import React, { useEffect, useState, useMemo, useRef } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPSettlementManager from "../../features/HTTPSettlementManager";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "../styles/CommonStyle.css";
import AgencyAccountListItem from "./AgencyAccountListItem";
import AgencyAccount from "./AgencyAccount";

export default function AgencyAccountWindow() {
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [tourList, setTourList] = useState([]);
  const [tourFilterList, setTourFilterList] = useState([]);

  // 에이전트별 손익 전체 리스트
  const [agencyAccountList, setAgencyAccountList] = useState([]);
  const [agencyAccountFilterList, setAgencyAccountFilterList] = useState([]);

  // 에이전트별로 분류
  const [finalDataList, setFinalDataList] = useState([]);
  const [finalDataFilterList, setFinalDataFilterList] = useState([]);

  const [selectedData, setSelectedData] = useState(undefined);

  function getCountryName(value) {
    switch (value) {
      case 2:
        return "인도네시아";
      case 3:
        return "필리핀";
    }
  }

  async function fetchData() {
    let res = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    let userList = [];
    let toursGuideInfo = [];

    if (res !== undefined) {
      userList = res.data.result;
    }

    res = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      toursGuideInfo = res.data.toursGuide;
    }

    res = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let tmpTourList = []
    if (res !== undefined) {
      tmpTourList = res.data.result.sort((a, b) => new Date(a.startDate.replace(" ", "T")) - new Date(b.startDate.replace(" ", "T")))
      tmpTourList = tmpTourList.map(tour => {

        return ({
          id: tour.id,
          country: getCountryName(tour.countryId),
          pax: tour.pax,
          docNum: tour.docNum,
          startDate: tour.startDate.slice(0, 10),
          endDate: tour.endDate.slice(0, 10),
          cancel: tour.cancel,
        });
      });

      setTourList(tmpTourList);
      setTourFilterList(tmpTourList);
    }

    res = await HTTPSettlementManager.getAgencyAccountList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      let tmpAgencyAccountList = res.data.result.map(item => {
        let tourInfo = tmpTourList.find(tour => tour.id === item.tourId);

        return {
          date: item.tourId !== -1 ? "[" + item.tourId + "] " + tourInfo?.startDate : item.note,
          agency: item.agency,
          amount: item.amount,
        };
      });

      let agencyList = tmpAgencyAccountList.map(item => {
        return item.agency;
      });

      setAgencyAccountList(tmpAgencyAccountList);
      setAgencyAccountFilterList(tmpAgencyAccountList);

      agencyList = agencyList.reduce((prev, now) => {
        if (!prev.some(obj => obj === now)) {
          prev.push(now);
        }
        return prev;
      }, []);

      let dataList = [];

      agencyList.map(item => {
        let tmpDataList = tmpAgencyAccountList.filter(agencyAccount => item === agencyAccount.agency);

        let data = {
          agency: item,
          amount: tmpDataList.reduce((a, b) => a = a + b.amount, 0),
          data: [{
            color: "#f00",
            data: tmpDataList.map(item => {
              return {
                x: item.date,
                y: item.amount,
              }
            }),
            id: item, // 필수
          }]
        };

        dataList.push(data);
      });

      setFinalDataList(dataList);

      // 여러 차원은로 구성된 데이터 단계 깊은 복사
      // JSON.stringfy(obj) : 값이나 객체를 JSON 문자열로 변환
      // JSON.parse() : 문자열 분석 후 그 결과에서 값이나 객체 생성
      setFinalDataFilterList(JSON.parse(JSON.stringify(dataList)));
    }
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.GOD, ROLES.SETTLEMENT], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          fetchData();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  const buttonRef = useRef(null);

  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);

  const YEARBUTTON = (number) => {
    return <div key={number} className={`flex w-fit ${selectedYear.find(item => item === number) !== undefined ? "bg-[black]" : "bg-[gray]"} px-[12px] py-[10px] mb-2 rounded-lg text-[white] cursor-pointer font-bold`}
      onClick={() => {
        if (selectedYear.find(item => item === number) !== undefined) {
          setSelectedYear(selectedYear.filter(item => item !== number));
        } else {
          setSelectedYear([...selectedYear, number]);
        }
      }}
    >
      {number < 10 ? "0" + number : number}
    </div>
  }

  const MONTHBUTTON = (number) => {
    return <div key={number} className={`flex w-fit ${selectedMonth.find(item => item === number) !== undefined ? "bg-[black]" : "bg-[gray]"} px-[12px] py-[10px] mb-5 rounded-lg text-[white] cursor-pointer font-bold`}
      onClick={() => {
        if (selectedMonth.find(item => item === number) !== undefined) {
          setSelectedMonth(selectedMonth.filter(item => item !== number));
        } else {
          setSelectedMonth([...selectedMonth, number]);
        }
      }}
    >
      {number < 10 ? "0" + number : number}
    </div>
  }

  const yearList = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031];
  const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <div className="w-full h-full flex flex-col bg-[#F5F7F9] mt-0 md:mt-14 px-[50px] md:px-[5px] pb-5">
      <div className="text-start mt-[50px] text-[#9299A1]">정산</div>
      <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px]">에이전시별 손익</div>

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="flex flex-row justify-between items-center">
        <div>
          <div className="flex flex-row gap-1">
            {
              yearList.map(year => {
                return YEARBUTTON(year);
              })
            }
          </div>
          <div className="flex flex-row gap-1">
            {
              monthList.map(month => {
                return MONTHBUTTON(month);
              })
            }
          </div>
        </div>
        <div className="text-[25px] font-bold mr-2"
        >{selectedData !== undefined && selectedData[0]?.id?.toString()}</div>
      </div>
      <div className="flex flex-row md:flex-col w-full h-dvh md:mt-16 gap-[20px]">
        <div className="flex flex-col w-1/3 md:w-full md:h-[390px] h-[780px] overflow-auto bg-[white] rounded-[20px] px-[20px] py-[20px] gap-[10px]">
          {
            finalDataFilterList.filter((item, index) => {
              let dataList = finalDataList[index].data[0].data;
              let tmpList = [];

              for (let i = 0; i < dataList.length; i++) {
                let dataItem = dataList[i];
                let xStrArray = dataItem.x?.split(' ');
                let date = xStrArray[1];
                let year = Number(date.slice(0, 4));
                let month = Number(date.slice(5, 7));

                // 년도 확인
                if (selectedYear.length > 0) {
                  if (selectedYear.find(item => item === year) !== undefined) {
                    // 월 확인
                    if (selectedMonth.length > 0) {
                      // 조건확인
                      if (selectedMonth.find(item => item === month) !== undefined)
                        tmpList.push(dataItem);
                    } else {
                      tmpList.push(dataItem);
                    }
                  }
                } else {
                  if (selectedMonth.length > 0) {
                    // 조건확인
                    if (selectedMonth.find(item => item === month) !== undefined)
                      tmpList.push(dataItem);
                  } else {
                    tmpList.push(dataItem);
                  }
                }
              }

              let totalAmount = tmpList.reduce((a, b) => a = a + b.y, 0);
              item.amount = totalAmount;
              item.data[0].data = Array.from(tmpList);
              return item;
            }).sort((a, b) => {
              if (a.amount > b.amount)
                return -1;
              else if (a.amount === b.amount)
                return 0;
              else
                return 1;
            }).filter(item => {
              let dataList = item.data[0].data;
              if (dataList?.length > 0)
                return item;
            }).map((item, index) => {
              let dataList = item.data[0]?.data;
              return <AgencyAccountListItem agency={item.agency + " (" + dataList?.length + ")"} rank={index} amount={item.amount} key={index} onClick={() => {
                setSelectedData(item.data);
              }} />
            })
          }
        </div>
        <div className="flex w-2/3 md:w-full md:h-[390px] h-[780px] bg-[white] rounded-[20px] md:px-[0px]">
          <AgencyAccount data={selectedData} />
        </div>
      </div>
    </div >
  );
}