import React, { useState, } from "react";
import * as Utils from '../../features/Utils';
import { PageLocale, ShowInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import UploadDialog from "./UploadDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import "../../components/styles/CommonStyle.css";
import * as HTTPITSManager from "../../features/HTTPITSManager";
import { UserInfo } from "../../recoil";
import LocaleKR from "../../features/LocaleKR";
import LocaleEN from "../../features/LocaleEN";

export default function RoomingInfoItem(Props) {
  const pageLocale = useRecoilValue(PageLocale);
  const languageSet = pageLocale === "ko" ? LocaleKR : LocaleEN;

  const userInfo = useRecoilValue(UserInfo);

  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const tourInfo = Props.data;

  const docNum = tourInfo.docNum;
  const rooming = tourInfo.rooming;
  const finalDoc = tourInfo.finalDoc;
  const finalDocIN = tourInfo.finalDocIN;
  const invoice = tourInfo.invoice;
  const logo = tourInfo.logo;

  const linkOnBackground = "#4171D6";
  const linkOffBackground = "#B3CDFF";

  //const userInfo = useRecoilValue(UserInfo);
  const showInfo = useRecoilValue(ShowInfo);

  const [uploadType, setUploadType] = useState("");

  const reservationHotel = Props.toursHotelInfo.filter(item => item.tourId === tourInfo.id);
  const reservationGuide = Props.toursGuideInfo.filter(item => item.tourId === tourInfo.id);
  const userList = Props.userList;
  const hotelList = Props.hotelList;

  const fromITSInfoList = Props.fromITSInfoList?.filter(item => item.tourId === tourInfo.id).sort((a, b) => {
    // 생성시간 최근순 정렬
    if (new Date(a.createdAt.replace(" ", "T")) > new Date(b.createdAt.replace(" ", "T")))
      return -1;
    else
      return 1;
  });

  const etaFromITS = fromITSInfoList !== undefined && fromITSInfoList.length > 0 ? fromITSInfoList[0].eta : undefined;
  const etdFromITS = fromITSInfoList !== undefined && fromITSInfoList.length > 0 ? fromITSInfoList[0].etd : undefined;

  const [dataType, setDataType] = useState("");   // eta, etd
  const [dataValue, setDataValue] = useState("");
  const [showModifyDialog, setShowModifyDialog] = useState(false);

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      await insertFromITSData();
    }
  }

  async function insertFromITSData() {
    let res = await HTTPITSManager.insertFromITS({
      accessToken: userInfo.accessToken,
      param: {
        tourId: tourInfo.id,
        dataType: dataType,
        dataValue: dataValue,
      }
    });

    if (res?.data?.result) {
      setShowModifyDialog(false);
      setDataType("");
      setDataValue("");
      Props.refresh();
    } else {
      alert("업데이트 실패!");
    }
  }

  return (
    <>
      <div className="flex flex-row w-full h-full font-bold text-center gap-[1px] min-w-max items-center justify-center divide-x-[1px] divide-[black]" style={{
        background: Props.index % 2 === 0 ? "#cfcfcf" : "#efefef"
      }}>
        <div className="flex w-[60px] h-[90px] items-center justify-center">{Props.index + 1}</div>
        <div className="flex w-[100px] h-[90px] items-center justify-center">{tourInfo.id}</div>
        <div className="flex w-[100px] h-[90px] items-center justify-center">{tourInfo.pax}</div>
        <div className="flex w-[500px] h-[90px] items-center justify-center px-2 sticky left-0 md:relative shadow-black shadow-xl" style={{
          background: Props.index % 2 === 0 ? "#cfcfcf" : "#efefef"
        }}>{docNum}</div>
        <div className="flex w-[250px] h-[90px] items-center justify-center px-2" onClick={() => {
          setShowModifyDialog(true);
          setDataType("eta");
        }}>
          {
            etaFromITS !== undefined && etaFromITS !== null ?
              <div className="flex w-full items-center justify-center cursor-pointer"> {etaFromITS} </div>
              :
              <div className="flex w-fit items-center justify-center rounded-lg text-white font-bold bg-[crimson] hover:bg-red-400 cursor-pointer py-2 px-4">
                INPUT
              </div>
          }
        </div>
        <div className="flex w-[250px] h-[90px] items-center justify-center px-2" onClick={() => {
          setShowModifyDialog(true);
          setDataType("etd");
        }}>
          {
            etdFromITS !== undefined && etdFromITS !== null ?
              <div className="flex w-full items-center justify-center cursor-pointer"> {etdFromITS}</div>
              :
              <div className="flex w-fit items-center justify-center rounded-lg text-white font-bold bg-[crimson] hover:bg-red-400 cursor-pointer py-2 px-4">
                INPUT
              </div>
          }
        </div>

        <div className="flex flex-row gap-1 w-[120px] h-[90px] items-center justify-center">
          <span
            title="SHOW ROOMING"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: (rooming !== undefined && rooming !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (rooming !== undefined && rooming !== null)
                Utils.showContent(rooming);
            }}>
            <FontAwesomeIcon icon={faFolderOpen} style={{ color: "white" }} />
          </span>

          <span
            title="UPLOAD ROOMING"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: "#34414d" }}
            onClick={() => {
              setShowUploadDialog(true);
              setUploadType("rooming");
            }}>
            <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
          </span>
        </div>

        <div className="flex flex-row gap-1 w-[120px] h-[90px] items-center justify-center">
          <span
            title="SHOW LOGO"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: (logo !== undefined && logo !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (logo !== undefined && logo !== null)
                Utils.showContent(logo);
            }}>
            <FontAwesomeIcon icon={faFolderOpen} style={{ color: "white" }} />
          </span>

          <span
            title="UPLOAD LOGO"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: "#34414d" }}
            onClick={() => {
              setShowUploadDialog(true);
              setUploadType("logo");
            }}>
            <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
          </span>
        </div>

        <div className="flex flex-row gap-1 w-[120px] h-[90px] items-center justify-center">
          <span
            title="SHOW FINAL(ITS)"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: (finalDocIN !== undefined && finalDocIN !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (finalDocIN !== undefined && finalDocIN !== null)
                Utils.showContent(finalDocIN);
            }}>
            <FontAwesomeIcon icon={faFolderOpen} style={{ color: "white" }} />
          </span>

          <span
            title="UPLOAD FINAL(ITS)"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: "#34414d" }}
            onClick={() => {
              setShowUploadDialog(true);
              setUploadType("finalDocIN");
            }}>
            <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
          </span>
        </div>

        <div className="flex w-[120px] h-[90px] items-center justify-center">
          <span
            title="SHOW FINAL(KTS)"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: (finalDoc !== undefined && finalDoc !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (finalDoc !== undefined && finalDoc !== null)
                Utils.showContent(finalDoc);
            }}>
            <FontAwesomeIcon icon={faFolderOpen} style={{ color: "white" }} />
          </span>
        </div>

        <div className="flex w-[120px] h-[90px] items-center justify-center">
          <span
            title="SHOW INVOICE"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: (invoice !== undefined && invoice !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (invoice !== undefined && invoice !== null)
                Utils.showContent(invoice);
            }}>
            <FontAwesomeIcon icon={faFolderOpen} style={{ color: "white" }} />
          </span>
        </div>

        {
          reservationGuide.length > 0 ?
            <div className="flex w-[150px] h-[90px] items-center justify-center relative text-[#4171D6] uppercase font-bold px-2">
              <div className="scrollRemoveBox">
                {
                  reservationGuide.map(item => {
                    return userList.find(user => user.id === item.guideId)?.nameEN;
                  }).toString()
                }
              </div>
            </div> :
            <div className="flex w-[150px] h-[90px] items-center justify-center font-bold text-[crimson]">TBA</div>
        }

        <div className="flex w-[480px] h-[90px] flex-col items-center justify-center px-2">
          <div className="relative flex flex-col">
            {
              reservationHotel.length > 0 &&
              <div>
                {
                  reservationHotel.map((item, index) => {
                    return (
                      <div key={item.id} className="flex flex-row gap-1 cursor-pointer" title={hotelList.find(hotel => hotel.id === item.hotelId)?.addressENG} onClick={() => {
                        let hotelInfo = hotelList.find(hotel => hotel.id === item.hotelId);
                        let copyStr = "";
                        if (hotelInfo !== undefined) {
                          copyStr = `name : ${hotelInfo.nameEN}\naddress : ${hotelInfo.addressENG}`;
                        }
                        navigator.clipboard.writeText(copyStr);
                      }}>
                        <div>[{index + 1}] {hotelList.find(hotel => hotel.id === item.hotelId)?.nameEN}</div>
                        <div>{item.startDate.slice(8, 10)} {Utils.getMonthToStr(Number(item.startDate.slice(5, 7)) - 1)}</div>
                        <div>~</div>
                        <div>{item.endDate.slice(8, 10)} {Utils.getMonthToStr(Number(item.endDate.slice(5, 7)) - 1)}</div>
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>
        </div>
        {
          showUploadDialog &&
          <UploadDialog
            title={`[${tourInfo.id}] ${docNum}`}
            setShow={setShowUploadDialog}
            data={Props.data}
            refresh={Props.refresh}
            type={uploadType}
          />
        }
        {
          showModifyDialog && <>
            <div className="scrollRemoveBox mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none font-medium">
              <div className="relative w-full mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#efefef] outline-none focus:outline-none px-5 pt-5">
                  <div className="mb-1">{tourInfo.id}</div>
                  <div className="mb-5">{docNum}</div>
                  <input
                    className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-4 pl-10 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mb-2"
                    type="text"
                    placeholder={dataType === "eta" ? "ARRIVAL" : "DEPATURE"}
                    onChange={(e) => {
                      setDataValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(e);
                    }}
                  />
                  <div className="flex flex-row">
                    <span className={"flex w-full cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[deepskyblue] font-bold"}
                        onClick={async () => {
                          insertFromITSData();
                        }}>MODIFY</div>
                    </span>
                    <span className={"flex w-full  cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[red] font-bold"} onClick={() => {
                        setShowModifyDialog(false);
                        setDataType("");
                        setDataValue("");
                      }}>CANCEL</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        }
      </div>
    </>
  )
}