import React, { useState, useEffect, useRef, useMemo } from "react";
import { useRecoilState, } from "recoil";
import { ROLES } from "../../features/Constant";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPSettlementManager from "../../features/HTTPSettlementManager";
import * as Utils from "../../features/Utils";
import { UserInfo } from "../../recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import "../styles/CommonStyle.css"
import { useNavigate } from "react-router-dom";

const SettlementListPage = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);

  let searchText = useRef("");
  let focusRef = useRef();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());

  const [userList, setUserList] = useState([]);
  const [tourList, setTourList] = useState([]);
  const [toursGuideList, setToursGuideList] = useState([]);

  const [toursGuideFilterList, setToursGuideFilterList] = useState([]);
  const data = useMemo(() => toursGuideFilterList, [toursGuideFilterList]);

  const [toursGuideIncentive, setToursGuideIncentive] = useState([]);

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";
  const [showMenu, setShowMenu] = useState(false);

  async function fetchData() {
    if (userInfo.accessToken === undefined) return;

    let res = "";

    res = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    setUserList(res.data.result);

    res = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let initTourList = [];
    if (res !== undefined) {
      initTourList = res.data.result;
      setTourList(initTourList);
    }

    res = await HTTPSettlementManager.getAdditionalIncentiveList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      setToursGuideIncentive(res.data.result);
    }

    res = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      setToursGuideList(res.data.toursGuide);
      setToursGuideFilterList(res.data.toursGuide);
    }
  }

  const handleCapture = async (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];

        let res = await HTTPSettlementManager.updateSettlementDataFromExcel({
          accessToken: userInfo.accessToken,
          fileList: [
            {
              type: "excel",
              file: file,
            },
          ],
        });

        if (res?.data?.result) {
          toast(`${res?.data?.update}건 업데이트 성공`, { type: "success", closeButton: false });
          setShowMenu(false);
          await fetchData();
        } else {
          alert("업데이트 실패!");
        }
      }
    }
  };

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.GOD, ROLES.SETTLEMENT], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  // 상태 정리
  const stateSymbol = (receiptCheck, guideCheck, accountFinish, index, tourId) => {
    if (tourList.find(item => item.id === tourId).cancel === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#FF0000] rounded-full`} title="취소된 행사" />
    }

    if (accountFinish === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#4ED964] rounded-full`} title="정산완료" />
    }

    if (guideCheck === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#4E64D9] rounded-full`} title="가이드 확인완료" />
    }

    if (receiptCheck === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#FFEA00] rounded-full`} title="영수증 확인완료" />
    }
  }

  const totalTax = data?.reduce((a, item) => {
    let shopIncentive = Number(item.shopIncentive);

    // 추가인센티브 계산
    let additionalIncentiveList = toursGuideIncentive?.filter(item => item.toursGuideId === item.id);
    let additionalIncentive = additionalIncentiveList.reduce((a, b) => a = a + Number(b.amount), 0);
    let additionalIncentiveTax = additionalIncentiveList.reduce((a, b) => a = a + (Utils.roundDown(Number(b.amount) * 0.03, -1) + Utils.roundDown(Number(b.amount) * 0.003, -1)), 0);

    let guideFeeTax = Utils.roundDown(item.guideFee * 0.03, -1) + Utils.roundDown(item.guideFee * 0.003, -1);

    // 원천징수
    let tax = guideFeeTax + (Utils.roundDown(shopIncentive * 0.03, -1) + Utils.roundDown(shopIncentive * 0.003, -1)) + additionalIncentiveTax;
    return a = a + tax;
  }, 0);

  const totalSum = data?.reduce((a, item) => {
    let shopIncentive = Number(item.shopIncentive);

    // 추가인센티브 계산
    let additionalIncentiveList = toursGuideIncentive?.filter(item => item.toursGuideId === item.id);
    let additionalIncentive = additionalIncentiveList.reduce((a, b) => a = a + Number(b.amount), 0);
    let additionalIncentiveTax = additionalIncentiveList.reduce((a, b) => a = a + (Utils.roundDown(Number(b.amount) * 0.03, -1) + Utils.roundDown(Number(b.amount) * 0.003, -1)), 0);

    let guideFeeTax = Utils.roundDown(item.guideFee * 0.03, -1) + Utils.roundDown(item.guideFee * 0.003, -1);

    // 원천징수
    let tax = guideFeeTax + (Utils.roundDown(shopIncentive * 0.03, -1) + Utils.roundDown(shopIncentive * 0.003, -1)) + additionalIncentiveTax;
    let sum = Number(item.guideFee) + shopIncentive + additionalIncentive - tax;

    return a = a + sum;
  }, 0);

  const finalSum = data?.reduce((a, item) => {
    let shopIncentive = Number(item.shopIncentive);

    // 추가인센티브 계산
    let additionalIncentiveList = toursGuideIncentive?.filter(item => item.toursGuideId === item.id);
    let additionalIncentive = additionalIncentiveList.reduce((a, b) => a = a + Number(b.amount), 0);
    let additionalIncentiveTax = additionalIncentiveList.reduce((a, b) => a = a + (Utils.roundDown(Number(b.amount) * 0.03, -1) + Utils.roundDown(Number(b.amount) * 0.003, -1)), 0);

    let guideFeeTax = Utils.roundDown(item.guideFee * 0.03, -1) + Utils.roundDown(item.guideFee * 0.003, -1);

    // 원천징수
    let tax = guideFeeTax + (Utils.roundDown(shopIncentive * 0.03, -1) + Utils.roundDown(shopIncentive * 0.003, -1)) + additionalIncentiveTax;
    let sum = Number(item.guideFee) + shopIncentive + additionalIncentive - tax;
    let totalSum = sum - Number(item.preChargeChange);

    return a = a + totalSum;
  }, 0);

  const idColor = "#43A4F9";
  const phColor = "#FF3A3A";

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-4 px-[50px] md:px-[5px]">
      <div className="flex flex-col relative">
        <span className={"absolute cursor-pointer top-10 right-0 text-[25px]"} onClick={() => {
          setShowMenu(true);
        }}>
          <FontAwesomeIcon icon={faEllipsis} style={{ color: "#000" }} />
        </span>
        <div className="text-start mt-[50px] text-[#9299A1]">정산</div>
        <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px]">목록 ({toursGuideFilterList?.length})</div>
        <div className="text-[#485763] font-bold normal-case mb-[25px] flex flex-col gap-1">
        </div>
        <input
          className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-[15px]"
          placeholder="가이드 이름 검색가능"
          type="text"
          name="search"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              let searchList = [];

              let guideList = userList.filter(user => user.name.includes(searchText.current)).map(item => {
                return item.id;
              });

              searchList = toursGuideList?.filter(tourGuide => guideList?.find(id => id === tourGuide.guideId) !== undefined);
              setToursGuideFilterList(searchList);
            }
          }}
          onChange={(e) => {
            searchText.current = e.target.value;
          }}
          ref={focusRef}
        />
      </div>

      <div className="w-full flex flex-row gap-2 mt-[25px] scrollRemoveBox">
        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
            </span>
            <div>사업소득</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(data?.reduce((a, b) => a = a + (Number(b.guideFee) + Number(b.shopIncentive)), 0))}원</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
            </span>
            <div>원천징수</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(totalTax)}원</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
            </span>
            <div>합계(사업소득 - 원천징수)</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(totalSum)}원</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
            </span>
            <div>전도금잔액</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(data?.reduce((a, b) => a = a + Number(b.preChargeChange), 0))}원</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
            </span>
            <div>총합</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(finalSum)}원</div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="md:scrollRemoveBox grid grid-cols-[5%_5%_10%_10%_10%_10%_10%_5%_10%_10%_10%_5%] sticky top-0 w-full text-[white] text-center items-center text-[16px] font-bold bg-[black] py-3">
        <div className="md:flex-none">번호</div>
        <div className="md:flex-none">가이드</div>
        <div className="md:flex-none">날짜</div>
        <div className="md:flex-none">전도금</div>
        <div className="md:flex-none">일비합계</div>
        <div className="md:flex-none">인센티브</div>
        <div className="md:flex-none">추가인센티브</div>
        <div className="md:flex-none">원천징수(3.3%)</div>
        <div className="md:flex-none">합계</div>
        <div className="md:flex-none">전도금잔액</div>
        <div className="md:flex-none">총합계</div>
        <div className="md:flex-none">상태</div>
      </div>
      <table>
        <tbody>
          {
            data?.sort((a, b) => {
              // 시작일 빠른순
              if (new Date(a.startDate?.replace(" ", "T")) > new Date(b.startDate?.replace(" ", "T")))
                return 1;
              if (new Date(a.startDate?.replace(" ", "T")) < new Date(b.startDate?.replace(" ", "T")))
                return -1;

              // 종료일 빠른순
              if (new Date(a.endDate?.replace(" ", "T")) > new Date(b.endDate?.replace(" ", "T")))
                return 1;
              if (new Date(a.endDate?.replace(" ", "T")) < new Date(b.endDate?.replace(" ", "T")))
                return -1;
            })?.map((item, index) => {
              let tourInfo = tourList.find(tour => tour.id === item.tourId);

              // 인센티브
              let shopIncentive = Number(item.shopIncentive);

              // 추가인센티브 계산
              let additionalIncentiveList = toursGuideIncentive?.filter(addIncentive => addIncentive.toursGuideId === item.id);
              let additionalIncentiveContent = additionalIncentiveList?.map(item => { return "[" + item.name + "] " + Utils.formatNumber(item.amount) })?.toString();
              let additionalIncentive = additionalIncentiveList.reduce((a, b) => a = a + Number(b.amount), 0);
              let additionalIncentiveTax = additionalIncentiveList.reduce((a, b) => a = a + (Utils.roundDown(Number(b.amount) * 0.03, -1) + Utils.roundDown(Number(b.amount) * 0.003, -1)), 0);

              let guideFeeTax = Utils.roundDown(item.guideFee * 0.03, -1) + Utils.roundDown(item.guideFee * 0.003, -1);

              // 원천징수
              let tax = guideFeeTax + (Utils.roundDown(shopIncentive * 0.03, -1) + Utils.roundDown(shopIncentive * 0.003, -1)) + additionalIncentiveTax;

              let sum = Number(item.guideFee) + shopIncentive + additionalIncentive - tax;
              let totalSum = sum - Number(item.preChargeChange);

              // 상태
              let receiptCheck = item.receiptCheck;   // 영수증확인완료
              let guideCheck = item.guideCheck;       // 가이드확인완료
              let accountFinish = item.accountFinish; // 정산완료

              return (
                <tr key={item.id} className="grid grid-cols-[5%_5%_10%_10%_10%_10%_10%_5%_10%_10%_10%_5%] text-center border-[#E2E2E2] border-x-[1px] border-b-[1px] py-5 text-[#A8A9A8] font-semibold items-center">
                  <td style={{ color: tourInfo?.countryId === 2 ? idColor : phColor }}>{item.id}</td>
                  <td>{userList.find(user => user.id === item.guideId)?.name}</td>
                  <td>{item.startDate?.slice(2, 10)?.replaceAll('-', '.')}~{item.endDate?.slice(2, 10)?.replaceAll('-', '.')}</td>
                  <td>{Utils.formatNumber(item.preCharge)}</td>
                  <td>{Utils.formatNumber(item.guideFee)}</td>
                  <td>{Utils.formatNumber(shopIncentive)}</td>
                  <td title={additionalIncentiveContent}>{Utils.formatNumber(additionalIncentive)}</td>
                  <td>{Utils.formatNumber(tax)}</td>
                  <td>{Utils.formatNumber(sum)}</td>
                  <td>{Utils.formatNumber(item.preChargeChange)}</td>
                  <td className={`${totalSum < 0 ? "text-[red]" : "text-[black]"} font-bold`}>{receiptCheck === 1 ? Utils.formatNumber(totalSum) : ""}</td>
                  <td className="flex justify-center items-center">
                    {
                      stateSymbol(receiptCheck, guideCheck, accountFinish, index, item.tourId)
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>

      {
        // 메뉴
        showMenu &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className={`border-b ${liCommonCSSUserMenu}`}>
                  <input
                    className="hidden"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                    onChange={(e) => {
                      handleCapture(e.target);
                    }}
                  />
                  <label htmlFor="icon-button-file">
                    <span>
                      <div className={`cursor-pointer ${btnCommonCSSUserMenu}`}>
                        데이터등록
                      </div>
                    </span>
                  </label>
                </div>
                <div className={`${liCommonCSSUserMenu}`}>
                  <button
                    className={btnCommonCSSUserMenu}
                    onClick={async () => {
                      setShowMenu(false);
                    }}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
    </div>
  );
};

export default SettlementListPage;
