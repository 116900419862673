import React, { useEffect, useState, useMemo, useRef } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPHanmaruManager from "../../features/HTTPHanmaruManager";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "../styles/CommonStyle.css";
import CalendarMovingBox from "./CalendarMovingBox";
import AccountItem from "./AccountItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";

export default function AccountPage() {
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const [accountList, setAccountList] = useState([]);
  const [accountFilterList, setAccountFilterList] = useState([]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);


  // 데이터 초기화
  async function initalize() {
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth() + 1);
  }

  async function fetchData() {
    let res = await HTTPHanmaruManager.GetAccountList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      let tmpList = res.data.list.sort((a, b) => {
        if (new Date(a.date.replace(" ", "T")) < new Date(b.date.replace(" ", "T")))
          return -1;
        if (new Date(a.date.replace(" ", "T")) > new Date(b.date.replace(" ", "T")))
          return 1;
      });

      setAccountList(tmpList);
      setAccountFilterList(tmpList);
    }
  }

  function clearList() {
    setAccountFilterList(accountList);
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.GOD], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          fetchData();
          await initalize();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-14 px-[50px] md:px-[10px]">
      <div className="text-start mt-[50px] text-[#9299A1]">한마루</div>
      <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px]">매입 매출</div>

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="w-full h-full">
        <div className="flex flex-col w-full h-full gap-1">
          <CalendarMovingBox year={selectedYear} setYear={setSelectedYear} month={selectedMonth} setMonth={setSelectedMonth} clear={clearList} />

          <div className="w-full flex-row gap-8 md:gap-2 mt-[25px] scrollRemoveBox flex cursor-pointer p-1">
            <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5" onClick={() => {
              let tmpList = accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25));
              setAccountFilterList(tmpList);
            }}>
              <div className="flex flex-row gap-3 font-bold">
                <span className={"cursor-pointer"}>
                  <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
                </span>
                <div>전체</div>
              </div>
              <div className="flex w-full justify-end text-[25px] font-bold">
                {accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25))?.length}
              </div>
            </div>

            <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5 cursor-pointer"
              style={{
                //background: (selectedFilterList.filter(name => name === "in")?.length > 0) ? "lightgray" : "#FFFFFF"
              }}
              onClick={() => {
                let tmpList = accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 0);
                setAccountFilterList(tmpList);
              }}>
              <div className="flex flex-row gap-3 font-bold text-red-700">
                <span className={"cursor-pointer"}>
                  <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
                </span>
                <div>매입</div>
              </div>
              <div className="flex w-full justify-end text-[25px] font-bold">
                {accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 0)?.length}
              </div>
            </div>

            <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5 cursor-pointer"
              style={{
                //background: (selectedFilterList.filter(name => name === "none")?.length > 0) ? "lightgray" : "#FFFFFF"
              }}
              onClick={() => {
                let tmpList = accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 0 && item.contentType === null);
                setAccountFilterList(tmpList);
              }}>
              <div className="flex flex-row gap-3 font-bold">
                <span className={"cursor-pointer"}>
                  <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
                </span>
                <div>매입 미분류</div>
              </div>
              <div className="flex w-full justify-end text-[25px] font-bold">
                {
                  accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                    && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 0 && item.contentType === null)?.length
                }
              </div>
            </div>

            <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5 cursor-pointer"
              style={{
                //background: (selectedFilterList.filter(name => name === "ph")?.length > 0) ? "lightgray" : "#FFFFFF"
              }}
              onClick={() => {
                let tmpList = accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 1);
                setAccountFilterList(tmpList);
              }}>
              <div className="flex flex-row gap-3 font-bold text-blue-700">
                <span className={"cursor-pointer"}>
                  <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
                </span>
                <div>매출</div>
              </div>
              <div className="flex w-full justify-end text-[25px] font-bold">
                {
                  accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                    && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 1)?.length
                }
              </div>
            </div>
          </div>

          <span
            title="복사"
            className="select-none items-center rounded-md bg-black px-2 py-3 font-medium text-white ring-1 ring-inset ring-[black] cursor-pointer hover:bg-[gray] hover:ring-[gray] ml-1 text-center"
            onClick={() => {
              navigator.clipboard.writeText(`${accountFilterList.map(item => {
                return item.date + "/" + item.content.replaceAll('/', '') + "/" + item.amount + "/" + item.contentType + "\n";
              })}`);
            }}>
            복사
          </span>

          <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

          <div className="flex flex-col w-full overflow-auto gap-2 pb-5">
            {
              accountFilterList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25)).reverse().map((item, index) => {
                  return (
                    <AccountItem account={item} index={index} refresh={fetchData} key={item.id} />
                  )
                })
            }
          </div>
        </div>
      </div>
    </div>
  );
}