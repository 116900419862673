import React, { useState, useEffect, useRef, useMemo } from "react";
import { useRecoilState, } from "recoil";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../features/Constant";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPShopManager from "../../features/HTTPShopManager";
import * as Utils from "../../features/Utils";
import { UserInfo } from "../../recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import "../styles/CommonStyle.css"
import ICON_PH from "../../assets/images/ph.png"
import ICON_ID from "../../assets/images/id.png"

const ShopAccountPage = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);

  let searchText = useRef("");
  let focusRef = useRef();

  const today = new Date();
  today.setMonth(today.getMonth() - 1);
  today.setHours(0, 0, 0, 0);

  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth() + 1);

  const [userList, setUserList] = useState([]);
  const [tourList, setTourList] = useState([]);
  const [toursGuideList, setToursGuideList] = useState([]);
  const [toursGuideFilterList, setToursGuideFilterList] = useState([]);
  const data = useMemo(() => toursGuideFilterList, [toursGuideFilterList]);

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none justify-center flex";
  const [showMenu, setShowMenu] = useState(false);

  const [shopList, setShopList] = useState([]);
  const [showShopList, setShowShowList] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState(-2);
  const [shopAccountList, setShopAccountList] = useState([]);

  // 월이동
  const filterYearList = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, "닫기"];
  const [showYearList, setShowYearList] = useState(false);
  const filterMonthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, "닫기"];
  const [showMonthList, setShowMonthList] = useState(false);

  // 국가
  const [selectedCountryList, setSelectedCountryList] = useState([2, 3]);

  // 쇼핑샵 리스트
  const [shopResultForMenu, setShopResultForMenu] = useState([]);

  // 정렬
  const [sortDirection, setSortDirection] = useState(-1);
  const [sortType, setSortType] = useState("");

  // 필터 버튼
  const FILTER_LIST = [{ id: 0, name: "1분기", startMonth: 0, endMonth: 2 }, { id: 1, name: "2분기", startMonth: 3, endMonth: 5 }, { id: 2, name: "3분기", startMonth: 6, endMonth: 8 },
  { id: 3, name: "4분기", startMonth: 9, endMonth: 11 }, { id: 4, name: "상반기", startMonth: 0, endMonth: 5 }, { id: 5, name: "하반기", startMonth: 6, endMonth: 11 }];
  const [selectedFilterId, setSelectedFilterId] = useState(-1);

  // 기간 선택
  const SELECTED_PERIOD_YEAR = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
  const SELECTED_PERIOD_MONTH = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [showPeriodStart, setShowPeriodStart] = useState(false);
  const [showPeriodEnd, setShowPeriodEnd] = useState(false);

  const [startYear, setStartYear] = useState(-1);
  const [endYear, setEndYear] = useState(-1);
  const [startMonth, setStartMonth] = useState(-1);
  const [endMonth, setEndMonth] = useState(-1);

  async function fetchData() {
    if (userInfo.accessToken === undefined) return;

    let res = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    setUserList(res.data.result);

    let shopList = [];

    res = await HTTPShopManager.getShopList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      res.data.result.push({
        id: -1,
        name: "취소",
      });
      shopList = res.data.result;
      setShopList(shopList);
    }

    res = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let initTourList = [];
    if (res !== undefined) {
      initTourList = res.data.result;
      setTourList(initTourList);
    }

    res = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      setToursGuideList(res.data.toursGuide);
      setToursGuideFilterList(res.data.toursGuide);
    }

    res = await HTTPShopManager.getShopAccountList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      setShopAccountList(res.data.result);
      setMenuCount(shopList, res.data.result, today.getFullYear(), today.getMonth());
    }
  }

  function setMenuCount(shopList, shopAccountList, year, month) {
    let shopMonthList = shopAccountList.filter(shopAccount => {
      let filterInfo = FILTER_LIST[selectedFilterId];

      if (selectedFilterId !== -1) {
        return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
          new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() >= filterInfo.startMonth && new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() <= filterInfo.endMonth);
      } else {
        if (startYear !== -1 && startMonth !== -1 && endYear !== -1 && endMonth !== -1) {
          let start = new Date(startYear, startMonth - 1, 1, 0, 0, 0, 0).getTime();
          let end = new Date(endYear, endMonth, 0, 0, 0, 0, 0).getTime();
          let visit = new Date(shopAccount.visitDate.replace(" ", "T")).getTime();

          return (start <= visit && visit <= end);
        } else {
          return (new Date(shopAccount.visitDate).getFullYear() === year && new Date(shopAccount.visitDate).getMonth() === month);
        }
      }
    });
    let tmpList = [];

    for (let i = 0; i < shopList.length; i++) {
      let shopInfo = shopList[i];

      tmpList.push({
        id: shopInfo.id,
        count: shopMonthList.filter(item => item.shopId === shopInfo.id)?.length
      });
    }

    setShopResultForMenu(tmpList);
  }

  const handleCapture = async (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];

        let res = await HTTPShopManager.updateShopAccountDataFromExcel({
          accessToken: userInfo.accessToken,
          fileList: [
            {
              type: "excel",
              file: file,
            },
          ],
        });

        if (res?.data?.result) {
          toast(`${res?.data?.update}건 업데이트 성공`, { type: "success", closeButton: false });
          setShowMenu(false);
          await fetchData();
        } else {
          alert("업데이트 실패!");
        }
      }
    }
  };

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.GOD, ROLES.SETTLEMENT], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
      });
  }, []);

  function moveToRight() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month + 1 > 12) {
      setSelectedYear(selectedYear + 1);
      month = 0;
      year = year + 1;
    }

    setSelectedMonth(month + 1);
    setMenuCount(shopList, shopAccountList, year, month);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  function moveToLeft() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month - 1 < 1) {
      setSelectedYear(selectedYear - 1);
      month = 13;
      year = year - 1;
    }

    setSelectedMonth(month - 1);
    setMenuCount(shopList, shopAccountList, year, month - 2);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  const btnMoveMonthClass = "text-3xl pointerhover:hover:cursor-pointer px-4 py-1 pointerhover:hover:bg-gray-200 rounded-full focus:outline-none";
  const idColor = "#43A4F9";
  const phColor = "#FF3A3A";

  const totalList = shopAccountList?.filter((shopAccount) => shopAccount.shopId === selectedShopId)?.filter((shopAccount, index) => {
    let filterInfo = FILTER_LIST[selectedFilterId];

    if (selectedFilterId !== -1) {
      return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
        new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() >= filterInfo.startMonth && new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() <= filterInfo.endMonth);
    } else {
      if (startYear !== -1 && startMonth !== -1 && endYear !== -1 && endMonth !== -1) {
        let start = new Date(startYear, startMonth - 1, 1, 0, 0, 0, 0).getTime();
        let end = new Date(endYear, endMonth, 0, 0, 0, 0, 0).getTime();
        let visit = new Date(shopAccount.visitDate.replace(" ", "T")).getTime();

        return (start <= visit && visit <= end);
      }
      else {
        return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
          new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() === (selectedMonth - 1));
      }
    }
  });

  const totalCount = totalList?.reduce((a, b) => a = a + Number(b.count * 100), 0);
  const totalRevenueAmount = totalList?.reduce((a, b) => a = a + Number(b.revenueAmount), 0);
  const totalPax = totalList?.reduce((a, b) => a = a + Number(b.pax), 0);
  const totalProfitAmount = totalList?.reduce((a, b) => a = a + Number(b.profitAmount), 0);

  // 인니
  const idCount = totalList?.filter(shopAccount => shopAccount.countryId === 2)?.reduce((a, b) => a = a + Number(b.count * 100), 0);
  const idRevenueAmount = totalList?.filter(shopAccount => shopAccount.countryId === 2)?.reduce((a, b) => a = a + Number(b.revenueAmount), 0);
  const idPax = totalList?.filter(shopAccount => shopAccount.countryId === 2)?.reduce((a, b) => a = a + Number(b.pax), 0);

  // 필핀
  const phCount = totalList?.filter(shopAccount => shopAccount.countryId === 3)?.reduce((a, b) => a = a + Number(b.count * 100), 0);
  const phRevenueAmount = totalList?.filter(shopAccount => shopAccount.countryId === 3)?.reduce((a, b) => a = a + Number(b.revenueAmount), 0);
  const phPax = totalList?.filter(shopAccount => shopAccount.countryId === 3)?.reduce((a, b) => a = a + Number(b.pax), 0);

  function handleSortType(type) {
    if (sortType !== type) {
      setSortDirection(-1);
      setSortType(type);
    } else {
      setSortDirection(-sortDirection);
    }
  }

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-4 px-[50px] pb-[30px] md:px-[5px]">
      <div className="flex flex-col relative">
        <span className={"absolute cursor-pointer top-10 right-0 text-[25px]"} onClick={() => {
          setShowMenu(true);
        }}>
          <FontAwesomeIcon icon={faEllipsis} style={{ color: "#000" }} />
        </span>

        <span className={"absolute top-10 left-1/2 transform -translate-x-1/2 text-[20px] cursor-pointer flex flex-col gap-2 items-center justify-center"}>
          <span className="flex flex-row">
            <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
              moveToLeft();
            }} >
              <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#777" }} />
            </span>
            <div
              id="title"
              className="flex flex-row justify-center items-center text-[25px] select-none cursor-pointer">
              <div className="mr-[5px]" onClick={() => {
                setShowYearList(true);
              }}>{selectedYear}년</div>
              <div onClick={() => {
                setShowMonthList(true);
              }}>{selectedMonth.toString().padStart(2, "0")}월</div>
            </div>
            <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
              moveToRight();
            }}>
              <FontAwesomeIcon icon={faCaretRight} style={{ color: "#777" }} />
            </span>
          </span>
        </span>
        <div className="text-start mt-[50px] text-[#9299A1]">쇼핑</div>
        <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px] cursor-pointer w-fit" onClick={() => {
          setShowShowList(true);
        }}>
          <div className="relative">
            <div>매출 ({shopList.find(shopInfo => shopInfo.id === selectedShopId) === undefined ? "매장 선택" : shopList.find(shopInfo => shopInfo.id === selectedShopId)?.name})</div>
            {
              shopResultForMenu.reduce((a, b) => a = a + b.count, 0) > 0 && <div className="absolute top-0 -right-3 w-[7px] h-[7px] rounded-full bg-[red]" />
            }
          </div>
        </div>
        <div className="text-[#485763] font-bold normal-case mb-[25px] flex flex-col gap-1">
        </div>
      </div>

      <div className="w-full flex flex-row gap-2 mt-[25px] scrollRemoveBox px-2">
        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5 cursor-pointer" style={{
          background: selectedCountryList.find(countryId => countryId === 2) !== undefined ? "#444444" : "#cdcdcd",
          color: selectedCountryList.find(countryId => countryId === 2) !== undefined ? "#ffffff" : "#ffffff",
        }} onClick={() => {
          if (selectedCountryList.find(countryId => countryId === 2) !== undefined) {
            setSelectedCountryList(selectedCountryList.filter(countryId => countryId !== 2));
          } else {
            setSelectedCountryList([...selectedCountryList, 2]);
          }
        }}>
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer bg-[lightgray] p-[1px] rounded-full w-[25px] h-[25px]"}>
              <img src={ICON_ID} className="rounded-full object-cover h-full w-full" />
            </span>
            <div>인도네시아</div>
          </div>

          <div className="flex flex-row justify-between">
            <div>쇼핑율</div>
            <div>{typeof idPax === "number" && idPax > 0 ? Utils.formatNumber((idCount / idPax).toFixed(2)) : "0"}%</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>객단가</div>
            <div>{typeof idPax === "number" && idPax > 0 ? Utils.formatNumber((idRevenueAmount / idPax).toFixed(0)) : "0"}원</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>회사수수료</div>
            <div>{Utils.formatNumber(totalList?.filter(shopAccount => shopAccount.countryId === 2).reduce((a, b) => a = a + Number(b.profitAmount), 0))}원</div>
          </div>

          <div className="flex w-full justify-end text-[25px] font-bold">
            {
              totalList?.filter(shopAccount => shopAccount.countryId === 2)?.length
            }건</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5 cursor-pointer" style={{
          background: selectedCountryList.find(countryId => countryId === 3) !== undefined ? "#444444" : "#cdcdcd",
          color: selectedCountryList.find(countryId => countryId === 3) !== undefined ? "#ffffff" : "#ffffff",
        }} onClick={() => {
          if (selectedCountryList.find(countryId => countryId === 3) !== undefined) {
            setSelectedCountryList(selectedCountryList.filter(countryId => countryId !== 3));
          } else {
            setSelectedCountryList([...selectedCountryList, 3]);
          }
        }}>
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer bg-[lightgray] p-[1px] rounded-full w-[25px] h-[25px]"}>
              <img src={ICON_PH} className="rounded-full object-cover h-full w-full" />
            </span>
            <div>필리핀</div>
          </div>

          <div className="flex flex-row justify-between">
            <div>쇼핑율</div>
            <div>{typeof phPax === "number" && phPax > 0 ? Utils.formatNumber((phCount / phPax).toFixed(2)) : "0"}%</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>객단가</div>
            <div>{typeof phPax === "number" && phPax > 0 ? Utils.formatNumber((phRevenueAmount / phPax).toFixed(0)) : "0"}원</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>회사수수료</div>
            <div>{Utils.formatNumber(totalList?.filter(shopAccount => shopAccount.countryId === 3).reduce((a, b) => a = a + Number(b.profitAmount), 0))}원</div>
          </div>

          <div className="flex w-full justify-end text-[25px] font-bold">
            {
              totalList?.filter(shopAccount => shopAccount.countryId === 3)?.length
            }건</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5 justify-between" style={{
          background: "#FFFFFF",
          color: "#000000",
        }}>
          <div className="flex flex-row gap-3 font-bold">
            <span>
              <FontAwesomeIcon icon={faChartSimple} style={{ color: "#1D3C77" }} />
            </span>
            <div>종합</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>쇼핑율</div>
            <div>{typeof totalPax === "number" && totalPax > 0 ? Utils.formatNumber((totalCount / totalPax).toFixed(2)) : "0"}%</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>객단가</div>
            <div>{typeof totalPax === "number" && totalPax > 0 ? Utils.formatNumber((totalRevenueAmount / totalPax).toFixed(0)) : "0"}원</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>회사수수료</div>
            <div>{Utils.formatNumber(totalProfitAmount)}원</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">
            {
              totalList?.length
            }건</div>
        </div>
      </div>

      <div className="px-2 md:scrollRemoveBox flex flex-row gap-1 items-center">
        {
          FILTER_LIST.map((item, index) => {
            return <div key={item.id} className="md:flex-none rounded text-[white] w-fit px-2 py-[5px] text-center cursor-pointer"
              style={{
                background: (selectedFilterId === item.id) ? "#000000" : "#bababa",
              }}
              onClick={() => {
                setStartYear(-1);
                setStartMonth(-1);
                setEndYear(-1);
                setEndMonth(-1);

                let shopMonthList = [];

                if (selectedFilterId === item.id) {
                  setSelectedFilterId(-1);

                  shopMonthList = shopAccountList.filter(shopAccount => {
                    return (new Date(shopAccount.visitDate).getFullYear() === selectedYear && new Date(shopAccount.visitDate).getMonth() === (selectedMonth - 1));
                  });
                }
                else {
                  setSelectedFilterId(item.id);

                  let filterInfo = FILTER_LIST[item.id];

                  shopMonthList = shopAccountList.filter(shopAccount => {
                    return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
                      new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() >= filterInfo.startMonth && new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() <= filterInfo.endMonth);
                  });
                }

                let tmpList = [];

                for (let i = 0; i < shopList.length; i++) {
                  let shopInfo = shopList[i];

                  tmpList.push({
                    id: shopInfo.id,
                    count: shopMonthList.filter(item => item.shopId === shopInfo.id)?.length
                  });
                }

                setShopResultForMenu(tmpList);
              }}>{item.name}</div>
          })
        }
        <div className="md:flex-none rounded text-[white] w-fit px-2 py-[5px] text-center cursor-pointer bg-[black]"
          onClick={() => {
            setSelectedFilterId(-1);
            setStartYear(-1);
            setStartMonth(-1);
            setEndYear(-1);
            setEndMonth(-1);
            setShowPeriodStart(!showPeriodStart);
          }}>
          기간설정
        </div>
        {
          (startYear !== -1 && startMonth !== -1 && endYear !== -1 && endMonth !== -1)
          &&
          <div className="md:flex-none rounded text-[black] border-[1px] border-black w-fit px-2 py-1 text-center cursor-pointer" onClick={() => {
            setStartYear(-1);
            setStartMonth(-1);
            setEndYear(-1);
            setEndMonth(-1);

            let shopMonthList = [];

            shopMonthList = shopAccountList.filter(shopAccount => {
              return (new Date(shopAccount.visitDate).getFullYear() === selectedYear && new Date(shopAccount.visitDate).getMonth() === (selectedMonth - 1));
            });

            let tmpList = [];

            for (let i = 0; i < shopList.length; i++) {
              let shopInfo = shopList[i];

              tmpList.push({
                id: shopInfo.id,
                count: shopMonthList.filter(item => item.shopId === shopInfo.id)?.length
              });
            }

            setShopResultForMenu(tmpList);
          }}>
            {startYear}.{startMonth.toString().padStart(2, 0)} ~ {endYear}.{endMonth.toString().padStart(2, 0)}
          </div>
        }
        <div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="md:scrollRemoveBox grid grid-cols-[5%_5%_10%_10%_10%_10%_10%_15%_10%_15%] sticky top-0 w-full text-[white] text-center items-center text-[16px] font-bold bg-[black] py-3">
        <div className="md:flex-none">번호</div>
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer">국가</div>
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer">가이드</div>
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer">입점일</div>
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={() => {
          handleSortType("pax");
        }}>
          <div>인원</div>
          {
            sortType === "pax" ? sortDirection === 1 ? <span>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: "#FFFFFF" }} />
            </span> : <span>
              <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FFFFFF" }} />
            </span> : null
          }
        </div>
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={() => {
          handleSortType("count");
        }}>
          <div>개수</div>
          {
            sortType === "count" ? sortDirection === 1 ? <span>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: "#FFFFFF" }} />
            </span> : <span>
              <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FFFFFF" }} />
            </span> : null
          }
        </div>
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={() => {
          handleSortType("shopRate");
        }}>
          <div>쇼핑율(개수/인원)</div>
          {
            sortType === "shopRate" ? sortDirection === 1 ? <span>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: "#FFFFFF" }} />
            </span> : <span>
              <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FFFFFF" }} />
            </span> : null
          }
        </div>
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={() => {
          handleSortType("revenueAmount");
        }}>
          <div>매출액</div>
          {
            sortType === "revenueAmount" ? sortDirection === 1 ? <span>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: "#FFFFFF" }} />
            </span> : <span>
              <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FFFFFF" }} />
            </span> : null
          }
        </div>
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={() => {
          handleSortType("revenueAmountRate");
        }}>
          <div>객단가(매출액/인원)</div>
          {
            sortType === "revenueAmountRate" ? sortDirection === 1 ? <span>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: "#FFFFFF" }} />
            </span> : <span>
              <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FFFFFF" }} />
            </span> : null
          }
        </div>
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={() => {
          handleSortType("profitAmount");
        }}>
          <div>회사수수료</div>
          {
            sortType === "profitAmount" ? sortDirection === 1 ? <span>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: "#FFFFFF" }} />
            </span> : <span>
              <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FFFFFF" }} />
            </span> : null
          }
        </div>
      </div>
      <table>
        <tbody>
          {
            selectedShopId !== -2 &&
            shopAccountList?.filter(shopAccount => shopAccount.shopId === selectedShopId)?.filter(shopAccount => {
              let filterInfo = FILTER_LIST[selectedFilterId];

              if (selectedFilterId !== -1) {
                return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
                  new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() >= filterInfo.startMonth && new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() <= filterInfo.endMonth);
              } else {
                if (startYear !== -1 && startMonth !== -1 && endYear !== -1 && endMonth !== -1) {
                  let start = new Date(startYear, startMonth - 1, 1, 0, 0, 0, 0).getTime();
                  let end = new Date(endYear, endMonth, 0, 0, 0, 0, 0).getTime();
                  let visit = new Date(shopAccount.visitDate.replace(" ", "T")).getTime();

                  return (start <= visit && visit <= end);
                } else {
                  return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
                    new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() === (selectedMonth - 1));
                }
              }
            })?.filter(shopAccount => selectedCountryList.find(countryId => shopAccount.countryId === countryId) !== undefined)?.sort((a, b) => {
              // 정렬 조건 and 처리하려면 아래와 같이 return 처리              
              switch (sortType) {
                case "count":
                  if (Number(a.count) > Number(b.count))
                    return sortDirection;
                  if (Number(a.count) < Number(b.count))
                    return -sortDirection;
                  break;
                case "pax":
                  if (Number(a.pax) > Number(b.pax))
                    return sortDirection;
                  if (Number(a.pax) < Number(b.pax))
                    return -sortDirection;
                  break;
                case "shopRate":
                  if (Number(a.count) * 100 / Number(a.pax) > Number(b.count) * 100 / Number(b.pax))
                    return sortDirection;
                  if (Number(a.count) * 100 / Number(a.pax) < Number(b.count) * 100 / Number(b.pax))
                    return -sortDirection;
                  break;
                case "revenueAmount":
                  if (Number(a.revenueAmount) > Number(b.revenueAmount))
                    return sortDirection;
                  if (Number(a.revenueAmount) < Number(b.revenueAmount))
                    return -sortDirection;
                  break;
                case "revenueAmountRate":
                  if (Number(a.revenueAmount) * 100 / Number(a.pax) > Number(b.revenueAmount) * 100 / Number(b.pax))
                    return sortDirection;
                  if (Number(a.revenueAmount) * 100 / Number(a.pax) < Number(b.revenueAmount) * 100 / Number(b.pax))
                    return -sortDirection;
                  break;
                case "profitAmount":
                  if (Number(a.profitAmount) > Number(b.profitAmount))
                    return sortDirection;
                  if (Number(a.profitAmount) < Number(b.profitAmount))
                    return -sortDirection;
                  break;
                default:
                  break;
              }
            })?.map((item, index) => {
              return (
                <tr key={index} className="md:scrollRemoveBox grid grid-cols-[5%_5%_10%_10%_10%_10%_10%_15%_10%_15%] border-[#E2E2E2] border-x-[1px] border-b-[1px] py-5 font-semibold items-center normal-case text-center">
                  <td>{index + 1}</td>
                  <td style={{
                    color: item.countryId === 2 ? idColor : item.countryId === 3 ? phColor : "#000",
                  }}>{item.countryId === 2 ? "ID" : (item.countryId === 3 ? "PH" : "")}</td>
                  <td>{item.name}</td>
                  <td>{item.visitDate.slice(0, 10)}</td>
                  <td>{item.pax}</td>
                  <td>{item.count}</td>
                  <td>{(Number(item.count) / Number(item.pax) * 100).toFixed(0)}%</td>
                  <td>{Utils.formatNumber(item.revenueAmount)}</td>
                  <td>{Utils.formatNumber((Number(item.revenueAmount) / Number(item.pax)).toFixed(0))}</td>
                  <td>{Utils.formatNumber(item.profitAmount)}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>

      {
        // 메뉴
        showMenu &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className={`border-b ${liCommonCSSUserMenu}`}>
                  <input
                    className="hidden"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                    onChange={(e) => {
                      handleCapture(e.target);
                    }}
                  />
                  <label htmlFor="icon-button-file">
                    <span>
                      <div className={`cursor-pointer ${btnCommonCSSUserMenu}`}>
                        데이터등록
                      </div>
                    </span>
                  </label>
                </div>
                <div className={`${liCommonCSSUserMenu}`}>
                  <button
                    className={btnCommonCSSUserMenu}
                    onClick={async () => {
                      setShowMenu(false);
                    }}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        showShopList &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  shopList.map((shopInfo, index) => {
                    let menuCount = shopResultForMenu.find(result => result.id === shopInfo.id)?.count;

                    return (
                      <div key={shopInfo.id} className={index !== shopList.length - 1
                        ? `border-b ${liCommonCSSUserMenu}`
                        : `${liCommonCSSUserMenu}`
                      }>
                        <button
                          className={btnCommonCSSUserMenu}
                          onClick={async () => {
                            switch (shopInfo.name) {
                              case "취소":
                                setShowShowList(false);
                                break;
                              default:
                                setSortDirection(-1);
                                setSortType("");
                                setSelectedShopId(shopInfo.id);
                                setShowShowList(false);
                                break;
                            }
                          }}
                        >
                          <div className="relative w-fit">
                            <div className="flex w-fit">{shopInfo.name} {shopInfo.name !== "취소" && `(${shopResultForMenu.find(result => result.id === shopInfo.id)?.count})`}</div>
                            {
                              menuCount > 0 &&
                              <div className="absolute top-0 -right-3 w-[7px] h-[7px] rounded-full bg-[red]" />
                            }
                          </div>
                        </button>

                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        // 년 이동 처리
        showYearList &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg border-[1px]">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  filterYearList.map((year, index) => {
                    return (
                      <div key={index}
                        className={index !== filterYearList.length - 1
                          ? `border-b ${liCommonCSSUserMenu}`
                          : `${liCommonCSSUserMenu}`
                        }>
                        <button
                          className={btnCommonCSSUserMenu}
                          onClick={() => {
                            if (typeof (year) === "number") {
                              setSelectedYear(year);
                              setShowYearList(false);
                            } else {
                              setShowYearList(false);
                            }
                          }}
                        >
                          {typeof (year) === "number" ? year + "년" : year}
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        // 월 이동 처리
        showMonthList &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg border-[1px]">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  filterMonthList.map((month, index) => {
                    return (
                      <div key={index}
                        className={index !== filterMonthList.length - 1
                          ? `border-b ${liCommonCSSUserMenu}`
                          : `${liCommonCSSUserMenu}`
                        }>
                        <button
                          className={btnCommonCSSUserMenu}
                          onClick={() => {
                            if (typeof (month) === "number") {
                              setSelectedMonth(month);
                              setShowMonthList(false);
                            } else {
                              setShowMonthList(false);
                            }
                          }}
                        >
                          {typeof (month) === "number" ? month + "월" : month}
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        showPeriodStart &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium normal-case">
            <div className="relative w-full m-auto max-w-lg border-[1px] bg-white shadow-lg">
              <div className="border-0 relative flex flex-col outline-none focus:outline-none">
                <div className="flex cursor-pointer text-black w-full justify-center py-2">시작</div>
                <div className="grid grid-cols-4 gap-1 w-full">
                  {
                    startYear === -1 && SELECTED_PERIOD_YEAR.map(year => {
                      return <div key={year} className="flex w-full items-center justify-center p-5 hover:bg-[#dfdfdf] cursor-pointer select-none"
                        onClick={() => {
                          setStartYear(year);
                        }}
                      >
                        {year}
                      </div>
                    })
                  }
                  {
                    (startYear !== -1 && startMonth === -1) && SELECTED_PERIOD_MONTH.map(month => {
                      return <div key={month} className="flex w-full items-center justify-center p-5 hover:bg-[#dfdfdf] cursor-pointer select-none"
                        onClick={() => {
                          setStartMonth(month);
                          setShowPeriodStart(false);
                          setShowPeriodEnd(true);
                        }}
                      >
                        {month}
                      </div>
                    })
                  }
                </div>
              </div>
              <div className="flex cursor-pointer text-white bg-[black] w-full justify-center py-2" onClick={() => {
                setShowPeriodStart(false);
                setStartYear(-1);
                setStartMonth(-1);
              }}>
                <div>닫기</div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        showPeriodEnd &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium normal-case">
            <div className="relative w-full m-auto max-w-lg border-[1px] bg-white shadow-lg">
              <div className="flex cursor-pointer text-black w-full justify-center py-2">종료</div>
              <div className="border-0 relative flex flex-col outline-none focus:outline-none">
                <div className="grid grid-cols-4 gap-1 w-full">
                  {
                    endYear === -1 && SELECTED_PERIOD_YEAR.map(year => {
                      return <div key={year} className="flex w-full items-center justify-center p-5 hover:bg-[#dfdfdf] cursor-pointer select-none"
                        style={{
                          pointerEvents: year < startYear ? "none" : "",
                          background: year < startYear && "#cfcfcf"
                        }}
                        onClick={() => {
                          setEndYear(year);
                        }}
                      >
                        {year}
                      </div>
                    })
                  }
                  {
                    (endYear !== -1 && endMonth === -1) && SELECTED_PERIOD_MONTH.map(month => {
                      return <div key={month} className="flex w-full items-center justify-center p-5 hover:bg-[#dfdfdf] cursor-pointer select-none"
                        style={{
                          pointerEvents: (startYear === endYear && month < startMonth) ? "none" : "",
                          background: (startYear === endYear && month < startMonth) && "#cfcfcf"
                        }}
                        onClick={() => {
                          setEndMonth(month);
                          setShowPeriodEnd(false);

                          let shopMonthList = [];

                          shopMonthList = shopAccountList.filter(shopAccount => {
                            let start = new Date(startYear, startMonth - 1, 1, 0, 0, 0, 0).getTime();
                            let end = new Date(endYear, month, 0, 0, 0, 0, 0).getTime();
                            let visit = new Date(shopAccount.visitDate.replace(" ", "T")).getTime();

                            return start <= visit && visit <= end;
                          });

                          let tmpList = [];

                          for (let i = 0; i < shopList.length; i++) {
                            let shopInfo = shopList[i];

                            tmpList.push({
                              id: shopInfo.id,
                              count: shopMonthList.filter(item => item.shopId === shopInfo.id)?.length
                            });
                          }

                          setShopResultForMenu(tmpList);
                        }}
                      >
                        {month}
                      </div>
                    })
                  }
                </div>
              </div>
              <div className="flex cursor-pointer text-white bg-[black] w-full justify-center py-2" onClick={() => {
                setShowPeriodEnd(false);
                setEndYear(-1);
                setEndMonth(-1);
              }}>
                <div>닫기</div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
    </div>
  );
};

export default ShopAccountPage;
