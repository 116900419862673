import React, { useState, useEffect } from "react";
import * as Utils from '../features/Utils';
import * as HTTPManager from '../features/HTTPManager';
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../features/Constant";
import RegistBusCompany from "./RegistBusCompany";
import ColorButton from "./ColorButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBusSimple } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import ModifyModal from "./ModifyModal";

export default function () {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const [busList, setBusList] = useState([]);
  const [busFilterList, setBusFilterList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [priceFilterList, setPriceFilterList] = useState([]);

  const [showAddBus, setShowAddBus] = useState(false);
  const [toursBusInfo, setToursBusInfo] = useState([]);

  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const [modifyBusId, setModifyBusId] = useState(0);
  const [modifyValue, setModifyValue] = useState("");
  const [modifyType, setModifyType] = useState("");

  async function fetchData() {
    var response = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setBusList(response.data.busList);
      setBusFilterList(response.data.busList);

      setPriceList(response.data.priceList);
      setPriceFilterList(response.data.priceList);
    }

    response = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setToursBusInfo(response.data.toursBus);
    }
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  function handleModifyInput(id, value, type) {
    setModifyBusId(id);
    setModifyValue(value);
    setModifyType(type);
    setShowModifyDialog(true);
  }

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-14 px-[50px] md:px-[30px] pb-5">
      <div className="text-start mt-[50px] text-[#9299A1]">버스</div>
      <div className="flex flex-row items-center mt-[50px]">
        <div className="text-start text-[#333A40] font-bold text-[30px]">등록 및 목록</div>
      </div>

      <div className="w-full flex flex-row gap-8 mt-[25px] scrollRemoveBox px-1">
        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faBusSimple} style={{ color: "#1D3C77" }} />
            </span>
            <div>등록</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(busFilterList?.length)}곳</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faBusSimple} style={{ color: "#1D3C77" }} />
            </span>
            <div>미사용</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">
            {
              Utils.formatNumber(busList.filter(bus => toursBusInfo.find(toursBus => toursBus.busId === bus.id) === undefined)?.length)
            }곳</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCalendar} style={{ color: "#1D3C77" }} />
            </span>
            <div>총 예약수</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(busFilterList.reduce((a, b) => {
            a = a + toursBusInfo.filter(toursBus => toursBus.busId === b.id)?.length;
            return a;
          }, 0))}건</div>
        </div>
      </div>

      <div className="flex justify-end">
        <ColorButton title="등록" color="black" handler={() => setShowAddBus(!showAddBus)} />
      </div>
      <div className="w-full flex flex-col">
        <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

        <div className="grid grid-cols-[5%_30%_30%_30%_5%] sticky top-0 w-full text-[white] text-center text-[16px] font-bold bg-[black] py-3">
          <div>번호</div>
          <div>이름</div>
          <div>지역</div>
          <div>담당자</div>
          <div>예약수</div>
        </div>
        <table>
          <tbody>
            {
              busFilterList?.sort((a, b) => {
                if (toursBusInfo.filter(toursBus => toursBus.busId === a.id)?.length > toursBusInfo.filter(toursBus => toursBus.busId === b.id)?.length)
                  return -1;
                else
                  return 1;
              }).map((item, index) => {
                return (
                  <tr key={item.id} className="grid grid-cols-[5%_30%_30%_30%_5%] text-center border-[#E2E2E2] border-x-[1px] border-b-[1px] text-[#A8A9A8] font-semibold items-center normal-case">
                    <td>{item.id}</td>
                    <td className="text-[black] hover:bg-[#dfdfdf] flex h-full py-5 justify-center cursor-pointer" onClick={() => {
                      handleModifyInput(item.id, item.name, "name");
                    }}>{item.name}</td>
                    <td className="text-[black] hover:bg-[#dfdfdf] flex h-full py-5 justify-center cursor-pointer" onClick={() => {
                      handleModifyInput(item.id, item.area, "area");
                    }}>{item.area}</td>
                    <td className="text-[black] hover:bg-[#dfdfdf] flex h-full py-5 justify-center cursor-pointer" onClick={() => {
                      handleModifyInput(item.id, item.manager, "manager");
                    }}>{item.manager}</td>
                    <td className="text-[black]">{Utils.formatNumber(toursBusInfo.filter(toursBus => toursBus.busId === item.id)?.length)}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <div className="fixed flex flex-col w-full md:top-14">
        {
          showAddBus &&
          <>
            <div className="flex w-full h-full text-center">
              <RegistBusCompany setShow={setShowAddBus} refresh={fetchData} />
            </div>
          </>
        }
      </div>
      <ModifyModal show={showModifyDialog} setShow={setShowModifyDialog} value={modifyValue} setValue={setModifyValue} onClick={async () => {
        let res = await HTTPManager.UpdateBusInfo({
          accessToken: userInfo.accessToken,
          busId: modifyBusId,
          value: modifyValue,
          type: modifyType,
        });

        if (res?.data?.result) {
          await fetchData();
        } else {
          alert("변경실패!");
        }

        setShowModifyDialog(false);
      }} />
    </div >
  );
}