import React, { useEffect, } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import RequestBoard from "./RequestBoard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

export default function RequestWindow() {
  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  async function fetchData() {
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.MANAGER, ROLES.ITS, ROLES.GOD], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  return (
    <div className="w-full bg-[white] md:mt-14 p-3">
      <div className="flex flex-row w-full justify-center mb-5">
        <div className=""
          style={{
            fontSize: 20,
            color: "black",
            cursor: "pointer",
            textAlign: "center",
            marginLeft: 9,
            visibility: "hidden"
          }}
          onClick={() => { }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <div className="flex w-full justify-center items-center font-bold">{languageSet.requestBoardTitle}</div>
        <button className="w-[0px]"
          style={{
            fontWeight: "bold",
            outline: "none",
            background: "#000",
            color: "#f00",
            borderRadius: 5,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 7,
            paddingLeft: 7,
            visibility: "hidden"
          }}
        />
      </div>
      <RequestBoard />
    </div>
  );
}