import React from "react";
import { useEffect, useState } from "react";
import * as HTTPManager from "../../features/HTTPManager";
import * as Utils from "../../features/Utils";
import { useLocation } from "react-router-dom";
import PurchaseInput from "./PurchaseInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus, faX } from "@fortawesome/free-solid-svg-icons";
import ColorButton from "../ColorButton";
import SalesInput from "./SalesInput";
import { UserInfo, ShowInfo } from "../../recoil";
import { TYPE_OF_PAYMENT, METHOD_OF_PAYMENT, PROOF_OF_CASH, SALES_COMPANY_ID } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../features/Constant"
import { useRecoilValue, } from "recoil";
import AdminInputDialog from "./AdminInputDialog";

const PageSettlement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useRecoilValue(UserInfo);
  const showInfo = useRecoilValue(ShowInfo);

  const tourInfo = location.state.tourInfo;
  const pax = Number(tourInfo.pax.replace("명", "").split("+")[0]);
  const guideName = location.state.guideName;

  // 입력창 목록
  const [purchaseList, setPurchaseList] = useState([]);
  const [salesList, setSalesList] = useState([]);

  // 지출 타입 목록
  const [purchaseTypeList, setPurchaseTypeList] = useState([]);

  // 매출 회사 목록
  const [salesCompanyList, setSalesCompanyList] = useState([]);

  // 서버 데이터
  const [tourPurcahseList, setTourPurchaseList] = useState([]);
  const [tourSalesList, setTourSalesList] = useState([]);

  const [totalPurchase, setTotalPurchase] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  // 관리자 전용 정보
  const [toursGuide, setToursGuide] = useState(location.state.toursGuide);
  const [guideFee, setGuideFee] = useState(Number((toursGuide?.guideFee === null || toursGuide?.guideFee === undefined) ? 0 : toursGuide?.guideFee))
  const [preCharge, setPreCharge] = useState(Number((toursGuide?.preCharge === null || toursGuide?.preCharge === undefined) ? 0 : toursGuide?.preCharge));
  const [shopIncentive, setShopIncentive] = useState(Number((toursGuide?.shopIncentive === null || toursGuide?.shopIncentive === undefined) ? 0 : toursGuide?.shopIncentive));
  const [totalUsingAmount, setTotalUsingAmount] = useState(0);

  const [showAdminInputDialog, setShowAdminInputDialog] = useState(false);

  // 최종 매출금액 수정팝업
  const [showUpdateFinalAmount, setShowUpdateFinalAmount] = useState(false);
  const [salesCompanyName, setSalesCompanyName] = useState("");
  const [updateSalesId, setUpdateSalesId] = useState(0);
  const [updateSalesType, setUpdateSalesType] = useState("");
  const [updateSalesValue, setSalesValue] = useState("");

  function updatePurchaseList() {
    setPurchaseList([...purchaseList, {
      date: Utils.convertLocalToUTCDate(tourInfo.startDate),
      type: "",
      content: "",
      amount: "",
      paymentMethod: "",
      cashProof: "",
    }]);
  }

  function updateSalesList() {
    setSalesList([...salesList, {
      date: Utils.convertLocalToUTCDate(tourInfo.startDate),
      type: "",
      amount: "",
    }]);
  }

  async function fetchData() {
    let res = await HTTPManager.GetSalesCompanyList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data.result) {
      setSalesCompanyList(res.data.companyList);
    }

    res = await HTTPManager.GetPuchaseTypeList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data.result) {
      setPurchaseTypeList(res.data.purchaseTypeList);
    }

    res = await HTTPManager.GetPurchaseList({
      tourId: tourInfo.tourId,
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data.result) {
      setTourPurchaseList(res.data.purchaseList);
      setTotalPurchase(res.data.purchaseList?.reduce((a, b) => a = a + Number(b.amount), 0));
      setTotalUsingAmount(res.data.purchaseList?.filter(purchase => purchase.method === METHOD_OF_PAYMENT.CASH).reduce((a, b) => a = a + Number(b.amount), 0));
    }

    res = await HTTPManager.GetSalesList({
      tourId: tourInfo.tourId,
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data.result) {
      setTourSalesList(res.data.salesList);
      setTotalSales(res.data.salesList?.reduce((a, b) => a = a + Number(b.finalAmount), 0));
    }

    if (userInfo.rolesId === ROLES.MANAGER) {
      res = await HTTPManager.GetTourReservation({
        accessToken: userInfo.accessToken,
      });

      if (res !== undefined && res.data !== undefined) {
        let tourGuideInfo = res.data.toursGuide.find(item => item.tourId === toursGuide?.tourId && item.guideId === toursGuide?.guideId);
        if (tourGuideInfo !== undefined) {
          setGuideFee(tourGuideInfo.guideFee);
          setPreCharge(tourGuideInfo.preCharge);
          setShopIncentive(tourGuideInfo.shopIncentive);
          setToursGuide(tourGuideInfo);
        }
      }
    }
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.GUIDE], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  return (
    <div className="mt-2 md:mt-12 p-[20px] md:px-1 w-full text-center text-[13px]">
      {
        userInfo.rolesId === ROLES.MANAGER &&
        <div className="flex">
          <ColorButton title="관리자 입력" color={"black"} handler={() => setShowAdminInputDialog(!showAdminInputDialog)} />
        </div>
      }
      <div className="mb-2 font-bold text-[20px] flex flex-row w-full justify-center">
        가이드 정산서
        {
          userInfo.rolesId === ROLES.MANAGER && <div>[{tourInfo.id}]</div>
        }
      </div>
      <div className="flex justify-between mb-0.5">
        <span className="font-bold">기간</span>
        <span>
          {tourInfo.startDate.replace(" 00:00:00", "")} ~{" "}
          {tourInfo.endDate.replace(" 00:00:00", "")}
        </span>
      </div>

      <div className="relative flex justify-between mb-0.5">
        {
          showInfo &&
          <div className="absolute w-full h-full bg-[white] ring ring-white ring-offset ring-offset-transparent top-0 left-0 rounded z-[103] text-[crimson]" >
            실제 행사 인원과 다른 경우 문의 부탁드립니다.
          </div>
        }
        <span className="font-bold">인원</span>
        <span>
          {tourInfo.pax}
        </span>
      </div>

      <div className="flex justify-between mb-4">
        <span className="font-bold">가이드</span>
        <span>
          {guideName}
        </span>
      </div>

      <div className="relative">
        {
          showInfo &&
          <div className="absolute flex flex-col w-full h-full bg-[white] ring ring-white ring-offset ring-offset-transparent top-0 left-0 rounded z-[103] text-start pl-1 pt-0" >
            <div className="font-bold mt-1">
              1. 매입(지출) 입력 사용방법
            </div>
            <div>
              1. 추가 버튼(여러 개 가능)
            </div>
            <div>
              2. 날짜 선택 &gt; 분류 &gt; 내용(가게명) &gt; 금액 &gt; 결제수단(카드, 현금) &gt; 현금 &gt; 증빙여부 선택
            </div>
            <div className="mb-2">
              3. 등록 및 하단 매입(지출)내역 확인
            </div>

            <div className="font-bold">
              주의 사항
            </div>
            <div className="text-[crimson]">
              - 분류 (호텔 : 금액을 모르시는 경우 0으로 입력, 기타 : 기사님 일비, 스태프 식비, wifi, 택시비 등 기타 항목 입력)
            </div>
            <div className="text-[crimson]">
              - 회사 카드로 결제한 경우만 결제수단 카드선택
            </div>
            <div className="text-[crimson]">
              - 가이드분 본인 카드로 결제 시 현금, 증빙여부 X로 입력 부탁드립니다.
            </div>
            <div className="text-[crimson]">
              - 현금 영수증 처리되지 않은 항목 또한 증빙여부 X로 입력 부탁드립니다.
            </div>
            <div className="text-[crimson] mb-4">
              - 기타 분류의 경우 날짜는 정확하지 않아도 됩니다. (투어 기간 중 특정 날짜면 OK)
            </div>

            <div className="font-bold">
              2. 매출 입력 사용방법
            </div>
            <div>
              1. 추가 버튼(여러 개 가능)
            </div>
            <div className="normal-case">
              2. 날짜 선택 &gt; 분류 &gt; 매출액 or 수량 입력
            </div>
            <div className="mb-2">
              3. 등록 및 하단 매출내역 확인
            </div>
            <div className="font-bold">
              주의 사항
            </div>
            <div className="text-[crimson] mb-4">
              - 분류에 없는 매장의 경우 문의 부탁드립니다. 기타 선택 후 메모에 적어주셔도 무방합니다. (추후 목록 업데이트 처리)
            </div>

            <div className="font-bold">
              3. 가이드 정산 항목
            </div>
            <div>
              - 가이드 비용, 전도금, 사용금액이 예상과 다른 경우 문의 부탁드립니다.
            </div>
            <div>
              - 쇼핑율(%) = (청하 + 적송 매장 매출 개수) / 인원수(대장제외) * 100
            </div>
            <div>
              - 쇼핑 인센티브 (쇼핑율에 따라 다름)
            </div>
            <div>
              - 100% 이상 ~ 150% 미만 : 개수 * 10,000원
            </div>
            <div>
              - 150% 이상 ~ 200% 미만 : 개수 * 15,000원
            </div>
            <div>
              - 200% 이상 ~ 250% 미만 : 개수 * 20,000원
            </div>
            <div>
              - 250% 이상 ~ 300% 미만 : 개수 * 25,000원
            </div>
            <div className="mb-2">
              - 300% 이상 : 개수 * 30,000원
            </div>
            <div className="font-bold">
              - 소계 = 가이드 비용(3.3% 제외한 금액) - 전도금 차액 + 쇼핑 인센티브
            </div>

          </div>
        }
        <div
          style={{
            marginBottom: 10,
            position: "relative",
            display: "flex",
            width: "100%",
          }}
        >

          <details
            style={{ width: "100%" }}
            className="relative open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-2 rounded-lg"
            open
          >

            <summary className=" text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left">
              매입(지출) 입력 ({purchaseList?.length})
            </summary>
            <div className=" mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400">

              <div className="flex flex-row w-full mb-4">
                <ColorButton title={"추가"} color={"skyblue"} width={"100%"} handler={() => {
                  updatePurchaseList();
                }} />
                <ColorButton title={"등록"} color={"crimson"} width={"100%"} handler={async () => {
                  let res = await HTTPManager.AddPurchaseList({
                    tourId: tourInfo.tourId,
                    accessToken: userInfo.accessToken,
                    purchaseList: purchaseList,
                  });

                  if (res !== undefined && res.data?.result) {
                    alert('등록 성공');
                    setPurchaseList([]);
                    fetchData();
                  } else {
                    alert('등록 실패');
                  }
                }} />
              </div>
              <div className="flex flex-row md:hidden text-[13px]">
                {/* UI 위치 보정 위함 */}
                <div className="text-[18px] text-[transparent] mr-2 md:mr-0">
                  <FontAwesomeIcon icon={faSquareMinus} />
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">일자</div>
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">분류</div>
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">내용</div>
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">금액</div>
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">결제수단</div>
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">증빙여부</div>
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">메모</div>
                </div>
              </div>
              <div className="mb-2">
                {
                  purchaseList?.map((purchase, index) => {
                    let itemStyle = "mb-2";
                    let lastItemStyle = "mb-0";

                    return (
                      <div key={index}
                        className={index !== purchaseList.length - 1 ? itemStyle : lastItemStyle}>
                        <PurchaseInput
                          purchaseTypeList={purchaseTypeList}
                          tourInfo={tourInfo}
                          startDate={tourInfo.startDate}
                          handler={() => {
                            const tempArray = [...purchaseList];
                            tempArray.splice(index, 1);
                            setPurchaseList(tempArray);
                          }}
                          list={purchaseList}
                          setList={setPurchaseList}
                          index={index}
                        />
                      </div>
                    )
                  })
                }
              </div>

            </div>
          </details>
        </div>

        <div
          style={{
            marginBottom: 10,
            position: "relative",
            display: "flex",
            width: "100%",
          }}
        >
          <details
            style={{ width: "100%" }}
            className="open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-2 rounded-lg"
            open
          >
            <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left">
              매출 입력 ({salesList?.length})
            </summary>
            <div className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400">
              <div className="flex flex-row w-full mb-4">
                <ColorButton title={"추가"} color={"skyblue"} width={"100%"} handler={() => {
                  updateSalesList();
                }} />
                <ColorButton title={"등록"} color={"crimson"} width={"100%"} handler={async () => {
                  let res = await HTTPManager.AddSalesList({
                    tourId: tourInfo.tourId,
                    accessToken: userInfo.accessToken,
                    salesList: salesList,
                  });

                  if (res !== undefined && res.data?.result) {
                    alert('등록 성공');
                    setSalesList([]);
                    fetchData();
                  } else {
                    alert('등록 실패');
                  }
                }} />
              </div>
              <div className="flex flex-row md:hidden text-[13px]">
                {/* UI 위치 보정 위함 */}
                <div className="text-[18px] text-[transparent] mr-2 md:mr-0">
                  <FontAwesomeIcon icon={faSquareMinus} />
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">일자</div>
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">분류</div>
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black] normal-case">매출액 or 수량</div>
                </div>

                <div className="flex flex-col items-center w-full">
                  <div className="mb-[10px] text-[black]">메모</div>
                </div>
              </div>
              <div className="mb-2">
                {
                  salesList?.map((sales, index) => {
                    let itemStyle = "mb-2";
                    let lastItemStyle = "mb-0";

                    return (
                      <div key={index}
                        className={index !== salesList.length - 1 ? itemStyle : lastItemStyle}
                      >
                        <SalesInput
                          companyList={salesCompanyList}
                          tourInfo={tourInfo}
                          handler={() => {
                            const tempArray = [...salesList];
                            tempArray.splice(index, 1);
                            setSalesList(tempArray);
                          }}
                          list={salesList}
                          setList={setSalesList}
                          index={index}
                        />
                      </div>
                    )
                  })
                }
              </div>

            </div>
          </details>
        </div >

        <div className="flex relative mb-[10px] w-full">
          <details
            style={{ width: "100%" }}
            className="open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-2 rounded-lg"
            open
          >
            <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left mb-2">
              매입(지출) 내역
            </summary>
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <details
                className="w-full open:bg-[#FCF5E7] dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-3 rounded-lg mb-2"
                open
              >
                <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left">
                  호텔
                </summary>
                <div className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400 mb-2">
                  {
                    tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.HOTEL)?.map((item, index) => {
                      let deleteMsg = `${item.date?.slice(0, 10)}, ${item.content}, ${Utils.formatNumber(item.amount)}`

                      return (
                        <div key={index} className="flex flex-row">
                          <div className="text-[18px] text-[grey] mr-2"
                            onClick={async () => {
                              let answer = window.confirm(deleteMsg + " 삭제하시겠습니까?");

                              if (answer) {
                                let res = await HTTPManager.DeletePurchaseList({
                                  tourId: tourInfo.tourId,
                                  id: item.id,
                                  accessToken: userInfo.accessToken,
                                });

                                if (res !== undefined && res.data.result) {
                                  fetchData();
                                }
                              }
                            }}>
                            <FontAwesomeIcon icon={faSquareMinus} />
                          </div>
                          <div className="mr-2">{item.date?.slice(0, 10)}</div>
                          <div className="mr-2">{item.content}</div>
                          <div className="mr-2">{Utils.formatNumber(item.amount)}</div>
                          {
                            (item.memo !== undefined && item.memo !== null) && <div>({item.memo})</div>
                          }
                        </div>
                      )
                    })
                  }
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row md:flex-col w-full justify-end">
                    <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                      <div className="mr-1">[회사 카드]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.HOTEL && purchase.method === METHOD_OF_PAYMENT.CARD).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                    <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                      <div className="mr-1">[현금 증빙 O]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.HOTEL && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.YES).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                    <div className="flex flex-row md:justify-between">
                      <div className="mr-1">[현금 증빙 X]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.HOTEL && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.NO).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end text-[15px]">
                    <div className="mr-1">호텔 총계</div>
                    <div className="font-bold">
                      {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.HOTEL).reduce((a, b) => a = a + Number(b.amount), 0))}
                    </div>
                  </div>
                </div>
              </details>

              {
                // 버스 비용 관리자만 표시
                userInfo.rolesId === ROLES.MANAGER &&
                <details
                  className="w-full open:bg-[#FCF5E7] dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-3 rounded-lg mb-2"
                  open
                >
                  <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left">
                    버스
                  </summary>
                  <div className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400 mb-2">
                    {
                      tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.BUS)?.map((item, index) => {
                        let deleteMsg = `${item.date?.slice(0, 10)}, ${item.content}, ${Utils.formatNumber(item.amount)}`

                        return (
                          <div key={index} className="flex flex-row">
                            <div className="text-[18px] text-[grey] mr-2"
                              onClick={async () => {
                                let answer = window.confirm(deleteMsg + " 삭제하시겠습니까?");

                                if (answer) {
                                  let res = await HTTPManager.DeletePurchaseList({
                                    tourId: tourInfo.tourId,
                                    id: item.id,
                                    accessToken: userInfo.accessToken,
                                  });

                                  if (res !== undefined && res.data.result) {
                                    fetchData();
                                  }
                                }
                              }}>
                              <FontAwesomeIcon icon={faSquareMinus} />
                            </div>
                            <div className="mr-2">{item.date?.slice(0, 10)}</div>
                            <div className="mr-2">{item.content}</div>
                            <div className="mr-2">{Utils.formatNumber(item.amount)}</div>
                            {
                              (item.memo !== undefined && item.memo !== null) && <div>({item.memo})</div>
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row md:flex-col w-full justify-end">
                      <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                        <div className="mr-1">[회사 카드]</div>
                        <div className="font-bold">
                          {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.BUS && purchase.method === METHOD_OF_PAYMENT.CARD).reduce((a, b) => a = a + Number(b.amount), 0))}
                        </div>
                      </div>
                      <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                        <div className="mr-1">[현금 증빙 O]</div>
                        <div className="font-bold">
                          {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.BUS && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.YES).reduce((a, b) => a = a + Number(b.amount), 0))}
                        </div>
                      </div>
                      <div className="flex flex-row md:justify-between">
                        <div className="mr-1">[현금 증빙 X]</div>
                        <div className="font-bold">
                          {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.BUS && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.NO).reduce((a, b) => a = a + Number(b.amount), 0))}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end text-[15px]">
                      <div className="mr-1">버스 총계</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.BUS).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                  </div>
                </details>
              }

              <details
                className="w-full open:bg-[white] dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-3 rounded-lg mb-2"
                open
              >
                <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left">
                  식대
                </summary>
                <div className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400 text-left">
                  {
                    tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.TOURIST_MEAL)?.map((item, index) => {
                      let deleteMsg = `${item.date?.slice(0, 10)}, ${item.content}, ${Utils.formatNumber(item.amount)}`

                      return (
                        <div key={index} className="flex flex-row">
                          <div className="text-[18px] text-[grey] mr-2"
                            onClick={async () => {
                              let answer = window.confirm(deleteMsg + " 삭제하시겠습니까?");

                              if (answer) {
                                let res = await HTTPManager.DeletePurchaseList({
                                  tourId: tourInfo.tourId,
                                  id: item.id,
                                  accessToken: userInfo.accessToken,
                                });

                                if (res !== undefined && res.data.result) {
                                  fetchData();
                                }
                              }
                            }}>
                            <FontAwesomeIcon icon={faSquareMinus} />
                          </div>
                          <div className="mr-2">{item.date?.slice(0, 10)}</div>
                          <div className="mr-2">{item.content}</div>
                          <div className="mr-2">{Utils.formatNumber(item.amount)}</div>
                          {
                            (item.memo !== undefined && item.memo !== null) && <div>({item.memo})</div>
                          }
                        </div>
                      )
                    })
                  }
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row md:flex-col w-full justify-end">
                    <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                      <div className="mr-1">[회사 카드]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.TOURIST_MEAL && purchase.method === METHOD_OF_PAYMENT.CARD).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                    <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                      <div className="mr-1">[현금 증빙 O]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.TOURIST_MEAL && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.YES).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                    <div className="flex flex-row md:justify-between">
                      <div className="mr-1">[현금 증빙 X]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.TOURIST_MEAL && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.NO).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end text-[15px]">
                    <div className="mr-1">식대 총계</div>
                    <div className="font-bold">
                      {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.TOURIST_MEAL).reduce((a, b) => a = a + Number(b.amount), 0))}
                    </div>
                  </div>
                </div>
              </details>
              <details
                className="w-full open:bg-[#E6EEF7] dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-3 rounded-lg mb-2"
                open
              >
                <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left">
                  관광지
                </summary>
                <div className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400">
                  {
                    tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.ATTRACTION)?.map((item, index) => {
                      let deleteMsg = `${item.date?.slice(0, 10)}, ${item.content}, ${Utils.formatNumber(item.amount)}`

                      return (
                        <div key={index} className="flex flex-row">
                          <div className="text-[18px] text-[grey] mr-2"
                            onClick={async () => {
                              let answer = window.confirm(deleteMsg + " 삭제하시겠습니까?");

                              if (answer) {
                                let res = await HTTPManager.DeletePurchaseList({
                                  tourId: tourInfo.tourId,
                                  id: item.id,
                                  accessToken: userInfo.accessToken,
                                });

                                if (res !== undefined && res.data.result) {
                                  fetchData();
                                }
                              }
                            }}>
                            <FontAwesomeIcon icon={faSquareMinus} />
                          </div>
                          <div className="mr-2">{item.date?.slice(0, 10)}</div>
                          <div className="mr-2">{item.content}</div>
                          <div className="mr-2">{Utils.formatNumber(item.amount)}</div>
                          {
                            (item.memo !== undefined && item.memo !== null) && <div>({item.memo})</div>
                          }
                        </div>
                      )
                    })
                  }
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row md:flex-col w-full justify-end">
                    <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                      <div className="mr-1">[회사 카드]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.ATTRACTION && purchase.method === METHOD_OF_PAYMENT.CARD).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                    <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                      <div className="mr-1">[현금 증빙 O]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.ATTRACTION && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.YES).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                    <div className="flex flex-row md:justify-between">
                      <div className="mr-1">[현금 증빙 X]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.ATTRACTION && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.NO).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end text-[15px]">
                    <div className="mr-1">관광지 총계</div>
                    <div className="font-bold">
                      {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.type === TYPE_OF_PAYMENT.ATTRACTION).reduce((a, b) => a = a + Number(b.amount), 0))}
                    </div>
                  </div>
                </div>
              </details>
              <details
                className="w-full open:bg-[white] dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-3 rounded-lg mb-6"
                open
              >
                <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left">
                  기타
                </summary>
                <div className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400">
                  {
                    tourPurcahseList?.filter(purchase => (purchase.type === TYPE_OF_PAYMENT.ETC || purchase.type === TYPE_OF_PAYMENT.COMISSION || purchase.type === TYPE_OF_PAYMENT.TRAFFIC_CHARGE))?.map((item, index) => {
                      let deleteMsg = `${item.date?.slice(0, 10)}, ${item.content}, ${Utils.formatNumber(item.amount)}`

                      return (
                        <div key={index} className="flex flex-row">
                          <div className="text-[18px] text-[grey] mr-2"
                            onClick={async () => {
                              let answer = window.confirm(deleteMsg + " 삭제하시겠습니까?");

                              if (answer) {
                                let res = await HTTPManager.DeletePurchaseList({
                                  tourId: tourInfo.tourId,
                                  id: item.id,
                                  accessToken: userInfo.accessToken,
                                });

                                if (res !== undefined && res.data.result) {
                                  fetchData();
                                }
                              }
                            }}>
                            <FontAwesomeIcon icon={faSquareMinus} />
                          </div>
                          <div className="mr-2">{item.date?.slice(0, 10)}</div>
                          <div className="mr-2">{item.content}</div>
                          <div className="mr-2">{Utils.formatNumber(item.amount)}</div>
                          {
                            (item.memo !== undefined && item.memo !== null) && <div>({item.memo})</div>
                          }
                        </div>
                      )
                    })
                  }
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row md:flex-col w-full justify-end">
                    <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                      <div className="mr-1">[회사 카드]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => (purchase.type === TYPE_OF_PAYMENT.ETC || purchase.type === TYPE_OF_PAYMENT.COMISSION || purchase.type === TYPE_OF_PAYMENT.TRAFFIC_CHARGE) && purchase.method === METHOD_OF_PAYMENT.CARD).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                    <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                      <div className="mr-1">[현금 증빙 O]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => (purchase.type === TYPE_OF_PAYMENT.ETC || purchase.type === TYPE_OF_PAYMENT.COMISSION || purchase.type === TYPE_OF_PAYMENT.TRAFFIC_CHARGE) && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.YES).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                    <div className="flex flex-row md:justify-between">
                      <div className="mr-1">[현금 증빙 X]</div>
                      <div className="font-bold">
                        {Utils.formatNumber(tourPurcahseList?.filter(purchase => (purchase.type === TYPE_OF_PAYMENT.ETC || purchase.type === TYPE_OF_PAYMENT.COMISSION || purchase.type === TYPE_OF_PAYMENT.TRAFFIC_CHARGE) && purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.NO).reduce((a, b) => a = a + Number(b.amount), 0))}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end text-[15px]">
                    <div className="mr-1">기타 총계</div>
                    <div className="font-bold">
                      {Utils.formatNumber(tourPurcahseList?.filter(purchase => (purchase.type === TYPE_OF_PAYMENT.ETC || purchase.type === TYPE_OF_PAYMENT.COMISSION || purchase.type === TYPE_OF_PAYMENT.TRAFFIC_CHARGE)).reduce((a, b) => a = a + Number(b.amount), 0))}
                    </div>
                  </div>
                </div>
              </details>

              <div className="flex flex-col w-full">
                <div className="flex flex-row md:flex-col w-full justify-end">
                  <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                    <div className="mr-1">[회사 카드]</div>
                    <div className="font-bold">
                      {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.method === METHOD_OF_PAYMENT.CARD).reduce((a, b) => a = a + Number(b.amount), 0))}
                    </div>
                  </div>
                  <div className="flex flex-row mr-2 md:mr-0 md:justify-between">
                    <div className="mr-1">[현금 증빙 O]</div>
                    <div className="font-bold">
                      {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.YES).reduce((a, b) => a = a + Number(b.amount), 0))}
                    </div>
                  </div>
                  <div className="flex flex-row md:justify-between">
                    <div className="mr-1">[현금 증빙 X]</div>
                    <div className="font-bold">
                      {Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.method === METHOD_OF_PAYMENT.CASH && purchase.proof === PROOF_OF_CASH.NO).reduce((a, b) => a = a + Number(b.amount), 0))}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end text-[15px]">
                  <div className="mr-1">전체 총계</div>
                  <div className="font-bold">
                    {Utils.formatNumber(tourPurcahseList?.filter(purchase => true).reduce((a, b) => a = a + Number(b.amount), 0))}
                  </div>
                </div>
              </div>
            </div>
          </details>
        </div>

        <div className="flex relative mb-[10px] w-full">
          <details
            className="w-full open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-2 rounded-lg"
            open
          >
            <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left mb-2">
              매출 내역
            </summary>
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400">
                {
                  tourSalesList?.map((item, index) => {
                    let companyInfo = salesCompanyList.find(company => company.id === item.companyId);
                    let deleteMsg = `${item.date?.slice(0, 10)}, ${companyInfo.type}, ${companyInfo.name} ${Utils.formatNumber(item.amount)}`;

                    return (
                      <div key={index} className="flex flex-row">
                        <div className="text-[18px] text-[grey] mr-2" onClick={async () => {
                          let answer = window.confirm(deleteMsg + " 삭제하시겠습니까?");

                          if (answer) {
                            let res = await HTTPManager.DeleteSalesList({
                              tourId: tourInfo.id,
                              id: item.id,
                              accessToken: userInfo.accessToken,
                            });

                            if (res !== undefined && res.data.result) {
                              fetchData();
                            }
                          }
                        }}>
                          <FontAwesomeIcon icon={faSquareMinus} />
                        </div>
                        <div className="mr-2">{item.date?.slice(2, 10)}</div>
                        {/* <div className="mr-2">{companyInfo.type}</div> */}
                        <div className="mr-2">{companyInfo.name}</div>
                        <div className="mr-2">{Utils.formatNumber(item.amount)}</div>
                        {
                          userInfo.rolesId === ROLES.MANAGER &&
                          <div className="mr-2 text-[crimson] cursor-pointer" onClick={() => {
                            setShowUpdateFinalAmount(true);
                            setUpdateSalesId(item.id);
                            setUpdateSalesType("finalAmount");
                            setSalesCompanyName(companyInfo.name);
                            setSalesValue((item.finalAmount !== undefined && item.finalAmount !== null) ? item.finalAmount : "");
                          }}>
                            {
                              (item.finalAmount !== undefined && item.finalAmount !== null) ? Utils.formatNumber(item.finalAmount) : "최종매출입력"
                            }
                          </div>
                        }
                        {
                          (item.memo !== undefined && item.memo !== null) && <div>({item.memo})</div>
                        }
                      </div>
                    )
                  })
                }
                {
                  userInfo.rolesId === ROLES.MANAGER &&
                  <div className="flex justify-end font-bold text-[black]">
                    총액(인보이스제외) {Utils.formatNumber(tourSalesList?.filter(sales => sales.companyId !== SALES_COMPANY_ID.INVOICE).reduce((a, b) => a = a + Number(b.finalAmount), 0))}
                  </div>
                }
              </div>
            </div>
          </details>
        </div>

        <div className="flex relative mb-[10px] w-full">
          <details
            className="w-full open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-2 rounded-lg"
            open
          >
            <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left mb-2">
              가이드 정산
            </summary>
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400 text-start">
                <div className="flex flex-row justify-between">
                  <div>가이드 비용</div>
                  <div>
                    {
                      `${Utils.formatNumber(Number(guideFee) * 0.967)} (${Utils.formatNumber(Number(guideFee))})`
                    }
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div>전도금</div>
                  <div>
                    {
                      Utils.formatNumber(Number(preCharge))
                    }
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div>사용금액</div>
                  <div>
                    {
                      Utils.formatNumber(tourPurcahseList?.filter(purchase => purchase.method === METHOD_OF_PAYMENT.CASH).reduce((a, b) => a = a + Number(b.amount), 0))
                    }
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div>전도금 차액</div>
                  <div style={{
                    color: preCharge - totalUsingAmount > 0 ? "blue" : "red",
                  }}>
                    {Utils.formatNumber(preCharge - totalUsingAmount)}
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div>쇼핑율</div>
                  <div>
                    {((tourSalesList.filter(item => item.companyId === 1 || item.companyId === 2).reduce((a, b) => a = a + Number(b.amount), 0)) / pax * 100).toFixed(2)}%
                  </div>
                </div>
                {
                  (tourSalesList.filter(item => item.companyId === 1 || item.companyId === 2).reduce((a, b) => a = a + Number(b.amount), 0)) / pax * 100 >= 100 &&
                  <div className="flex flex-row justify-between">
                    <div>쇼핑 인센티브</div>
                    <div>
                      {
                        Utils.formatNumber(Number(shopIncentive))
                      }
                    </div>
                  </div>
                }
              </div>
              <div className="flex flex-col w-full mt-3">
                <div className="flex flex-row md:flex-col w-full justify-end">
                  <div className="flex flex-row md:justify-between">
                    <div className="mr-1">[소계]</div>
                    <div className="font-bold">
                      {Utils.formatNumber((Number(guideFee) * 0.967 + Number(shopIncentive) * 0.967 - (preCharge - totalUsingAmount)).toFixed(0))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full mt-3">
                <div className="flex flex-row md:flex-col w-full justify-end">
                  <div className="flex flex-col text-end">
                    <div>은행 : 기업은행</div>
                    <div>계좌번호 : 019-119422-04-017</div>
                    <div className="mb-1">입금자명 : 김관우 코리아트래블스토어</div>
                    <div className="font-bold text-[red]">소계가 마이너스인 경우 회사 계좌로 입금부탁드립니다.</div>
                  </div>
                </div>
              </div>
            </div>
          </details>
        </div>

      </div>
      {
        userInfo.rolesId === ROLES.MANAGER && <div className="flex relative mb-[10px] w-full">
          <details
            className="w-full open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 md:px-2 rounded-lg"
            open
          >
            <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none text-left mb-2">
              종합
            </summary>
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400 text-start">
                <div className="flex flex-row justify-between">
                  <div>비용계</div>
                  <div>{Utils.formatNumber(totalPurchase + Number(guideFee) + Number(shopIncentive))}</div>
                </div>
                <div className="flex flex-row justify-between">
                  <div>수익계</div>
                  <div>{Utils.formatNumber(totalSales)}</div>
                </div>
                <div className="flex flex-row justify-between">
                  <div>손익</div>
                  <div style={{
                    fontWeight: "bold",
                    color: totalSales - (totalPurchase + Number(guideFee) + Number(shopIncentive)) > 0 ? "blue" : "red",
                  }}>
                    {Utils.formatNumber(totalSales - (totalPurchase + Number(guideFee) + Number(shopIncentive)))}
                  </div>
                </div>
              </div>
            </div>
          </details>
        </div>
      }
      {
        userInfo.rolesId === ROLES.MANAGER && showAdminInputDialog &&
        <AdminInputDialog setShow={setShowAdminInputDialog} title={"전도금, 가이드비용, 쇼핑 인센티브"} data={toursGuide} refresh={fetchData} />
      }
      {
        showUpdateFinalAmount &&
        <>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          <div className="fixed inset-0 top-[70%] bg-[white] z-40 px-20 md:px-2">
            <div className="flex justify-end items-end pr-1 pt-4">
              <FontAwesomeIcon icon={faX} className="cursor-pointer text-[black]" onClick={() => {
                setSalesValue("");
                setUpdateSalesType("");
                setUpdateSalesId(0);
                setShowUpdateFinalAmount(false);
              }} />
            </div>
            <div className="flex z-40 bg-[white] items-center justify-start mb-2">
              <div className="mr-2">
                [{salesCompanyName}]
              </div>
              <div className="font-bold">
                최종 매출 금액
              </div>
            </div>
            <input
              className="w-full placeholder:text-center placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl mb-3"
              placeholder={"금액"}
              type="text"
              name="userId"
              onChange={(e) => {
                // replace 순서주의
                setSalesValue(e.target.value.replace(/,/g, "").replace(/\D/, ''));
              }}
              value={Utils.formatNumber(updateSalesValue)}
            />
            <ColorButton title="등록" color="slateblue" handler={async () => {
              let res = await HTTPManager.UpdateSales({
                accessToken: userInfo.accessToken,
                id: updateSalesId,
                type: updateSalesType,
                value: updateSalesValue
              });

              if (res.data !== undefined && res.data.result) {
                setShowUpdateFinalAmount(false);
                setSalesValue("");
                setUpdateSalesType("");
                setUpdateSalesId(0);
                await fetchData();
              }
            }} />
          </div>
        </>
      }
    </div>
  );
};

export default PageSettlement;
