import React, { useState, useEffect, useRef, useMemo } from "react";
import { useRecoilState, } from "recoil";
import { ROLES } from "../../features/Constant";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPBusManager from "../../features/HTTPBusManager";
import * as Utils from "../../features/Utils";
import { UserInfo } from "../../recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import "../styles/CommonStyle.css"

const RentalCarPage = () => {
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);

  let searchText = useRef("");
  let focusRef = useRef();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());

  const [userList, setUserList] = useState([]);
  const [tourList, setTourList] = useState([]);
  const [rentalCarList, setRentalCarList] = useState([]);

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";
  const [showMenu, setShowMenu] = useState(false);

  // 가이드 데이터 업데이트 처리
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [updateSelectId, setUpdateSelectId] = useState(0);
  const [updateDataType, setUpdateDataType] = useState("");
  const [updateDataValue, setUpdateDataValue] = useState("");

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      await updateData(updateDataType, updateDataValue);
    }
  }

  async function updateData(dataType, dataValue) {
    let res = await HTTPBusManager.updateRentalCarData({
      accessToken: userInfo.accessToken,
      dataId: updateSelectId,
      dataType: dataType,
      dataValue: dataValue,
    });

    if (res?.data?.result) {
      setShowUpdateDialog(false);
      setUpdateDataType("");
      setUpdateDataValue("");
      await fetchData();
    } else {
      alert("업데이트 실패!");
    }
  }

  function selectedData(id, type, value) {
    setUpdateSelectId(id)
    setUpdateDataType(type);
    setUpdateDataValue(value);
    setShowUpdateDialog(true);
  }

  async function fetchData() {
    if (userInfo.accessToken === undefined) return;

    let res = "";

    if (userInfo.rolesId === ROLES.GOD || userInfo.rolesId === ROLES.MANAGER) {
      res = await HTTPManager.GetUserList({
        accessToken: userInfo.accessToken,
      });

      setUserList(res.data.result);
    }

    res = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let initTourList = [];
    if (res !== undefined) {
      initTourList = res.data.result;
      setTourList(initTourList);
    }

    res = await HTTPBusManager.getRentalCarList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      setRentalCarList(res.data.result);
    }
  }

  const handleCapture = async (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];

        let res = await HTTPBusManager.updateRentalCarDataFromExcel({
          accessToken: userInfo.accessToken,
          fileList: [
            {
              type: "excel",
              file: file,
            },
          ],
        });

        if (res?.data?.result) {
          toast(`${res?.data?.update}건 업데이트 성공`, { type: "success", closeButton: false });
          setShowMenu(false);
          await fetchData();
        } else {
          alert("업데이트 실패!");
        }
      }
    }
  };

  // 상태 정리
  const stateSymbol = (state) => {
    switch (state) {
      case 0:
        return <div className={`w-3 h-3 cursor-pointer bg-[#FF0000] rounded-full`} title="배차필요" />
      case 1:
        return <div className={`w-3 h-3 cursor-pointer bg-[#FFEA00] rounded-full`} title="배차요청" />
      case 2:
        return <div className={`w-3 h-3 cursor-pointer bg-[#4ED964] rounded-full`} title="배차완료" />
      default:
        return null
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-4 px-[50px] md:px-[5px] mb-10">
      <div className="flex flex-col relative">
        <span className={"absolute cursor-pointer top-10 right-0 text-[25px]"} onClick={() => {
          setShowMenu(true);
        }}>
          <FontAwesomeIcon icon={faEllipsis} style={{ color: "#000" }} />
        </span>
        <div className="text-start mt-[50px] text-[#9299A1]">버스</div>
        <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px]">렌트카</div>
        <div className="flex flex-row items-center gap-3 mt-[10px]">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px]">{`파일 업로드 방법 : 우측상단메뉴 > 데이터등록 (컨펌폴더 엑셀파일 사용)`}</div>
        </div>
        <div className="flex flex-row items-center gap-3 mt-[5px]">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px]">번호 클릭 시 내용 복사</div>
        </div>
        <div className="flex flex-row items-center gap-3 mt-[5px]">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px]">각 항목 클릭하여 값 업데이트 가능</div>
        </div>
        <div className="flex flex-row items-center gap-3 mt-[5px] mb-[10px]">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px]">상태 설정 시 다음 값으로 지정 0 : 배차필요 1: 배차요청 2 : 배차완료</div>
        </div>

        <input
          className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-[15px]"
          placeholder="TBA"
          type="text"
          name="search"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {

            }
          }}
          onChange={(e) => {
            searchText.current = e.target.value;
          }}
          ref={focusRef}
        />
      </div>

      <div className="w-full flex flex-row gap-2 mt-[25px] scrollRemoveBox">
        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCar} style={{ color: "#1D3C77" }} />
            </span>
            <div>배차필요</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(rentalCarList?.filter(rentInfo => rentInfo.state === 0)?.length)}건</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCar} style={{ color: "#1D3C77" }} />
            </span>
            <div>배차요청</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(rentalCarList?.filter(rentInfo => rentInfo.state === 1)?.length)}건</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCar} style={{ color: "#1D3C77" }} />
            </span>
            <div>배차완료</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(rentalCarList?.filter(rentInfo => rentInfo.state === 2)?.length)}건</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCar} style={{ color: "#1D3C77" }} />
            </span>
            <div>총</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(rentalCarList?.length)}건</div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="md:scrollRemoveBox grid grid-cols-[5%_5%_10%_10%_5%_10%_15%_10%_10%_10%_5%_5%] sticky top-0 w-full text-[white] text-center items-center text-[16px] font-bold bg-[black] py-3">
        <div className="md:flex-none">번호</div>
        <div className="md:flex-none">날짜</div>
        <div className="md:flex-none">종류</div>
        <div className="md:flex-none">시간</div>
        <div className="md:flex-none">인원</div>
        <div className="md:flex-none">여행사</div>
        <div className="md:flex-none">호텔</div>
        <div className="md:flex-none">손님정보</div>
        <div className="md:flex-none">비고</div>
        <div className="md:flex-none">가이드</div>
        <div className="md:flex-none">행사번호</div>
        <div className="md:flex-none">상태</div>
      </div>
      <table>
        <tbody>
          {
            rentalCarList.sort((a, b) => {
              if (a.id - b.id < 0)
                return 1;
              else
                return -1;
            }).map((rentInfo, index) => {
              return (
                <tr key={index} className="md:scrollRemoveBox grid grid-cols-[5%_5%_10%_10%_5%_10%_15%_10%_10%_10%_5%_5%] border-[#E2E2E2] border-x-[1px] border-b-[1px] py-0 font-semibold items-center normal-case text-center">
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => {
                    navigator.clipboard.writeText(`${(rentInfo.tourId !== undefined && rentInfo.tourId !== null) ? rentInfo.tourId + " " : ""}${rentInfo.type}\n날짜 : ${rentInfo.date}\n인원 : ${rentInfo.guest} 외 ${rentInfo.pax - 1}명\n호텔 : ${rentInfo.hotel}\n여행사 : ${rentInfo.agency}\n${(rentInfo.time !== undefined && rentInfo.time !== null) ? rentInfo.time : ""}\n${(rentInfo.etc !== undefined && rentInfo.etc !== null) ? rentInfo.etc : ""}`);
                    alert('복사완료');
                  }}>{rentInfo.id}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "date", rentInfo.date) }}>{rentInfo.date}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "type", rentInfo.type) }}>{rentInfo.type}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "time", rentInfo.time) }}>{rentInfo.time}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "pax", rentInfo.pax) }}>{rentInfo.pax}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "agency", rentInfo.agency) }}>{rentInfo.agency}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "hotel", rentInfo.hotel) }}>{rentInfo.hotel}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "guest", rentInfo.guest) }}>{rentInfo.guest}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "etc", rentInfo.etc) }}>{rentInfo.etc}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "guide", rentInfo.guide) }}>{rentInfo.guide}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "tourId", rentInfo.tourId) }}>{rentInfo.tourId}</td>
                  <td className="flex cursor-pointer h-full items-center justify-center py-3" onClick={() => { selectedData(rentInfo.id, "state", rentInfo.state) }}>
                    {
                      stateSymbol(rentInfo.state)
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>

      {
        // 메뉴
        showMenu &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className={`border-b ${liCommonCSSUserMenu}`}>
                  <input
                    className="hidden"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                    onChange={(e) => {
                      handleCapture(e.target);
                    }}
                  />
                  <label htmlFor="icon-button-file">
                    <span>
                      <div className={`cursor-pointer ${btnCommonCSSUserMenu}`}>
                        데이터등록
                      </div>
                    </span>
                  </label>
                </div>
                <div className={`${liCommonCSSUserMenu}`}>
                  <button
                    className={btnCommonCSSUserMenu}
                    onClick={async () => {
                      setShowMenu(false);
                    }}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        showUpdateDialog && <>
          <div className="scrollRemoveBox mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none font-medium">
            <div className="relative w-full my-6 mx-auto max-w-lg">
              <div className="border-0 shadow-lg relative flex flex-col bg-[#efefef] outline-none focus:outline-none px-5  rounded-lg">
                <div className="text-center py-3">{updateSelectId}번</div>
                <input
                  className="placeholder:text-slate-400 block bg-white border border-slate-300 py-4 pl-10 shadow-sm focus:outline-none mb-1"
                  type="text"
                  onChange={(e) => {
                    setUpdateDataValue(e.target.value);
                  }}
                  value={updateDataValue}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <div className="flex flex-row">
                  <span className={"flex w-full cursor-pointer px-2 py-3 justify-center"} onClick={async () => {
                    await updateData(updateDataType, updateDataValue);
                  }}>
                    <div className={"select-none text-[deepskyblue] font-bold"}
                    >업데이트</div>
                  </span>
                  <span className={"flex w-full  cursor-pointer px-2 py-3 justify-center"} onClick={() => {
                    setShowUpdateDialog(false);
                    setUpdateDataType("");
                    setUpdateDataValue("");
                  }}>
                    <div className={"select-none text-[red] font-bold"} >취소</div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
        </>
      }
    </div >
  );
};

export default RentalCarPage;
