import React, { useState, useEffect } from "react";
import * as Utils from '../features/Utils';
import * as HTTPManager from '../features/HTTPManager';
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ColorButton from "./ColorButton"
import { ROLES } from "../features/Constant";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function RegistBusCompany(Props) {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);

  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [manager, setManager] = useState("");

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.MANAGER, ROLES.GOD], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  return (
    <div className="fixed h-full w-full top-0 left-60 md:left-0 md:top-10 pl-4 text-sm bg-[#0007] overflow-auto z-[100]">
      <div className="fixed border-t border-l border-r-2 border-b-2 h-5/6 w-5/6 rounded-md md:w-11/12 bg-white top-24 md:top-20 overflow-auto p-6 md:p-2">
        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
          <div
            style={{
              width: 25,
              height: 25,
              fontSize: 15,
              color: "grey",
              cursor: "pointer",
              textAlign: "center"
            }}
            onClick={() => { Props.setShow(false) }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>기본 정보</div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>이름</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) 글로벌, 모던"
            type="text"
            name="name"
            style={{ flex: 1 }}
            onChange={(e) => { setName(e.target.value) }}
            value={name}
          />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>지역</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) 서울, 부산"
            type="text"
            name="area"
            style={{ flex: 1 }}
            onChange={(e) => { setArea(e.target.value) }}
            value={area}
          />
        </div>

        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>담당자</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) 홍길동"
            type="text"
            name="manager"
            style={{ flex: 1 }}
            onChange={(e) => { setManager(e.target.value) }}
            value={manager}
          />
        </div>

        {/* <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <span style={{ marginRight: 10 }}>구간 요금 정보</span>
          <FontAwesomeIcon icon={faSquarePlus} size="xl" color="grey" onClick={() => {
          }} />
        </div> */}
        <span style={{ width: "100%" }}>
          <ColorButton title="등록" color="slateblue" handler={async () => {
            var res = await HTTPManager.RegistBusInfo({
              accessToken: userInfo.accessToken,
              info: {
                name: name,
                area: area,
                manager: manager,
              },
            })

            if (res.data.result) {
              alert("등록 완료");
              window.location.reload(true);
            } else {
              alert("등록 실패");
            }
          }} />
        </span>
      </div >
    </div >
  );
}