import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { UserInfo, ScheduleListScroll } from "../recoil";
import { useRecoilValue, useRecoilState } from "recoil";

import { ROLES } from "../features/Constant"
import * as HTTPManager from "../features/HTTPManager";
import * as Utils from "../features/Utils";
import TourInfoItemV2 from "./scheduleList/TourInfoItemV2";
import KakaoShareButton from "./KakaoShareButton";
import useScript from "../hooks/useScript";
import CreateTour from "./CreateTour";
import "./ScheduleListStyle.css";

import ICON_PH from "../assets/images/ph.png"
import ICON_ID from "../assets/images/id.png"

export default function ScheduleList() {
  useScript("https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js", "sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx");

  const navigate = useNavigate();
  const scrollPosition = window.pageYOffset;
  const [currentScroll, setCurrentScroll] = useRecoilState(ScheduleListScroll);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const TourComponent = TourInfoItemV2;

  const [tourList, setTourList] = useState([]);
  const [tourCompleteFilterList, setCompleteTourFilterList] = useState([]);
  const [tourDoingFilterList, setDoingTourFilterList] = useState([]);
  const [tourCancelFilterList, setCancelTourFilterList] = useState([]);
  const [tourRemainFilterList, setRemainTourFilterList] = useState([]);

  const [toursBusInfo, setToursBusInfo] = useState([]);
  const [toursHotelInfo, setToursHotelInfo] = useState([]);
  const [toursHotelLogInfo, setToursHotelLogInfo] = useState([]);
  const [toursGuideInfo, setToursGuideInfo] = useState([]);
  const [toursCheckListInfo, setToursCheckListInfo] = useState([]);
  const [toursFunds, setToursFunds] = useState([]);
  const [toursFiles, setToursFiles] = useState([]);

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);

  const [hotelList, setHotelList] = useState([]);
  const [busList, setBusList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [guideMemoList, setGuideMemoList] = useState([]);

  const userInfo = useRecoilValue(UserInfo);

  // 메뉴
  const [showMenu, setShowMenu] = useState(false);
  const menuNameList = ["투어생성", "투어정보", "투어정보V2", "호텔예약", "버스예약", "가이드예약"];


  // 필터 관련 변수
  const filterNameList = ["번호 오름차순", "번호 내림차순", "시작일 빠른순", "시작일 느린순"];
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(2);
  const [showFilter, setShowFilter] = useState(false);

  const filterYearList = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, "닫기"];
  const [showYearList, setShowYearList] = useState(false);
  const filterMonthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, "닫기"];
  const [showMonthList, setShowMonthList] = useState(false);

  // 전체 검색
  const [allSearch, setAllSearch] = useState(false);

  const [showCreateTour, setShowCreateTour] = useState(false);
  let searchText = useRef("");
  let focusRef = useRef();

  // 오늘 달 >= 선택된 달 : 진행 & 예정 open
  // 오늘 달 < 선택된 달 : 완료 & 취소 open
  const [isComplete, setIsComplete] = useState(true);

  // 인원수
  const [totalIdPax, setTotalIdPax] = useState(0);
  const [totalPhPax, setTotalPhPax] = useState(0);

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";

  // 월별 메모
  const [showMemoMonth, setShowMemoMonth] = useState(false);
  const [memoMonthList, setMemoMonthList] = useState([]);
  let memoMonthText = useRef("");

  // 필터 기능 개선작업
  const CHECK_LIST = ["인도네시아", "필리핀", "호텔미정", "버스미정", "가이드미정", "면세점확인", "입출국미정", "루밍변경"];
  const [selectedCheckList, setSelectedCheckList] = useState(["인도네시아", "필리핀"]);

  // 데이터 초기화
  async function initalize() {
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth() + 1);
  }

  // 서버 데이터 수신
  async function fetchData() {
    var response = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let initTourList = [];
    if (response !== undefined) {
      setTourList(response.data.result);

      let initYear = selectedYear === 0 ? today.getFullYear() : selectedYear;
      let initMonth = selectedMonth === 0 ? today.getMonth() : selectedMonth - 1;

      initTourList = response.data.result?.filter(tour => (new Date(tour.startDate.replace(" ", "T")).getFullYear() === initYear &&
        new Date(tour.startDate.replace(" ", "T")).getMonth() === initMonth));

      // 국가 선택 버튼 확인
      if (selectedCheckList.find(item => item === "필리핀") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (selectedCheckList.find(item => item === "인도네시아") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      filteringTour(initTourList, selectedFilterIndex, selectedCheckList.find(item => item === "면세점확인") !== undefined);
    }

    response = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setToursBusInfo(response.data.toursBus);
      setToursHotelInfo(response.data.toursHotel);
      setToursHotelLogInfo(response.data.toursHotelLog);
      setToursGuideInfo(response.data.toursGuide);
      setToursCheckListInfo(response.data.toursCheckList);
      setToursFunds(response.data.toursFunds);
      setToursFiles(response.data.toursFiles);
    }

    response = await HTTPManager.GetHotelList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setHotelList(response.data.hotelList);
    }

    response = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setBusList(response.data.busList);
    }

    response = await HTTPManager.GetGuideMemoForAdmin({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setGuideMemoList(response.data.guideMemoList);
    }

    response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data?.result !== undefined) {
      var sortList = [...response.data.result];
      sortList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      setUserList(sortList);
    }

    // refresh 시 검색창 입력값 확인
    if (searchText.current.length > 0) {
      let searchList = [];

      // 직접 접근 : pax name(국가) leader itinerary cancel eta etd dutyFreeShop memo
      // 참조 접근(예약정보) : guide, hotel, bus
      let guideId = userList.find(user => user.name === searchText.current)?.id;
      let hotelId = hotelList.find(hotel => hotel.name === searchText.current)?.id;
      searchList = initTourList.filter(tour => String(tour.id + "번")?.includes(searchText.current)
        || tour.pax?.toUpperCase().includes(searchText.current.toUpperCase())
        || tour.itinerary?.toUpperCase().includes(searchText.current.toUpperCase())
        || tour.eta?.toUpperCase().includes(searchText.current.toUpperCase())
        || tour.etd?.toUpperCase().includes(searchText.current.toUpperCase())
        || tour.dutyFreeShop?.toUpperCase().includes(searchText.current.toUpperCase())
        || tour.memo?.toUpperCase().includes(searchText.current.toUpperCase()) || tour.name?.includes(searchText.current)
        || (guideId !== undefined && toursGuideInfo?.find(tourGuide => tourGuide.guideId === guideId && tourGuide.tourId === tour.id) !== undefined)
        || (toursHotelInfo?.find(tourHotel => tourHotel.hotelId === hotelId && tourHotel.tourId === tour.id) !== undefined)
        || tour.docNum?.toUpperCase().includes(searchText.current.toUpperCase())
      );

      filteringTour(searchList, selectedFilterIndex, selectedCheckList.find(item => item === "면세점확인") !== undefined);
    }

    response = await HTTPManager.GetMemoMonth({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setMemoMonthList(response.data.result);
    }
  }

  const [allowShow, setAllowShow] = useState(false);

  // useEffect (async () => {}, []) 형태 사용하지 말 것
  // 내부에서 하는 경우 async 함수를 정의해서 호출
  useEffect(() => {
    Utils.allowByPositionEx([ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await initalize();
          await fetchData();
          setAllowShow(true);
        }
      })
      .catch((err) => {
        alert(err.message);
      });

  }, []);

  function checkCompleteTour(endDate) {
    if (today > new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function checkDoingTour(startDate, endDate) {
    if (today >= new Date(startDate.replace(" ", "T")) && today <= new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function checkRemainTour(startDate) {
    if (today < new Date(startDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function sortList(list, direction) {
    // direction : 1, 빠른순
    // direction : -1, 느린순
    return list.sort((a, b) => {
      if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
        return direction;
      if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
        return -direction;

      if (new Date(a.endDate.replace(" ", "T")) > new Date(b.endDate.replace(" ", "T")))
        return direction;
      if (new Date(a.endDate.replace(" ", "T")) < new Date(b.endDate.replace(" ", "T")))
        return -direction;
    });
  }

  function changeYearOrMonth(year, month) {
    // 선택된 연도 or 달만 표시
    let selectList = tourList.filter(tour => (new Date(tour.startDate.replace(" ", "T")).getFullYear() === year &&
      new Date(tour.startDate.replace(" ", "T")).getMonth() === (month - 1)));

    // 필터 초기화
    filteringTour(selectList, 2);
    setSelectedCheckList(["인도네시아", "필리핀"]);
  }

  function filteringTour(list, index, checkDutyFree) {
    if (list === undefined) return;

    let paxIn = 0;
    let paxPh = 0;

    list.map(tour => {
      if (tour.cancel === 1) return 0;

      // [, ( 기준 제거 숫자만 추출하기 위한 과정
      let firstItem = tour.pax?.split('[')[0];
      let secondItem = firstItem?.split('(')[0];
      let sum = secondItem?.split(/[\D]+/i)?.reduce((a, b) => a = a + Number(b), 0);

      if (sum !== undefined && tour.countryId === 2)
        paxIn += sum;

      if (sum !== undefined && tour.countryId === 3)
        paxPh += sum;
    });

    setTotalIdPax(paxIn);
    setTotalPhPax(paxPh);

    let completeList = checkDutyFree ? list.filter(item => checkCompleteTour(item.endDate) && item.cancel === 0 && (item.dutyFreeShop === undefined || item.dutyFreeShop === null)) : list.filter(item => checkCompleteTour(item.endDate) && item.cancel === 0);
    let doingList = checkDutyFree ? list.filter(item => (checkDoingTour(item.startDate, item.endDate) && item.cancel === 0) && (item.dutyFreeShop === undefined || item.dutyFreeShop === null)) : list.filter(item => (checkDoingTour(item.startDate, item.endDate) && item.cancel === 0));
    let cancelList = checkDutyFree ? [] : list.filter(item => item.cancel === 1);
    let remainList = checkDutyFree ? list.filter(item => (checkRemainTour(item.startDate) && item.cancel === 0) && (item.dutyFreeShop === undefined || item.dutyFreeShop === null)) : list.filter(item => (checkRemainTour(item.startDate) && item.cancel === 0));

    setSelectedFilterIndex(index);
    setShowFilter(false);
    // "번호 오름차순", "번호 내림차순", "시작일 빠른순", "시작일 느린순"
    switch (index) {
      case 0:
        setCompleteTourFilterList([].concat(completeList.sort((a, b) => a.id - b.id)));
        setDoingTourFilterList([].concat(doingList.sort((a, b) => a.id - b.id)));
        setCancelTourFilterList([].concat(cancelList.sort((a, b) => a.id - b.id)));
        setRemainTourFilterList([].concat(remainList.sort((a, b) => a.id - b.id)));
        break;
      case 1:
        setCompleteTourFilterList([].concat(completeList.sort((a, b) => b.id - a.id)));
        setDoingTourFilterList([].concat(doingList.sort((a, b) => b.id - a.id)));
        setCancelTourFilterList([].concat(cancelList.sort((a, b) => b.id - a.id)));
        setRemainTourFilterList([].concat(remainList.sort((a, b) => b.id - a.id)));
        break;
      case 2:
        setCompleteTourFilterList([].concat(sortList(completeList, 1)));
        setDoingTourFilterList([].concat(sortList(doingList, 1)));
        setCancelTourFilterList([].concat(sortList(cancelList, 1)));
        setRemainTourFilterList([].concat(sortList(remainList, 1)));
        break;
      case 3:
        setCompleteTourFilterList([].concat(sortList(completeList, -1)));
        setDoingTourFilterList([].concat(sortList(doingList, -1)));
        setCancelTourFilterList([].concat(sortList(cancelList, -1)));
        setRemainTourFilterList([].concat(sortList(remainList, -1)));
        break;
      default:
        break;
    }
  }

  const btnMoveMonthClass = "text-3xl pointerhover:hover:cursor-pointer px-4 py-1 pointerhover:hover:bg-gray-200 rounded-full focus:outline-none";

  function moveToRight() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month + 1 > 12) {
      setSelectedYear(selectedYear + 1);
      month = 0;
      year = year + 1;
    }

    setSelectedMonth(month + 1);
    changeYearOrMonth(year, month + 1);

    setIsComplete(today.getMonth() < (month + 1) && today.getFullYear() <= year);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  function moveToLeft() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month - 1 < 1) {
      setSelectedYear(selectedYear - 1);
      month = 13;
      year = year - 1;
    }

    setSelectedMonth(month - 1);
    changeYearOrMonth(year, month - 1);

    setIsComplete(today.getMonth() < (month - 1) && today.getFullYear() <= year);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  function getCheckBoxStr(type) {
    let result = "";

    switch (type) {
      case "호텔미정":
        result = type + "(" + tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            (toursHotelInfo.find(reserveInfo => reserveInfo.tourId === item.id) === undefined)
            &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        ).length + ")";
        break;
      case "버스미정":
        result = type + "(" + tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            (toursBusInfo.find(reserveInfo => reserveInfo.tourId === item.id) === undefined)
            &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        ).length + ")";
        break;
      case "가이드미정":
        result = type + "(" + tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            (toursGuideInfo.find(reserveInfo => reserveInfo.tourId === item.id) === undefined)
            &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        ).length + ")";
        break;
      case "면세점확인":
        result = type + "(" + tourList.filter(
          (item) =>
            (item.cancel !== 1) &&
            (item.dutyFreeShop === undefined || item.dutyFreeShop === null) &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        )?.length + ")";
        break;
      case "입출국미정":
        result = type + "(" + tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            ((item.eta === undefined || item.eta === null) ||
              (item.etd === undefined || item.etd === null)) &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        ).length + ")";
        break;
      case "루밍변경":
        result = type + "(" + tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            (item.roomingModifyAt !== undefined && item.roomingModifyAt !== null) &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        )?.length + ")";
        break;
      default:
        result = type;
        break;
    }

    return result;
  }

  // 각 항목 업데이트
  function updateChecked(type, checked) {
    let tmpList = [];
    for (let i = 0; i < selectedCheckList.length; i++) {
      tmpList.push(selectedCheckList[i]);
    }

    if (checked) {
      tmpList.push(type);
    } else {
      tmpList = selectedCheckList.filter(item => item !== type);
    }

    if (type === "필리핀") {
      let initTourList = []

      if (selectedCheckList.find(checked => checked === type) !== undefined) {
        initTourList = tourDoingFilterList?.filter((item) => item.name !== "필리핀");
        initTourList.push(...tourCancelFilterList?.filter((item) => item.name !== "필리핀"));
        initTourList.push(...tourCompleteFilterList?.filter((item) => item.name !== "필리핀"));
        initTourList.push(...tourRemainFilterList?.filter((item) => item.name !== "필리핀"));
      }
      else {
        if (selectedCheckList.find(checked => checked === "인도네시아") !== undefined) {
          initTourList = tourList?.filter(
            (item) =>
              new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
              new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
          );
        } else {
          initTourList = tourList?.filter(
            (item) =>
              item.name === "필리핀" &&
              new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
              new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
          );
        }
      }

      filteringTour(initTourList, selectedFilterIndex);
    } else if (type === "인도네시아") {
      let initTourList = [];
      // on > off
      if (selectedCheckList.find(checked => checked === type) !== undefined) {
        initTourList = tourDoingFilterList?.filter((item) => item.name !== "인도네시아");
        initTourList.push(...tourCancelFilterList?.filter((item) => item.name !== "인도네시아"));
        initTourList.push(...tourCompleteFilterList?.filter((item) => item.name !== "인도네시아"));
        initTourList.push(...tourRemainFilterList?.filter((item) => item.name !== "인도네시아"));
      }
      // off > on  
      else {
        if (selectedCheckList.find(checked => checked === "필리핀") !== undefined) {
          initTourList = tourList?.filter(
            (item) =>
              new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
              new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
          );
        } else {
          initTourList = tourList?.filter(
            (item) =>
              item.name === "인도네시아" &&
              new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
              new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
          );
        }
      }

      filteringTour(initTourList, selectedFilterIndex);
    } else if (type === "호텔미정") {
      let initTourList = [];

      if (selectedCheckList.find(checked => checked === type) === undefined) {
        tmpList = tmpList.filter(item => item !== "버스미정" && item !== "면세점확인" && item !== "가이드미정" && item !== "입출국미정" && item !== "루밍변경");

        initTourList = tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            (toursHotelInfo.find(hotelInfo => hotelInfo.tourId === item.id) === undefined) &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      } else {
        initTourList = tourList?.filter(
          (item) =>
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      }

      // 필리핀, 인도네시아 필터 고려
      if (selectedCheckList.find(checked => checked === "필리핀") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (selectedCheckList.find(checked => checked === "인도네시아") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      filteringTour(initTourList, selectedFilterIndex);
    } else if (type === "버스미정") {
      let initTourList = [];

      if (selectedCheckList.find(checked => checked === type) === undefined) {
        tmpList = tmpList.filter(item => item !== "면세점확인" && item !== "호텔미정" && item !== "가이드미정" && item !== "입출국미정" && item !== "루밍변경");

        initTourList = tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            (toursBusInfo.find(busInfo => busInfo.tourId === item.id) === undefined) &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      } else {
        initTourList = tourList?.filter(
          (item) =>
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      }

      // 필리핀, 인도네시아 필터 고려
      if (selectedCheckList.find(checked => checked === "필리핀") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (selectedCheckList.find(checked => checked === "인도네시아") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      filteringTour(initTourList, selectedFilterIndex);
    } else if (type === "가이드미정") {
      let initTourList = [];

      if (selectedCheckList.find(checked => checked === type) === undefined) {
        tmpList = tmpList.filter(item => item !== "버스미정" && item !== "호텔미정" && item !== "면세점확인" && item !== "입출국미정" && item !== "루밍변경");

        initTourList = tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            (toursGuideInfo.find(guideInfo => guideInfo.tourId === item.id) === undefined) &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      } else {
        initTourList = tourList?.filter(
          (item) =>
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      }

      // 필리핀, 인도네시아 필터 고려
      if (selectedCheckList.find(checked => checked === "필리핀") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (selectedCheckList.find(checked => checked === "인도네시아") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      filteringTour(initTourList, selectedFilterIndex);
    } else if (type === "면세점확인") {
      let initTourList = [];

      if (selectedCheckList.find(item => item === type) === undefined) {
        tmpList = tmpList.filter(item => item !== "버스미정" && item !== "호텔미정" && item !== "가이드미정" && item !== "입출국미정" && item !== "루밍변경");

        initTourList = tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            (item.dutyFreeShop === undefined || item.dutyFreeShop === null) &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      } else {
        initTourList = tourList?.filter(
          (item) =>
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      }

      // 필리핀, 인도네시아 필터 고려
      if (selectedCheckList.find(item => item === "필리핀") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (selectedCheckList.find(item => item === "인도네시아") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      filteringTour(initTourList, selectedFilterIndex, !(selectedCheckList.find(item => item === "면세점확인") !== undefined));
    } else if (type === "입출국미정") {
      let initTourList = [];

      if (selectedCheckList.find(item => item === type) === undefined) {
        tmpList = tmpList.filter(item => item !== "버스미정" && item !== "호텔미정" && item !== "가이드미정" && item !== "면세점확인" && item !== "루밍변경");

        initTourList = tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            ((item.eta === undefined || item.eta === null) ||
              (item.etd === undefined || item.etd === null)) &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      } else {
        initTourList = tourList?.filter(
          (item) =>
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      }

      // 필리핀, 인도네시아 필터 고려
      if (selectedCheckList.find(item => item === "필리핀") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (selectedCheckList.find(item => item === "인도네시아") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      filteringTour(initTourList, selectedFilterIndex);
    } else if (type === "루밍변경") {
      let initTourList = [];
      if (selectedCheckList.find(checked => checked === type) === undefined) {
        tmpList = tmpList.filter(item => item !== "버스미정" && item !== "호텔미정" && item !== "가이드미정" && item !== "입출국미정" && item !== "면세점확인");

        initTourList = tourList?.filter(
          (item) =>
            (item.cancel !== 1) &&
            (item.roomingModifyAt !== undefined && item.roomingModifyAt !== null) &&
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      }
      else {
        initTourList = tourList?.filter(
          (item) =>
            new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
            new Date(item.startDate.replace(" ", "T")).getMonth() === (selectedMonth - 1)
        );
      }

      // 필리핀, 인도네시아 필터 고려
      if (selectedCheckList.find(checked => checked === "필리핀") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (selectedCheckList.find(checked => checked === "인도네시아") === undefined) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      filteringTour(initTourList, selectedFilterIndex);
    }

    setSelectedCheckList(tmpList);
  }

  function getBusCount(countryId) {
    let busList = [];
    let count = 0;

    // 대형 (x) 정규식 
    // 괄호 시작전 띄어쓰기 개수 상관 X
    // 괄호 시작 후 숫자로만 이루어져있는지
    // 괄호 끝
    // 숫자만 추출 필요

    busList = busList.concat(toursBusInfo.filter(dataInfo => tourCompleteFilterList.find(tour => tour.id === dataInfo.tourId && tour.countryId === countryId) && dataInfo.busType.includes("대형")));
    busList = busList.concat(toursBusInfo.filter(dataInfo => tourDoingFilterList.find(tour => tour.id === dataInfo.tourId && tour.countryId === countryId) && dataInfo.busType.includes("대형")));
    busList = busList.concat(toursBusInfo.filter(dataInfo => tourRemainFilterList.find(tour => tour.id === dataInfo.tourId && tour.countryId === countryId) && dataInfo.busType.includes("대형")));

    for (let i = 0; i < busList.length; i++) {
      let busInfo = busList[i];
      let busType = busInfo.busType;
      let result = busType.match(/대형\s*\(?\d*\)?/g);

      if (result.length > 0) {
        const rawText = result[0];
        const reg = new RegExp(/[^0-9]/, "g");
        count += Number(rawText.replace(reg, ""));
      }
    }

    return count;
  }

  return (
    <>
      <div
        className="mt-2 md:mt-14 overflow-y-auto outline-none"
        style={{
          width: "100%",
          backgroundColor: "#fff",
          textAlign: "center",
          justifyContent: 'center',
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setShowYearList(false);
            setShowMonthList(false);
            e.preventDefault();
          }
        }}
        tabIndex={-1}
      >
        {
          allowShow &&
          <div style={{ width: '100%', height: "100%", alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
            <div style={{
              marginBottom: 5, display: "flex", width: "100%", justifyContent: "center", padding: 10,
            }}
            >
              {
                <div className="fixed right-1 bottom-16 z-[100]">
                  <span
                    className={"relative cursor-pointer px-[10px] py-[6px] rounded-full mr-2 z-[99] bg-[#3C1E1E] hover:bg-[#3C1E1Eaa]"}
                    onClick={() => {
                      setShowMemoMonth(!showMemoMonth);
                    }}>
                    <FontAwesomeIcon icon={faMessage} style={{ fontSize: "13px", color: "white", }} />
                    {
                      memoMonthList.filter(item => {
                        let date = new Date(item.date);
                        let year = date.getFullYear();
                        let month = date.getMonth() + 1;
                        return year === selectedYear && month === selectedMonth && item.state !== 1;
                      })?.length > 0 && <div className="absolute w-[8px] h-[8px] top-0 right-0 bg-[red] rounded-full" />
                    }
                  </span>
                </div>
              }
              {
                showMemoMonth &&
                <div className="fixed flex-col md:right-0 right-5 md:top-0 top-25 pt-5 px-3 pb-20 md:rounded-none rounded-lg bg-[#E2F1FF] md:w-full w-[400px] md:h-full h-[600px] text-[14px] z-[105]">
                  <div className="font-bold mb-2">{selectedMonth}월 메모</div>
                  <span className="absolute right-2 top-0 z-[100] select-none" style={{ fontSize: 20, cursor: "pointer" }} onClick={() => {
                    setShowMemoMonth(false);
                  }}>
                    <FontAwesomeIcon icon={faXmark} style={{ color: "#3C1E1E" }} />
                  </span>
                  <div className="h-full w-full overflow-auto gap-2 flex flex-col">
                    {
                      memoMonthList.filter(item => {
                        let date = new Date(item.date);
                        let year = date.getFullYear();
                        let month = date.getMonth() + 1;
                        return year === selectedYear && month === selectedMonth && item.state !== 1;
                      }).map(memoMonthItem => {
                        return (
                          <div key={memoMonthItem.id} className="flex flex-col items-center w-full bg-[#fcfcfc] rounded-lg p-1">
                            <div className="flex flex-row normal-case justify-start w-full">
                              <div className="flex w-full flex-wrap text-start">{memoMonthItem.contents}</div>
                            </div>
                            <div className="flex flex-row items-center justify-end w-full">
                              <div className="mr-1">[{userList.find(user => user.id === memoMonthItem.userId)?.name}]</div>
                              <div>{memoMonthItem.createdAt.slice(5, 16)}</div>
                              <span className="ml-2 cursor-pointer font-[15px]" style={{
                                display: userInfo.id !== memoMonthItem.userId ? "none" : ""
                              }} onClick={async () => {
                                var answer = window.confirm(`Delete "${memoMonthItem.contents}"?`);
                                if (answer) {
                                  let result = await HTTPManager.UpdateMemoMonth({
                                    accessToken: userInfo.accessToken,
                                    param: {
                                      id: memoMonthItem.id,
                                    }
                                  });

                                  if (result?.data?.result)
                                    fetchData();
                                }
                              }}>
                                <FontAwesomeIcon icon={faTrash} style={{ color: "#3C1E1E" }} />
                              </span>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="absolute flex flex-col left-0 bottom-0 p-1 bg-[E2F1FF] rounded-b-lg w-full justify-center cursor-pointer font-bold">
                    <input
                      id={`memoMonth`}
                      className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none text-[15px] text-black"
                      placeholder="입력 후 Enter"
                      type="text"
                      name="search"
                      onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                          let removeBlank = memoMonthText.current.replaceAll(' ', '');
                          if (memoMonthText.current.length > 0 && removeBlank.length > 0) {
                            let result = await HTTPManager.InsertMemoMonth({
                              accessToken: userInfo.accessToken,
                              param: {
                                contents: memoMonthText.current,
                                year: selectedYear,
                                month: selectedMonth,
                              }
                            });

                            if (result?.data?.result) {
                              fetchData();
                              let inputBox = document.getElementById(`memoMonth`);
                              inputBox.value = '';
                            }
                          }
                        }
                      }}
                      onChange={(e) => {
                        memoMonthText.current = e.target.value;
                      }}
                      ref={memoMonthText}
                    />
                  </div>
                </div>
              }
              <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
                moveToLeft();
              }} >
                <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#777" }} />
              </span>
              <div
                id="title"
                className="flex flex-row justify-center items-center text-[25px] select-none cursor-pointer">
                <div className="mr-[5px]" onClick={() => {
                  setShowYearList(true);
                }}>{selectedYear}년</div>
                <div onClick={() => {
                  setShowMonthList(true);
                }}>{selectedMonth}월</div>
              </div>
              <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
                moveToRight();
              }}>
                <FontAwesomeIcon icon={faCaretRight} style={{ color: "#777" }} />
              </span>
            </div>

            <div className="scrollRemoveBox flex flex-row mt-2 md:w-full overflow-auto select-none">
              <span
                title="투어 생성"
                className="flex-none items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 ml-2 cursor-pointer hover:bg-green-300"
                onClick={() => {
                  setShowCreateTour(!showCreateTour);
                }}>
                투어생성
              </span>

              <span
                title="투어정보확인"
                className="ml-2 flex-none items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 cursor-pointer hover:bg-red-300"
                onClick={() => {
                  let tourList = [];
                  tourList.push(...tourDoingFilterList);
                  tourList.push(...tourCompleteFilterList);
                  tourList.push(...tourRemainFilterList);
                  navigate("/reservationTourList", { state: { reservationTour: tourList, toursGuideInfo: toursGuideInfo, } });
                }}>
                투어정보
              </span>

              <span
                title="호텔예약확인"
                className="ml-2 flex-none items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 cursor-pointer hover:bg-red-300"
                onClick={() => {
                  let tourList = [];
                  tourList.push(...tourCompleteFilterList);
                  tourList.push(...tourDoingFilterList);
                  tourList.push(...tourRemainFilterList);
                  navigate("/reservationHotelList", { state: { reservationHotel: toursHotelInfo.filter(item => tourList.find(tour => tour.id === item.tourId)), toursGuideInfo: toursGuideInfo } });
                }}>
                호텔예약
              </span>

              <span
                title="버스예약확인"
                className="ml-2 flex-none items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 cursor-pointer hover:bg-red-300"
                onClick={() => {
                  let tourList = [];
                  tourList.push(...tourCancelFilterList);
                  tourList.push(...tourCompleteFilterList);
                  tourList.push(...tourDoingFilterList);
                  tourList.push(...tourRemainFilterList);
                  navigate("/reservationBusList", {
                    state: {
                      reservationBus: toursBusInfo.filter(item => tourList.find(tour => tour.id === item.tourId)),
                      reservationHotel: toursHotelInfo.filter(item => tourList.find(tour => tour.id === item.tourId)),
                      tourList: tourList,
                      toursGuideInfo: toursGuideInfo,
                      userList: userList,
                      hotelList: hotelList,
                    }
                  });
                }}>
                버스예약
              </span>

              <span
                title="가이드예약확인"
                className="ml-2 flex-none items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 cursor-pointer hover:bg-red-300"
                onClick={() => {
                  let tourList = [];
                  tourList.push(...tourCompleteFilterList);
                  tourList.push(...tourDoingFilterList);
                  tourList.push(...tourRemainFilterList);
                  navigate("/reservationGuideList", {
                    state: {
                      reservationGuide: toursGuideInfo.filter(item => tourList.find(tour => tour.id === item.tourId)),
                      tourList: tourList,
                      userList: userList,
                    }
                  });
                }}>
                가이드예약
              </span>

              <span
                title="투어정보확인(종주)"
                className="ml-2 flex-none items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 cursor-pointer hover:bg-red-300"
                onClick={() => {
                  let tourList = [];
                  tourList.push(...tourCompleteFilterList);
                  tourList.push(...tourDoingFilterList);
                  tourList.push(...tourRemainFilterList);
                  navigate("/reservationTourListEx", {
                    state: {
                      reservationTour: tourList,
                      toursGuideInfo: toursGuideInfo,
                      hotelList: hotelList,
                      reservationHotel: toursHotelInfo.filter(item => tourList.find(tour => tour.id === item.tourId)),
                      reservationBus: toursBusInfo.filter(item => tourList.find(tour => tour.id === item.tourId)),
                    }
                  });
                }}>
                투어정보(종주)
              </span>
            </div>

            <div className="w-full flex flex-row gap-2 mt-[10px] scrollRemoveBox px-3">
              <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5">
                <div className="flex flex-row gap-3 font-bold">
                  <span className={"bg-[lightgray] p-[1px] rounded-full w-[25px] h-[25px]"}>
                    <img src={ICON_ID} className="rounded-full object-cover h-full w-full" />
                  </span>
                  <div>인도네시아</div>
                </div>
                <div className="flex w-full justify-between">
                  <div>대형버스</div>
                  <div>{getBusCount(2)}건</div>
                </div>
                <div className="flex w-full justify-between">
                  <div>취소</div>
                  <div>{tourCancelFilterList.filter(tour => tour.countryId === 2)?.length}팀</div>
                </div>
                <div className="flex w-full justify-between">
                  <div>인원(취소팀 제외)</div>
                  <div>{Utils.formatNumber(totalIdPax)}명</div>
                </div>
                <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(tourDoingFilterList?.filter(tour => tour.countryId === 2)?.length + tourCompleteFilterList?.filter(tour => tour.countryId === 2)?.length + tourRemainFilterList?.filter(tour => tour.countryId === 2)?.length)}팀</div>
              </div>

              <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5">
                <div className="flex flex-row gap-3 font-bold">
                  <span className={"bg-[lightgray] p-[1px] rounded-full w-[25px] h-[25px]"}>
                    <img src={ICON_PH} className="rounded-full object-cover h-full w-full" />
                  </span>
                  <div>필리핀</div>
                </div>
                <div className="flex w-full justify-between">
                  <div>대형버스</div>
                  <div>{getBusCount(3)}건</div>
                </div>
                <div className="flex w-full justify-between">
                  <div>취소</div>
                  <div>{tourCancelFilterList.filter(tour => tour.countryId === 3)?.length}팀</div>
                </div>
                <div className="flex w-full justify-between">
                  <div>인원(취소팀 제외)</div>
                  <div>{Utils.formatNumber(totalPhPax)}명</div>
                </div>
                <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(tourDoingFilterList?.filter(tour => tour.countryId === 3)?.length + tourCompleteFilterList?.filter(tour => tour.countryId === 3)?.length + tourRemainFilterList?.filter(tour => tour.countryId === 3)?.length)}팀</div>
              </div>

              <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5">
                <div className="flex flex-row gap-3 font-bold">
                  <span className={""}>
                    <FontAwesomeIcon icon={faChartSimple} style={{ color: "#1D3C77" }} />
                  </span>
                  <div>종합</div>
                </div>
                <div className="flex w-full justify-between">
                  <div>대형/전체</div>
                  <div>{getBusCount(2) + getBusCount(3)}/{
                    toursBusInfo.filter(dataInfo => tourCompleteFilterList.find(tour => tour.id === dataInfo.tourId)).length + toursBusInfo.filter(dataInfo => tourDoingFilterList.find(tour => tour.id === dataInfo.tourId)).length + toursBusInfo.filter(dataInfo => tourRemainFilterList.find(tour => tour.id === dataInfo.tourId)).length
                  }건</div>
                </div>

                <div className="flex w-full justify-between">
                  <div>취소</div>
                  <div>{tourCancelFilterList?.length}팀</div>
                </div>
                <div className="flex w-full justify-between">
                  <div>인원(취소팀 제외)</div>
                  <div>{Utils.formatNumber(totalIdPax + totalPhPax)}명</div>
                </div>
                <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(tourDoingFilterList?.length + tourCompleteFilterList?.length + tourRemainFilterList?.length)}팀</div>
              </div>
            </div>

            <div className="w-full items-center" style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingRight: 10, paddingLeft: 5, }}>
              <input
                className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-[15px]"
                placeholder="검색"
                type="text"
                name="search"
                style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    let searchList = [];

                    // 날짜 분류
                    let initYear = selectedYear === 0 ? today.getFullYear() : selectedYear;
                    let initMonth = selectedMonth === 0 ? today.getMonth() : selectedMonth - 1;

                    let initTourList = !allSearch ? tourList.filter(tour => (new Date(tour.startDate.replace(" ", "T")).getFullYear() === initYear &&
                      new Date(tour.startDate.replace(" ", "T")).getMonth() === initMonth)) : tourList;

                    // 직접 접근 : pax name(국가) leader itinerary cancel eta etd dutyFreeShop memo
                    // 참조 접근(예약정보) : guide, hotel, bus
                    let guideList = userList.filter(user => user.name.includes(searchText.current)).map(item => {
                      return item.id;
                    });

                    let hotelId = hotelList.find(hotel => hotel.name === searchText.current)?.id;

                    searchList = initTourList.filter(tour => String(tour.id + "번")?.includes(searchText.current)
                      || tour.pax?.toUpperCase().includes(searchText.current.toUpperCase())
                      || tour.itinerary?.toUpperCase().includes(searchText.current.toUpperCase())
                      || tour.eta?.toUpperCase().includes(searchText.current.toUpperCase())
                      || tour.etd?.toUpperCase().includes(searchText.current.toUpperCase())
                      || tour.dutyFreeShop?.toUpperCase().includes(searchText.current.toUpperCase())
                      || tour.memo?.toUpperCase().includes(searchText.current.toUpperCase()) || tour.name?.includes(searchText.current)
                      || (toursGuideInfo?.find(tourGuide => guideList?.find(id => id === tourGuide.guideId) !== undefined && tourGuide.tourId === tour.id) !== undefined)
                      || (toursHotelInfo?.find(tourHotel => tourHotel.hotelId === hotelId && tourHotel.tourId === tour.id) !== undefined)
                      || tour.docNum?.toUpperCase().includes(searchText.current.toUpperCase())
                    );

                    filteringTour(searchList, selectedFilterIndex);
                  }
                }}
                onChange={(e) => {
                  searchText.current = e.target.value;
                }}
                ref={focusRef}
              />
              <label className="flex select-none text-[14px] flex-row gap-1 rounded-md px-2 items-center justify-center allSearch">
                <input id={`checkBoxAllSearch`} className="cursor-pointer" type="checkbox" onChange={() => {
                  setAllSearch(!allSearch);
                }} checked={allSearch} />
                <div>전체검색</div>
              </label>
            </div>

            <div className="scrollRemoveBox flex flex-row mt-2 md:w-full overflow-auto select-none">
              <div className="flex flex-row gap-2 px-3 items-center">
                {
                  CHECK_LIST.map((item, index) => {
                    return (
                      <label key={index} className="cursor-pointer md:flex-none flex items-center select-none text-[14px] flex-row gap-1 rounded-sm border-[1px] border-black px-2 py-1">
                        <div>{getCheckBoxStr(item)}</div>
                        <input id={`checkBox${index}`} type="checkbox" onChange={() => {
                          var checkbox = document.getElementById(`checkBox${index}`);
                          updateChecked(item, checkbox.checked);
                        }} checked={selectedCheckList.find(checkedItem => checkedItem === item) !== undefined} />
                      </label>
                    )
                  })
                }
              </div>
            </div>

            <div className="w-full my-2 px-3" style={{ margnBottom: 10, display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", }}>
              <div className="" style={{ flex: 1, color: "grey", flexDirection: "row", display: "flex", alignItems: "center", cursor: "pointer" }}>
                <div className="bg-[white] text-[black] rounded-sm py-1 px-2 mr-2 border-[1px] border-[black]" style={{ fontSize: 14 }} onClick={() => { setShowFilter(!showFilter); }}>
                  {filterNameList[selectedFilterIndex]}
                </div>
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "crimson",
                  cursor: "pointer",
                  textAlign: "center",
                  paddingRight: 5,
                }}
                onClick={() => {
                  window.location.reload(true);
                }}
              >
                새로고침
              </div>
            </div>

            <div className="flex relative w-full">
              <details open={!isComplete} className="w-full open:bg-white open:ring-black/5 open:shadow-lg py-6">
                <summary className="text-sm leading-6 text-slate-900 font-semibold select-none text-left px-4">
                  취소 {tourCancelFilterList?.length} (인니 {tourCancelFilterList?.filter(item => item.countryId === 2).length} 필핀 {tourCancelFilterList?.filter(item => item.countryId === 3).length})
                </summary>
                <div className="mt-1">
                  {
                    tourCancelFilterList?.map(tour => {
                      return (
                        <div key={tour.id} className={`w-full`}>
                          <TourComponent
                            data={tour}
                            guide={toursGuideInfo.filter(guideInfo => guideInfo.tourId === tour.id).map(guideInfo => {
                              let userInfo = userList.find(user => user.id === guideInfo.guideId);
                              guideInfo.name = userInfo.name;
                              guideInfo.nameEN = userInfo.nameEN;
                              guideInfo.phoneNumber = userInfo.phoneNumber;
                              guideInfo.birthDate = userInfo.birthDate;
                              return guideInfo;
                            })}
                            hotel={toursHotelInfo.filter(hotelInfo => hotelInfo.tourId === tour.id)}
                            hotelLog={toursHotelLogInfo.filter(hotelLogInfo => hotelLogInfo.tourId === tour.id)}
                            hotelist={hotelList}
                            bus={toursBusInfo.filter(busInfo => busInfo.tourId === tour.id)}
                            buslist={busList}
                            checkList={toursCheckListInfo}
                            refresh={fetchData}
                            guideMemoList={guideMemoList?.filter(memoInfo => memoInfo.tourId === tour.id)}
                            funds={toursFunds?.filter(fundInfo => fundInfo.tourId === tour.id)}
                            files={toursFiles?.filter(fileInfo => fileInfo.tourId === tour.id)}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </details>
            </div>

            <div className="flex relative w-full">
              <details open={!isComplete} className="w-full open:bg-white open:ring-black/5 open:shadow-lg py-6">
                <summary className="text-sm leading-6 text-slate-900 font-semibold select-none text-left px-4">
                  완료 {tourCompleteFilterList?.length} (인니 {tourCompleteFilterList?.filter(item => item.countryId === 2).length} 필핀 {tourCompleteFilterList?.filter(item => item.countryId === 3).length})
                </summary>
                <div className="mt-1">
                  {
                    tourCompleteFilterList?.filter(tour => selectedCheckList).map(tour => {
                      return (
                        <div key={tour.id} className={`w-full`}>
                          <TourComponent
                            data={tour}
                            guide={toursGuideInfo.filter(guideInfo => guideInfo.tourId === tour.id).map(guideInfo => {
                              let userInfo = userList.find(user => user.id === guideInfo.guideId);
                              guideInfo.name = userInfo.name;
                              guideInfo.nameEN = userInfo.nameEN;
                              guideInfo.phoneNumber = userInfo.phoneNumber;
                              guideInfo.birthDate = userInfo.birthDate;
                              return guideInfo;
                            })}
                            hotel={toursHotelInfo.filter(hotelInfo => hotelInfo.tourId === tour.id)}
                            hotelLog={toursHotelLogInfo.filter(hotelLogInfo => hotelLogInfo.tourId === tour.id)}
                            hotelist={hotelList}
                            bus={toursBusInfo.filter(busInfo => busInfo.tourId === tour.id)}
                            buslist={busList}
                            checkList={toursCheckListInfo}
                            refresh={fetchData}
                            guideMemoList={guideMemoList?.filter(memoInfo => memoInfo.tourId === tour.id)}
                            funds={toursFunds?.filter(fundInfo => fundInfo.tourId === tour.id)}
                            files={toursFiles?.filter(fileInfo => fileInfo.tourId === tour.id)}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </details>
            </div>

            <div className="flex relative w-full">
              <details open={isComplete} className="w-full open:bg-white open:ring-black/5 open:shadow-lg py-6">
                <summary className="text-sm leading-6 text-slate-900 font-semibold select-none text-left px-4">
                  진행 {tourDoingFilterList?.length} (인니 {tourDoingFilterList?.filter(item => item.countryId === 2).length} 필핀 {tourDoingFilterList?.filter(item => item.countryId === 3).length})
                </summary>
                <div className="mt-1">
                  {
                    tourDoingFilterList?.map(tour => {
                      return (
                        <div key={tour.id} className={`w-full`}>
                          <TourComponent
                            data={tour}
                            guide={toursGuideInfo.filter(guideInfo => guideInfo.tourId === tour.id).map(guideInfo => {
                              let userInfo = userList.find(user => user.id === guideInfo.guideId);
                              guideInfo.name = userInfo.name;
                              guideInfo.nameEN = userInfo.nameEN;
                              guideInfo.phoneNumber = userInfo.phoneNumber;
                              guideInfo.birthDate = userInfo.birthDate;
                              return guideInfo;
                            })}
                            hotel={toursHotelInfo.filter(hotelInfo => hotelInfo.tourId === tour.id)}
                            hotelLog={toursHotelLogInfo.filter(hotelLogInfo => hotelLogInfo.tourId === tour.id)}
                            hotelist={hotelList}
                            bus={toursBusInfo.filter(busInfo => busInfo.tourId === tour.id)}
                            buslist={busList}
                            checkList={toursCheckListInfo}
                            refresh={fetchData}
                            guideMemoList={guideMemoList?.filter(memoInfo => memoInfo.tourId === tour.id)}
                            funds={toursFunds?.filter(fundInfo => fundInfo.tourId === tour.id)}
                            files={toursFiles?.filter(fileInfo => fileInfo.tourId === tour.id)}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </details>
            </div>

            <div className="flex relative w-full mb-[10px]">
              <details open={isComplete} className="w-full open:bg-white open:ring-black/5 open:shadow-lg py-6">
                <summary className="text-sm leading-6 text-slate-900 font-semibold select-none text-left px-4">
                  예정 {tourRemainFilterList?.length} (인니 {tourRemainFilterList?.filter(item => item.countryId === 2).length} 필핀 {tourRemainFilterList?.filter(item => item.countryId === 3).length})
                </summary>
                <div className="mt-1">
                  {
                    tourRemainFilterList?.map(tour => {
                      return (
                        <div key={tour.id} className={`w-full`}>
                          <TourComponent
                            data={tour}
                            guide={toursGuideInfo.filter(guideInfo => guideInfo.tourId === tour.id).map(guideInfo => {
                              let userInfo = userList.find(user => user.id === guideInfo.guideId);
                              guideInfo.name = userInfo.name;
                              guideInfo.nameEN = userInfo.nameEN;
                              guideInfo.phoneNumber = userInfo.phoneNumber;
                              guideInfo.birthDate = userInfo.birthDate;
                              return guideInfo;
                            })}
                            hotel={toursHotelInfo.filter(hotelInfo => hotelInfo.tourId === tour.id)}
                            hotelLog={toursHotelLogInfo.filter(hotelLogInfo => hotelLogInfo.tourId === tour.id)}
                            hotelist={hotelList}
                            bus={toursBusInfo.filter(busInfo => busInfo.tourId === tour.id)}
                            buslist={busList}
                            checkList={toursCheckListInfo}
                            refresh={fetchData}
                            guideMemoList={guideMemoList?.filter(memoInfo => memoInfo.tourId === tour.id)}
                            funds={toursFunds?.filter(fundInfo => fundInfo.tourId === tour.id)}
                            files={toursFiles?.filter(fileInfo => fileInfo.tourId === tour.id)}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </details>
            </div>

            <div className="w-full">

            </div>
            {
              // 필터 다이얼로그
              showFilter &&
              <>
                <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
                  <div className="relative w-full my-6 mx-auto max-w-lg">
                    <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                      {
                        filterNameList.map((filter, index) => {
                          return (
                            <div key={index}
                              className={index !== filterNameList.length - 1
                                ? `border-b ${liCommonCSSUserMenu}`
                                : `${liCommonCSSUserMenu}`
                              }>
                              <button
                                className={btnCommonCSSUserMenu}
                                onClick={() => {
                                  let filterList = [];
                                  filterList.push(...tourCompleteFilterList);
                                  filterList.push(...tourDoingFilterList);
                                  filterList.push(...tourCancelFilterList);
                                  filterList.push(...tourRemainFilterList);
                                  filteringTour(filterList, index);
                                }}
                              >
                                {filter}
                              </button>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black" />
              </>
            }
            {
              // 년 이동 처리
              showYearList &&
              <>
                <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium">
                  <div className="relative w-full m-auto max-w-lg border-[1px]">
                    <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                      {
                        filterYearList.map((year, index) => {
                          return (
                            <div key={index}
                              className={index !== filterYearList.length - 1
                                ? `border-b ${liCommonCSSUserMenu}`
                                : `${liCommonCSSUserMenu}`
                              }>
                              <button
                                className={btnCommonCSSUserMenu}
                                onClick={() => {
                                  if (typeof (year) === "number") {
                                    setSelectedYear(year);
                                    changeYearOrMonth(year, selectedMonth);

                                    setIsComplete(today.getMonth() < (selectedMonth) && today.getFullYear() <= year);
                                    setShowYearList(false);
                                  } else {
                                    setShowYearList(false);
                                  }
                                }}
                              >
                                {typeof (year) === "number" ? year + "년" : year}
                              </button>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black" />
              </>
            }
            {
              // 월 이동 처리
              showMonthList &&
              <>
                <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium">
                  <div className="relative w-full m-auto max-w-lg border-[1px]">
                    <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                      {
                        filterMonthList.map((month, index) => {
                          return (
                            <div key={index}
                              className={index !== filterMonthList.length - 1
                                ? `border-b ${liCommonCSSUserMenu}`
                                : `${liCommonCSSUserMenu}`
                              }>
                              <button
                                className={btnCommonCSSUserMenu}
                                onClick={() => {
                                  if (typeof (month) === "number") {
                                    setSelectedMonth(month);
                                    changeYearOrMonth(selectedYear, month);

                                    setIsComplete(today.getMonth() < (month) && today.getFullYear() <= selectedYear);
                                    setShowMonthList(false);
                                  } else {
                                    setShowMonthList(false);
                                  }
                                }}
                              >
                                {typeof (month) === "number" ? month + "월" : month}
                              </button>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black" />
              </>
            }
          </div>
        }
      </div>
      <div className="absolute top-0 flex flex-col w-full">
        {
          showCreateTour &&
          <>
            <div className="flex w-full h-full text-center">
              <CreateTour setShow={setShowCreateTour} refresh={fetchData} />
            </div>
          </>
        }
      </div>
    </>
  )
}