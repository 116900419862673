import React, { useState, useEffect, useRef, useMemo } from "react";
import { useRecoilState, } from "recoil";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../features/Constant";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPShopManager from "../../features/HTTPShopManager";
import * as Utils from "../../features/Utils";
import { UserInfo } from "../../recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import "../styles/CommonStyle.css"
import ICON_PH from "../../assets/images/ph.png"
import ICON_ID from "../../assets/images/id.png"

const ShopPerformancePage = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);

  let searchText = useRef("");
  let focusRef = useRef();

  const today = new Date();
  today.setMonth(today.getMonth() - 1);
  today.setHours(0, 0, 0, 0);

  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth() + 1);

  const [userList, setUserList] = useState([]);
  const [tourList, setTourList] = useState([]);
  const [toursGuideList, setToursGuideList] = useState([]);
  const [toursGuideFilterList, setToursGuideFilterList] = useState([]);

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none justify-center flex";

  const [shopList, setShopList] = useState([]);
  const [shopAccountList, setShopAccountList] = useState([]);

  // 월이동
  const filterYearList = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, "닫기"];
  const [showYearList, setShowYearList] = useState(false);
  const filterMonthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, "닫기"];
  const [showMonthList, setShowMonthList] = useState(false);

  // 국가
  const [selectedCountryList, setSelectedCountryList] = useState([2, 3]);

  // 정렬
  const [sortDirection, setSortDirection] = useState(-1);
  const [sortType, setSortType] = useState("");

  // 필터 버튼
  const FILTER_LIST = [{ id: 0, name: "1분기", startMonth: 0, endMonth: 2 }, { id: 1, name: "2분기", startMonth: 3, endMonth: 5 }, { id: 2, name: "3분기", startMonth: 6, endMonth: 8 },
  { id: 3, name: "4분기", startMonth: 9, endMonth: 11 }, { id: 4, name: "상반기", startMonth: 0, endMonth: 5 }, { id: 5, name: "하반기", startMonth: 6, endMonth: 11 }];
  const [selectedFilterId, setSelectedFilterId] = useState(-1);

  // 기간 선택
  const SELECTED_PERIOD_YEAR = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
  const SELECTED_PERIOD_MONTH = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [showPeriodStart, setShowPeriodStart] = useState(false);
  const [showPeriodEnd, setShowPeriodEnd] = useState(false);

  const [startYear, setStartYear] = useState(-1);
  const [endYear, setEndYear] = useState(-1);
  const [startMonth, setStartMonth] = useState(-1);
  const [endMonth, setEndMonth] = useState(-1);

  const [dataList, setDataList] = useState([]);

  async function fetchData() {
    if (userInfo.accessToken === undefined) return;

    let res = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    let userList = res.data.result;
    setUserList(userList);

    let shopList = [];

    res = await HTTPShopManager.getShopList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      shopList = res.data.result;
      setShopList(shopList);
    }

    res = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let initTourList = [];
    if (res !== undefined) {
      initTourList = res.data.result;
      setTourList(initTourList);
    }

    res = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      setToursGuideList(res.data.toursGuide);
      setToursGuideFilterList(res.data.toursGuide);
    }

    res = await HTTPShopManager.getShopAccountList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      let nameList = res.data.result.map(item => { return item.name }).sort((a, b) => {
        // 이름순 정렬
        if (a < b)
          return sortDirection;
        if (a > b)
          return -sortDirection;
      });
      let uniqueNameList = [...new Set(nameList)];

      let tmpList = res.data.result.sort((a, b) => {
        // 이름순 정렬
        if (a.name < b.name)
          return sortDirection;
        if (a.name > b.name)
          return -sortDirection;

        // 샵 id 정렬
        if (a.shopId < b.shopId)
          return sortDirection;
        if (a.shopId > b.shopId)
          return -sortDirection;
      });

      setShopAccountList(tmpList);

      let dataList = uniqueNameList.map(item => {
        return {
          name: item,
          countryId: userList.find(user => user.name === item)?.majorCountry,
          list: tmpList.filter(shopAccount => shopAccount.name === item)
        }
      });
      setDataList(dataList);
    }
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.GOD, ROLES.SETTLEMENT], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
      });
  }, []);

  function moveToRight() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month + 1 > 12) {
      setSelectedYear(selectedYear + 1);
      month = 0;
      year = year + 1;
    }

    setSelectedMonth(month + 1);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  function moveToLeft() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month - 1 < 1) {
      setSelectedYear(selectedYear - 1);
      month = 13;
      year = year - 1;
    }

    setSelectedMonth(month - 1);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  const btnMoveMonthClass = "text-3xl pointerhover:hover:cursor-pointer px-4 py-1 pointerhover:hover:bg-gray-200 rounded-full focus:outline-none";
  const idColor = "#43A4F9";
  const phColor = "#FF3A3A";

  function handleSortType(type) {
    if (sortType !== type) {
      setSortDirection(-1);
      setSortType(type);
    } else {
      setSortDirection(-sortDirection);
    }
  }

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-4 px-[50px] pb-[30px] md:px-[5px]">
      <div className="flex flex-col relative">
        <span className={"absolute top-10 left-1/2 transform -translate-x-1/2 text-[20px] cursor-pointer flex flex-col gap-2 items-center justify-center"}>
          <span className="flex flex-row">
            <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
              moveToLeft();
            }} >
              <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#777" }} />
            </span>
            <div
              id="title"
              className="flex flex-row justify-center items-center text-[25px] select-none cursor-pointer">
              <div className="mr-[5px]" onClick={() => {
                setShowYearList(true);
              }}>{selectedYear}년</div>
              <div onClick={() => {
                setShowMonthList(true);
              }}>{selectedMonth.toString().padStart(2, "0")}월</div>
            </div>
            <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
              moveToRight();
            }}>
              <FontAwesomeIcon icon={faCaretRight} style={{ color: "#777" }} />
            </span>
          </span>
        </span>
        <div className="text-start mt-[50px] text-[#9299A1]">쇼핑</div>
        <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px] cursor-pointer w-fit">
          <div className="relative">
            <div>실적</div>
          </div>
        </div>
        <div className="text-[#485763] font-bold normal-case mb-[25px] flex flex-col gap-1">
        </div>
      </div>

      <div className="w-full flex flex-row gap-2 mt-[25px] scrollRemoveBox px-2">
        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5 cursor-pointer" style={{
          background: selectedCountryList.find(countryId => countryId === 2) !== undefined ? "#444444" : "#cdcdcd",
          color: selectedCountryList.find(countryId => countryId === 2) !== undefined ? "#ffffff" : "#ffffff",
        }} onClick={() => {
          if (selectedCountryList.find(countryId => countryId === 2) !== undefined) {
            setSelectedCountryList(selectedCountryList.filter(countryId => countryId !== 2));
          } else {
            setSelectedCountryList([...selectedCountryList, 2]);
          }
        }}>
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer bg-[lightgray] p-[1px] rounded-full w-[25px] h-[25px]"}>
              <img src={ICON_ID} className="rounded-full object-cover h-full w-full" />
            </span>
            <div>인도네시아</div>
          </div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5 cursor-pointer" style={{
          background: selectedCountryList.find(countryId => countryId === 3) !== undefined ? "#444444" : "#cdcdcd",
          color: selectedCountryList.find(countryId => countryId === 3) !== undefined ? "#ffffff" : "#ffffff",
        }} onClick={() => {
          if (selectedCountryList.find(countryId => countryId === 3) !== undefined) {
            setSelectedCountryList(selectedCountryList.filter(countryId => countryId !== 3));
          } else {
            setSelectedCountryList([...selectedCountryList, 3]);
          }
        }}>
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer bg-[lightgray] p-[1px] rounded-full w-[25px] h-[25px]"}>
              <img src={ICON_PH} className="rounded-full object-cover h-full w-full" />
            </span>
            <div>필리핀</div>
          </div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5 justify-between" style={{
          background: "#FFFFFF",
          color: "#000000",
        }}>
          <div className="flex flex-row gap-3 font-bold">
            <span>
              <FontAwesomeIcon icon={faChartSimple} style={{ color: "#1D3C77" }} />
            </span>
            <div>종합</div>
          </div>
        </div>
      </div>

      <div className="px-2 md:scrollRemoveBox flex flex-row gap-1 items-center">
        {
          FILTER_LIST.map((item, index) => {
            return <div key={item.id} className="md:flex-none rounded text-[white] w-fit px-2 py-[5px] text-center cursor-pointer"
              style={{
                background: (selectedFilterId === item.id) ? "#000000" : "#bababa",
              }}
              onClick={() => {
                setStartYear(-1);
                setStartMonth(-1);
                setEndYear(-1);
                setEndMonth(-1);

                let shopMonthList = [];

                if (selectedFilterId === item.id) {
                  setSelectedFilterId(-1);

                  shopMonthList = shopAccountList.filter(shopAccount => {
                    return (new Date(shopAccount.visitDate).getFullYear() === selectedYear && new Date(shopAccount.visitDate).getMonth() === (selectedMonth - 1));
                  });
                }
                else {
                  setSelectedFilterId(item.id);

                  let filterInfo = FILTER_LIST[item.id];

                  shopMonthList = shopAccountList.filter(shopAccount => {
                    return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
                      new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() >= filterInfo.startMonth && new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() <= filterInfo.endMonth);
                  });
                }

                let tmpList = [];

                for (let i = 0; i < shopList.length; i++) {
                  let shopInfo = shopList[i];

                  tmpList.push({
                    id: shopInfo.id,
                    count: shopMonthList.filter(item => item.shopId === shopInfo.id)?.length
                  });
                }
              }}>{item.name}</div>
          })
        }
        <div className="md:flex-none rounded text-[white] w-fit px-2 py-[5px] text-center cursor-pointer bg-[black]"
          onClick={() => {
            setSelectedFilterId(-1);
            setStartYear(-1);
            setStartMonth(-1);
            setEndYear(-1);
            setEndMonth(-1);
            setShowPeriodStart(!showPeriodStart);
          }}>
          기간설정
        </div>
        {
          (startYear !== -1 && startMonth !== -1 && endYear !== -1 && endMonth !== -1)
          &&
          <div className="md:flex-none rounded text-[black] border-[1px] border-black w-fit px-2 py-1 text-center cursor-pointer" onClick={() => {
            setStartYear(-1);
            setStartMonth(-1);
            setEndYear(-1);
            setEndMonth(-1);

            let shopMonthList = [];

            shopMonthList = shopAccountList.filter(shopAccount => {
              return (new Date(shopAccount.visitDate).getFullYear() === selectedYear && new Date(shopAccount.visitDate).getMonth() === (selectedMonth - 1));
            });

            let tmpList = [];

            for (let i = 0; i < shopList.length; i++) {
              let shopInfo = shopList[i];

              tmpList.push({
                id: shopInfo.id,
                count: shopMonthList.filter(item => item.shopId === shopInfo.id)?.length
              });
            }
          }}>
            {startYear}.{startMonth.toString().padStart(2, 0)} ~ {endYear}.{endMonth.toString().padStart(2, 0)}
          </div>
        }
        <div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="md:scrollRemoveBox grid grid-cols-[9%_10%_9%_9%_9%_9%_9%_9%_9%_9%_9%_0%] sticky top-0 w-full text-[white] text-center items-center text-[16px] font-bold bg-[black] py-3">
        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={() => {
          handleSortType("name");
        }}>
          <div>가이드</div>
          {
            sortType === "name" ? sortDirection === 1 ? <span>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: "#FFFFFF" }} />
            </span> : <span>
              <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FFFFFF" }} />
            </span> : null
          }
        </div>

        <div className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={() => {
          handleSortType("totalProfitAmount");
        }}>
          <div>총합</div>
          {
            sortType === "totalProfitAmount" ? sortDirection === 1 ? <span>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: "#FFFFFF" }} />
            </span> : <span>
              <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FFFFFF" }} />
            </span> : null
          }
        </div>

        {
          // truncate : 텍스트 overflow 생략처리
          shopList.map((shop) => {
            return (
              <div key={shop.id} className="md:flex-none flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={() => {
                handleSortType(shop.id);
              }}>
                <div className="truncate">{shop.name}</div>
                {
                  sortType === shop.id ? sortDirection === 1 ? <span>
                    <FontAwesomeIcon icon={faArrowUp} style={{ color: "#FFFFFF" }} />
                  </span> : <span>
                    <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FFFFFF" }} />
                  </span> : null
                }
              </div>
            )
          })
        }

      </div>
      <table>
        <tbody>
          {
            dataList?.map(data => {
              let filterInfo = FILTER_LIST[selectedFilterId];
              let tmpList = [];

              tmpList = data.list.filter(shopAccount => {
                if (selectedFilterId !== -1) {
                  return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
                    new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() >= filterInfo.startMonth && new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() <= filterInfo.endMonth);
                } else {
                  if (startYear !== -1 && startMonth !== -1 && endYear !== -1 && endMonth !== -1) {
                    let start = new Date(startYear, startMonth - 1, 1, 0, 0, 0, 0).getTime();
                    let end = new Date(endYear, endMonth, 0, 0, 0, 0, 0).getTime();
                    let visit = new Date(shopAccount.visitDate.replace(" ", "T")).getTime();

                    return (start <= visit && visit <= end);
                  } else {
                    return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
                      new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() === (selectedMonth - 1));
                  }
                }
              });

              if (tmpList.length > 0) {
                return {
                  name: data.name,
                  countryId: data.countryId,
                  list: tmpList,
                }
              }
            })?.filter(element => element)?.filter(data => selectedCountryList.find(countryId => data.countryId === countryId) !== undefined)?.sort((a, b) => {
              switch (sortType) {
                case "":
                  break;
                case "name":
                  if (a.name > b.name)
                    return sortDirection;
                  if (a.name < b.name)
                    return -sortDirection;
                  break;
                case "totalProfitAmount":
                  let aProfit = a.list?.reduce((a, b) => a = a + Number(b.profitAmount), 0);
                  let bProfit = b.list?.reduce((a, b) => a = a + Number(b.profitAmount), 0);
                  if (aProfit > bProfit)
                    return sortDirection;
                  if (aProfit < bProfit)
                    return -sortDirection;
                  break;
                default:
                  let aTotal = a.list?.filter(shopAccount => shopAccount.shopId === sortType)?.reduce((a, b) => a = a + Number(b.profitAmount), 0);
                  let bTotal = b.list?.filter(shopAccount => shopAccount.shopId === sortType)?.reduce((a, b) => a = a + Number(b.profitAmount), 0);
                  if (aTotal > bTotal)
                    return sortDirection;
                  if (aTotal < bTotal)
                    return -sortDirection;
                  break;
              }
            }
            )?.map((data, index) => {
              if (data !== undefined && data.list?.length > 0) {
                return (
                  <tr key={index} className="md:scrollRemoveBox grid grid-cols-[9%_10%_9%_9%_9%_9%_9%_9%_9%_9%_9%_0%] border-[#E2E2E2] border-x-[1px] border-b-[1px] py-5 font-semibold items-center normal-case text-center">
                    <td className="flex flex-row gap-1 justify-center">
                      <div style={{
                        color: data.countryId === 2 ? idColor : data.countryId === 3 ? phColor : "#000",
                      }}>[{index + 1}]</div>
                      <div>{data.name}</div>
                    </td>

                    <td>
                      {Utils.formatNumber(data.list?.reduce((a, b) => a = a + Number(b.profitAmount), 0))}
                    </td>

                    {
                      shopList.map((shop) => {
                        if (shop.id !== 10)
                          return (<td key={shop.id}>
                            {Utils.formatNumber(data.list?.filter(shopAccount => shopAccount.shopId === shop.id)?.reduce((a, b) => a = a + Number(b.profitAmount), 0))}
                          </td>);
                        else
                          return null;
                      })
                    }
                  </tr>
                )
              }
            })
          }
          {
            // shopAccountList?.filter(shopAccount => selectedCountryList.find(countryId => shopAccount.countryId === countryId) !== undefined)?.filter(shopAccount => {
            //   return (new Date(shopAccount.visitDate.replace(" ", "T")).getFullYear() === selectedYear &&
            //     new Date(shopAccount.visitDate.replace(" ", "T")).getMonth() === (selectedMonth - 1));
            // })?.sort((a, b) => {
            //   // 정렬 조건 and 처리하려면 아래와 같이 return 처리              
            //   switch (sortType) {
            //     case "name":
            //       if (a.name > b.name)
            //         return sortDirection;
            //       if (a.name < b.name)
            //         return -sortDirection;
            //       break;
            //     case "profitAmount":
            //       if (Number(a.profitAmount) > Number(b.profitAmount))
            //         return sortDirection;
            //       if (Number(a.profitAmount) < Number(b.profitAmount))
            //         return -sortDirection;
            //       break;
            //     default:
            //       break;
            //   }
            // })?.map((item, index) => {
            //   return (
            //     <tr key={index} className="md:scrollRemoveBox grid grid-cols-[5%_5%_10%_10%_10%_10%_10%_15%_10%_15%] border-[#E2E2E2] border-x-[1px] border-b-[1px] py-5 font-semibold items-center normal-case text-center">
            //       <td>{index + 1}</td>
            //       <td style={{
            //         color: item.countryId === 2 ? idColor : item.countryId === 3 ? phColor : "#000",
            //       }}>{item.countryId === 2 ? "ID" : (item.countryId === 3 ? "PH" : "")}</td>
            //       <td>{item.name}</td>
            //       <td>{Utils.formatNumber(item.profitAmount)}</td>
            //       <td>{item.shopId}</td>
            //     </tr>
            //   )
            // })
          }
        </tbody>
      </table>
      {
        // 년 이동 처리
        showYearList &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg border-[1px]">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  filterYearList.map((year, index) => {
                    return (
                      <div key={index}
                        className={index !== filterYearList.length - 1
                          ? `border-b ${liCommonCSSUserMenu}`
                          : `${liCommonCSSUserMenu}`
                        }>
                        <button
                          className={btnCommonCSSUserMenu}
                          onClick={() => {
                            if (typeof (year) === "number") {
                              setSelectedYear(year);
                              setShowYearList(false);
                            } else {
                              setShowYearList(false);
                            }
                          }}
                        >
                          {typeof (year) === "number" ? year + "년" : year}
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        // 월 이동 처리
        showMonthList &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg border-[1px]">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  filterMonthList.map((month, index) => {
                    return (
                      <div key={index}
                        className={index !== filterMonthList.length - 1
                          ? `border-b ${liCommonCSSUserMenu}`
                          : `${liCommonCSSUserMenu}`
                        }>
                        <button
                          className={btnCommonCSSUserMenu}
                          onClick={() => {
                            if (typeof (month) === "number") {
                              setSelectedMonth(month);
                              setShowMonthList(false);
                            } else {
                              setShowMonthList(false);
                            }
                          }}
                        >
                          {typeof (month) === "number" ? month + "월" : month}
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        showPeriodStart &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium normal-case">
            <div className="relative w-full m-auto max-w-lg border-[1px] bg-white shadow-lg">
              <div className="border-0 relative flex flex-col outline-none focus:outline-none">
                <div className="flex cursor-pointer text-black w-full justify-center py-2">시작</div>
                <div className="grid grid-cols-4 gap-1 w-full">
                  {
                    startYear === -1 && SELECTED_PERIOD_YEAR.map(year => {
                      return <div key={year} className="flex w-full items-center justify-center p-5 hover:bg-[#dfdfdf] cursor-pointer select-none"
                        onClick={() => {
                          setStartYear(year);
                        }}
                      >
                        {year}
                      </div>
                    })
                  }
                  {
                    (startYear !== -1 && startMonth === -1) && SELECTED_PERIOD_MONTH.map(month => {
                      return <div key={month} className="flex w-full items-center justify-center p-5 hover:bg-[#dfdfdf] cursor-pointer select-none"
                        onClick={() => {
                          setStartMonth(month);
                          setShowPeriodStart(false);
                          setShowPeriodEnd(true);
                        }}
                      >
                        {month}
                      </div>
                    })
                  }
                </div>
              </div>
              <div className="flex cursor-pointer text-white bg-[black] w-full justify-center py-2" onClick={() => {
                setShowPeriodStart(false);
                setStartYear(-1);
                setStartMonth(-1);
              }}>
                <div>닫기</div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        showPeriodEnd &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium normal-case">
            <div className="relative w-full m-auto max-w-lg border-[1px] bg-white shadow-lg">
              <div className="flex cursor-pointer text-black w-full justify-center py-2">종료</div>
              <div className="border-0 relative flex flex-col outline-none focus:outline-none">
                <div className="grid grid-cols-4 gap-1 w-full">
                  {
                    endYear === -1 && SELECTED_PERIOD_YEAR.map(year => {
                      return <div key={year} className="flex w-full items-center justify-center p-5 hover:bg-[#dfdfdf] cursor-pointer select-none"
                        style={{
                          pointerEvents: year < startYear ? "none" : "",
                          background: year < startYear && "#cfcfcf"
                        }}
                        onClick={() => {
                          setEndYear(year);
                        }}
                      >
                        {year}
                      </div>
                    })
                  }
                  {
                    (endYear !== -1 && endMonth === -1) && SELECTED_PERIOD_MONTH.map(month => {
                      return <div key={month} className="flex w-full items-center justify-center p-5 hover:bg-[#dfdfdf] cursor-pointer select-none"
                        style={{
                          pointerEvents: (startYear === endYear && month < startMonth) ? "none" : "",
                          background: (startYear === endYear && month < startMonth) && "#cfcfcf"
                        }}
                        onClick={() => {
                          setEndMonth(month);
                          setShowPeriodEnd(false);

                          let shopMonthList = [];

                          shopMonthList = shopAccountList.filter(shopAccount => {
                            let start = new Date(startYear, startMonth - 1, 1, 0, 0, 0, 0).getTime();
                            let end = new Date(endYear, month, 0, 0, 0, 0, 0).getTime();
                            let visit = new Date(shopAccount.visitDate.replace(" ", "T")).getTime();

                            return start <= visit && visit <= end;
                          });

                          let tmpList = [];

                          for (let i = 0; i < shopList.length; i++) {
                            let shopInfo = shopList[i];

                            tmpList.push({
                              id: shopInfo.id,
                              count: shopMonthList.filter(item => item.shopId === shopInfo.id)?.length
                            });
                          }
                        }}
                      >
                        {month}
                      </div>
                    })
                  }
                </div>
              </div>
              <div className="flex cursor-pointer text-white bg-[black] w-full justify-center py-2" onClick={() => {
                setShowPeriodEnd(false);
                setEndYear(-1);
                setEndMonth(-1);
              }}>
                <div>닫기</div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
    </div>
  );
};

export default ShopPerformancePage;
