// 서버 측과 동기화
export const ROLES = {
  USER: 1,  // 미사용
  GUIDE: 2,
  MANAGER: 3,
  LEADERGUIDE: 4, // 미사용
  ITS: 5,
  GOD: 6,
  SETTLEMENT: 7,
};

export const ERRORCODE = {
  NEED_TO_LOGIN: {
    CODE: 1100,
    MESSAGE: "",
  },
};

export const METHOD_OF_PAYMENT = {
  CASH: 0,
  CARD: 1
}

export const PROOF_OF_CASH = {
  NO: 0,
  YES: 1,
}

export const TYPE_OF_PAYMENT = {
  HOTEL: 0,
  TOURIST_MEAL: 1,
  ATTRACTION: 2,
  ETC: 3,
  // 관리자전용
  BUS: 4,
  COMISSION: 5,
  TRAFFIC_CHARGE: 6,
}

export const SALES_COMPANY_ID = {
  // 인삼
  INSAM: 1,

  // 적송
  FINE: 2,
  BOGAN: 3,

  // 잡화
  MYUNGPOOM: 4,
  POONGLIM: 5,

  // 면세
  DUTYFREE_LOTTE: 6,
  DUTYFREE_HYUNDAI: 7,
  DUTYFREE_SIN: 8,

  // 김
  CHUNGHAE: 9,
  SONGHAK: 10,

  // 자수정
  JEIL: 11,

  // 기타
  ETC: 12,

  // 화장품
  CNC: 13,

  // 관리자전용
  INVOICE: 14,

  // 신라면세점
  DUTYFREE_SILLA: 15,
}

export const TOURINFO_COLOR_SET_IN = {
  IN: {
    background: "#EDF1FD",
    sidebar: "#5D81E2",
    horizontalLine: "#DDE3FD",
    buttonBackground: "#D7E3FD",
    button: "#6884CE",
    name: "#4171D6",
    linkOnBackground: "#4171D6",
    linkOffBackground: "#B3CDFF",
    // 추가정보 색상
    addTopBarBackground: "#ECF2FF",
    addTopBarText: "#4171D6",
    addLine: "#D5E3FF",
  },
  PH: {
    background: "#FCF2F3",
    sidebar: "#D64E5A",
    horizontalLine: "#F8D8DD",
    buttonBackground: "#FBD7DB",
    button: "#D95165",
    name: "#D31A45",
    linkOnBackground: "#D31A45",
    linkOffBackground: "#FFBAC3",
    // 추가정보 색상
    addTopBarBackground: "#FFF1F3",
    addTopBarText: "#D31A45",
    addLine: "#FFD8D9",
  },
  CANCEL: {
    background: "#F7F7F9",
    sidebar: "#758592",
    horizontalLine: "#EBEEF1",
    buttonBackground: "#DBE0E4",
    button: "#9AA4AF",
    name: "#57646F",
    linkOnBackground: "#57646F",
    linkOffBackground: "#C3CBD1",
    // 추가정보 색상
    addTopBarBackground: "#EBEEF1",
    addTopBarText: "#57646F",
    addLine: "#9AA4AF",
  },
}

export const REQUEST_STATE = {
  NEEDTOCHECK: 0,
  PROCESSING: 1,
  COMPLETE: 2,
  REVISE: 3,
  PAUSE: 4,
  REJECT: 5,
  DELETED: 6,
  NEEDTOCHECKITS: 7,
  NEEDTOHOTELPRICE: 8,
  COMPLETEHOTELPRICE: 9,
}