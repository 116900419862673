import React, { useState, useEffect, } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet, } from "../../recoil";
import { useRecoilValue, } from "recoil";
import * as HTTPManager from "../../features/HTTPManager";
import RoomingInfoItem from "./RoomingInfoItem";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as HTTPITSManager from "../../features/HTTPITSManager";
import "../styles/CommonStyle.css"

export default function RoomingWindow() {
  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const [tourList, setTourList] = useState([]);
  const [tourFilterList, setTourFilterList] = useState([]);
  const [tourFindList, setTourFindList] = useState([]);

  const [userList, setUserList] = useState([]);
  const [hotelList, setHotelList] = useState([]);

  const [toursHotelInfo, setToursHotelInfo] = useState([]);
  const [toursGuideInfo, setToursGuideInfo] = useState([]);

  const [searchText, setSearchText] = useState("");

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);

  const [fromITSInfoList, setFromITSInfoList] = useState([]);

  // 데이터 초기화
  async function initalize() {
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth() + 1);
  }

  function getInitList(list, year, month) {
    let tmpList = list.filter(tour => (new Date(tour.startDate.replace(" ", "T")).getFullYear() === year && new Date(tour.startDate.replace(" ", "T")).getMonth() === month) && tour.cancel !== 1);

    tmpList.sort((a, b) => {
      // 시작일 빠른순 정렬
      if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
        return 1;
      if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
        return -1;

      // 종료일 빠른순 정렬
      if (new Date(a.endDate.replace(" ", "T")) > new Date(b.endDate.replace(" ", "T")))
        return 1;
      if (new Date(a.endDate.replace(" ", "T")) < new Date(b.endDate.replace(" ", "T")))
        return -1;
    });

    return tmpList;
  }

  async function fetchData() {
    let res = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    if (res.data !== undefined) {
      // 인도네시아, 문서번호 존재 시, 취소 x
      let tmpList = res.data.result.filter(item => (item.docNum !== undefined && item.docNum !== null && item.name === "인도네시아"));

      setTourList(tmpList);

      let initYear = selectedYear === 0 ? today.getFullYear() : selectedYear;
      let initMonth = selectedMonth === 0 ? today.getMonth() : selectedMonth - 1;

      setTourFilterList(getInitList(tmpList, initYear, initMonth));

      res = await HTTPManager.GetTourReservation({
        accessToken: userInfo.accessToken,
      });

      if (res !== undefined && res.data !== undefined) {
        setToursHotelInfo(res.data.toursHotel);
        setToursGuideInfo(res.data.toursGuide);
      }

      res = await HTTPManager.GetUserList({
        accessToken: userInfo.accessToken,
      });

      if (res !== undefined && res.data !== undefined) {
        setUserList(res.data.result);
      }


      res = await HTTPManager.GetHotelList({
        accessToken: userInfo.accessToken
      });

      if (res !== undefined && res.data !== undefined) {
        setHotelList(res.data.hotelList);
      }
    }

    res = await HTTPITSManager.getFromITS({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      setFromITSInfoList(res.data.result);
    }
  }

  function searchList(findText) {
    let tmpList = tourList.filter(item => (item.docNum !== undefined && item.docNum !== null && item.name === "인도네시아"));
    tmpList = getInitList(tmpList, selectedYear, selectedMonth - 1)

    if (findText.length === 0) {
      setTourFilterList(tmpList);
    } else {
      let guideList = userList.filter(user => user.name.includes(findText) || user.nameEN?.toUpperCase().includes(findText.toUpperCase())).map(item => {
        return item.id;
      });

      let findList = tmpList.filter(tour => (tour.id === Number(findText)
        || tour.docNum.toUpperCase().includes(findText.toUpperCase()))
        || (toursGuideInfo?.find(tourGuide => guideList?.find(id => id === tourGuide.guideId) !== undefined && tourGuide.tourId === tour.id) !== undefined)
      );

      // id, docNum 검색      
      setTourFilterList(findList);
    }
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.MANAGER, ROLES.ITS, ROLES.GOD], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await initalize();
          await fetchData();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);


  const btnMoveMonthClass = "text-3xl hover:cursor-pointer px-4 py-1 hover:bg-gray-200 rounded-full";

  // 선택된 연도 or 달만 표시
  function changeYearOrMonth(year, month) {
    setTourFilterList(getInitList(tourList, year, month - 1));
  }

  function changeMonthText(month) {
    let result = ""
    switch (month) {
      case 1:
        result = "Jan.";
        break;
      case 2:
        result = "Feb.";
        break;
      case 3:
        result = "Mar.";
        break;
      case 4:
        result = "Apr.";
        break;
      case 5:
        result = "May.";
        break;
      case 6:
        result = "Jun.";
        break;
      case 7:
        result = "Jul.";
        break;
      case 8:
        result = "Aug.";
        break;
      case 9:
        result = "Sep.";
        break;
      case 10:
        result = "Oct.";
        break;
      case 11:
        result = "Nov.";
        break;
      case 12:
        result = "Dec.";
        break;
    }

    return result;
  }


  return (
    <div className="scrollRemoveBox mt-2 md:mt-14 px-2 w-full h-full">
      <div className="flex flex-row font-bold mb-5 text-center justify-center">
        <span className={btnMoveMonthClass} onClick={() => {
          let year = selectedYear;
          let month = selectedMonth;

          if (month - 1 < 1) {
            setSelectedYear(selectedYear - 1);
            month = 13;
            year = year - 1;
          }

          setSelectedMonth(month - 1);
          changeYearOrMonth(year, month - 1);
        }}>
          <FontAwesomeIcon icon={faCaretLeft} className="text-gray-500" />
        </span>
        <div className="text-3xl px-3 py-1">{selectedYear} {changeMonthText(selectedMonth)}</div>
        <span className={btnMoveMonthClass} onClick={() => {
          let year = selectedYear;
          let month = selectedMonth;

          if (month + 1 > 12) {
            setSelectedYear(selectedYear + 1);
            month = 0;
            year = year + 1;
          }

          setSelectedMonth(month + 1);
          changeYearOrMonth(year, month + 1);
        }}>
          <FontAwesomeIcon icon={faCaretRight} className="text-gray-500" />
        </span>
      </div>
      <div className="flex w-full justify-center font-bold mb-5">{languageSet.roomingTitle} ({tourFilterList?.length})</div>
      <div className="w-full mt-[10px]" style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        <input
          className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
          placeholder={languageSet.roomingFindHint}
          type="text"
          name="search"
          onChange={(e) => {
            setSearchText(e.target.value);
            let findText = e.target.value;
            searchList(findText)
          }}
          value={searchText}
        />
      </div>

      <div className="w-full h-[calc(100vh-250px)] overflow-auto" id="roomingList">
        <div className="flex flex-col gap-0 w-full min-w-max">
          {/* sticky는 top-0과 같이 사용해야 동작 */}
          <div className="flex flex-row w-full bg-[black] text-[white] font-bold text-center gap-[1px] min-w-max sticky top-0 z-[1]">
            <div className="flex-none w-[60px] py-5">NO</div>
            <div className="flex-none w-[100px] py-5">Sys ID</div>
            <div className="flex-none w-[100px] py-5">PAX</div>
            <div className="flex-none w-[500px] py-5 sticky left-0 md:relative bg-[black] z-[100]">NAME OF DOCUMENT</div>
            <div className="flex-none w-[250px] py-5">ARRIVAL</div>
            <div className="flex-none w-[250px] py-5">DEPATURE</div>
            <div className="flex-none w-[120px] py-5">ROOMING</div>
            <div className="flex-none w-[120px] py-5">LOGO</div>
            <div className="flex-none w-[120px] py-5">FINAL(ITS)</div>
            <div className="flex-none w-[120px] py-5">FINAL(KTS)</div>
            <div className="flex-none w-[120px] py-5">INVOICE</div>
            <div className="flex-none w-[150px] py-5">GUIDE</div>
            <div className="flex-none w-[480px] py-5">HOTEL</div>
          </div>
          <div className="flex flex-col">
            {
              tourFilterList.map((item, index) => {
                return (
                  <RoomingInfoItem key={item.id} index={index} data={item} refresh={fetchData}
                    toursHotelInfo={toursHotelInfo} toursGuideInfo={toursGuideInfo} userList={userList} hotelList={hotelList} fromITSInfoList={fromITSInfoList} />
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}