import React, { useState, useEffect, } from "react";
import * as HTTPManager from "../../features/HTTPManager";
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ColorButton from "../ColorButton"

export default function ModifyFileDialog(Props) {
  const body = document.querySelector('body');
  const scrollPosition = window.pageYOffset;

  const userInfo = useRecoilValue(UserInfo);
  const tourInfo = Props.tourInfo;

  const [uploadFileList, setUploadFileList] = useState([]);
  const [userList, setUserList] = useState([]);

  async function fetchData() {
    let response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined && response.data?.result !== undefined)
      setUserList(response.data.result);
  }

  const inputLogoRef = React.useRef(null);
  const inputRoomingRef = React.useRef(null);
  const inputScheduleRef = React.useRef(null);
  const inputFinalRef = React.useRef(null);
  const inputFinalINRef = React.useRef(null);
  const inputInvoiceRef = React.useRef(null);

  const [dragActive, setDragActive] = useState([false, false, false, false, false, false]);

  const handleDrag = function (e, index) {
    e.preventDefault();
    e.stopPropagation();
    let checkValue = false;

    if (e.type === "dragenter" || e.type === "dragover") {
      checkValue = true;
    } else if (e.type === "dragleave") {
      checkValue = false;
    }

    let findIndex = dragActive.findIndex((item, idx) => idx === index);
    let copiedItems = [...dragActive];
    copiedItems[findIndex] = checkValue;

    setDragActive(copiedItems);
  };

  const handleDrop = function (e, type) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0], type);
    }

    setDragActive([false, false, false, false, false, false]);
  };

  const handleFiles = function (file, type) {
    if (file !== undefined) {
      let findIndex = uploadFileList.findIndex((item) => item.type === type);

      if (findIndex !== -1) {
        let copiedItems = [...uploadFileList];
        copiedItems[findIndex].file = file;
        setUploadFileList(copiedItems);
      } else {
        setUploadFileList([
          ...uploadFileList,
          {
            type: type,
            file: file,
          },
        ]);
      }
    }
  }

  const removeFiles = function (type) {
    setUploadFileList(uploadFileList.filter(item => item.type !== type));
  }

  useEffect(() => {
    // 모달 팝업 시 호출
    // 스크롤 최상단 위치 후 배경 스크롤 되지 않도록 고정 -> 최상단 이동 삭제    
    body.style.overflow = 'hidden';

    fetchData();

    // 모달 종료 시 호출
    return () => {
      body.style.removeProperty('overflow');
      window.scrollTo(0, scrollPosition);
    };
  }, []);

  return (
    <div className="fixed bg-scroll h-full w-full top-0 left-60 md:left-0 md:top-0 pl-4 md:pl-0 text-sm bg-[#0007] overflow-auto z-[100] md:z-[999] font-bold">
      <div className="fixed w-[50%] md:w-full md:h-full border-t border-l border-r-2 border-b-2 md:rounded-none rounded-md bg-white top-24 md:top-0 mr-4 overflow-auto">
        <span
          className={"flex cursor-pointer py-1 rounded mr-1 text-[20px] justify-end"}
          onClick={() => {
            Props.setShow(false);
          }}>
          <FontAwesomeIcon icon={faXmark} style={{ color: "black", marginRight: "5px", }} />
        </span>
        <div className="flex flex-col">
          <div className="flex flex-1 flex-row mb-[10px] font-bold">
            <div className="mx-2">[파일등록] 투어번호</div>
            <div className="text-[slateblue]">{Props.tourInfo.id}</div>
          </div>

          <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t-2 border-[#3f4b5b] text-[#555555]">
            <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">일정</div>
            <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
              <div>{Props.tourInfo.startDate.slice(0, 10)} {Props.tourInfo.eta}</div>
              <div>{Props.tourInfo.endDate.slice(0, 10)} {Props.tourInfo.etd}</div>
            </div>
          </div>

          {
            Props.tourInfo.name !== undefined && <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t text-[#555555]">
              <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">국가</div>
              <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
                <div>{Props.tourInfo.name}</div>
              </div>
            </div>
          }

          {
            Props.tourInfo.itinerary !== undefined && <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t text-[#555555]">
              <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">코스</div>
              <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
                <div>{Props.tourInfo.itinerary === null ? "미정" : Props.tourInfo.itinerary}</div>
              </div>
            </div>
          }

          {
            Props.tourInfo.pax !== undefined && <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t border-b text-[#555555]">
              <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">인원</div>
              <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
                <div>{Props.tourInfo.pax}</div>
              </div>
            </div>
          }

          <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-b text-[#555555] mb-3">
            <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-[2px]">가이드</div>
            {
              Props.guideInfo.map(item => {
                return (
                  <div key={item.id} className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
                    <div>{userList.find(user => user.id === item.guideId)?.name}</div>
                  </div>
                );
              })
            }
          </div>

          <div className="flex-row md:flex-col grid grid-cols-3 md:grid-cols-1">
            <div className="relative flex">
              <form id="form-file-upload" onDragEnter={e => handleDrag(e, 0)} onSubmit={(e) => e.preventDefault()} style={{
                width: "100%",
                textAlign: "center",
                position: "relative"
              }}>
                <div className="flex w-full mb-[10px] items-center px-[5px]">
                  <input ref={inputLogoRef} id="logo-file-upload" className="flex flex-1 rounded text-[black] bg-[red]"
                    style={{ display: "none" }}
                    type="file"
                    name="image"
                    onChange={(event) => {
                      handleFiles(event.target.files[0], "logo");
                    }}
                  />
                  <label htmlFor="logo-file-upload" className="flex flex-col" style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderWidth: "2px",
                    borderRadius: "1rem",
                    borderStyle: "dashed",
                    borderColor: "#cbd5e1",
                    backgroundColor: (dragActive[0] || uploadFileList.find(item => item.type === "logo")) ? "#6A5ACD55" : "#f8fafc",
                  }}>

                    <div style={{ cursor: "pointer", marginBottom: 3 }}>
                      <p style={{ color: uploadFileList.find(item => item.type === "logo") && "#000" }}>로고</p>
                      {uploadFileList.find(item => item.type === "logo") === undefined && <p>Drag or Click</p>}
                    </div>
                    {
                      uploadFileList.find(item => item.type === "logo") !== undefined && uploadFileList.find(item => item.type === "logo").file !== undefined &&
                      <div className="normal-case">
                        File : {uploadFileList.find(item => item.type === "logo").file.name}
                      </div>
                    }
                  </label>
                  {dragActive[0] && <div id="drag-file-element" onDragEnter={e => handleDrag(e, 0)} onDragLeave={e => handleDrag(e, 0)} onDragOver={e => handleDrag(e, 0)} onDrop={e => handleDrop(e, "logo")} style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}></div>}
                </div>
              </form>
              {
                uploadFileList.find(item => item.type === "logo") && <span
                  className={"absolute right-0 cursor-pointer py-1 rounded text-[15px] justify-end mr-[15px]"}
                  onClick={() => {
                    removeFiles("logo");
                    inputLogoRef.current = null;
                  }}>
                  <FontAwesomeIcon icon={faXmark} style={{ color: "crimson", marginRight: "5px", }} />
                </span>
              }
            </div>

            <div className="relative flex">
              <form id="form-file-upload" onDragEnter={e => handleDrag(e, 5)} onSubmit={(e) => e.preventDefault()} style={{
                width: "100%",
                textAlign: "center",
                position: "relative"
              }}>
                <div className="flex w-full mb-[10px] items-center px-[5px]">
                  <input ref={inputRoomingRef} id="rooming-file-upload" className="flex flex-1 rounded text-[black] bg-[red]"
                    style={{ display: "none" }}
                    type="file"
                    name="image"
                    onChange={(event) => {
                      handleFiles(event.target.files[0], "rooming");
                    }}
                  />
                  <label htmlFor="rooming-file-upload" className="flex flex-col" style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderWidth: "2px",
                    borderRadius: "1rem",
                    borderStyle: "dashed",
                    borderColor: "#cbd5e1",
                    backgroundColor: (dragActive[5] || uploadFileList.find(item => item.type === "rooming")) ? "#6A5ACD55" : "#f8fafc",
                  }}>
                    <div style={{ cursor: "pointer", marginBottom: 3 }}>
                      <p style={{ color: uploadFileList.find(item => item.type === "rooming") && "#000" }} >루밍</p>
                      {uploadFileList.find(item => item.type === "rooming") === undefined && <p>Drag or Click</p>}
                    </div>
                    {
                      uploadFileList.find(item => item.type === "rooming") !== undefined && uploadFileList.find(item => item.type === "rooming").file !== undefined &&
                      <div className="normal-case">
                        File : {uploadFileList.find(item => item.type === "rooming").file.name}
                      </div>
                    }
                  </label>
                  {dragActive[5] && <div id="drag-file-element" onDragEnter={e => handleDrag(e, 5)} onDragLeave={e => handleDrag(e, 5)} onDragOver={e => handleDrag(e, 5)} onDrop={e => handleDrop(e, "rooming")} style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}></div>}
                </div>
              </form>
              {
                uploadFileList.find(item => item.type === "rooming") && <span
                  className={"absolute right-0 cursor-pointer py-1 rounded text-[15px] justify-end mr-[15px]"}
                  onClick={() => {
                    removeFiles("rooming");
                  }}>
                  <FontAwesomeIcon icon={faXmark} style={{ color: "crimson", marginRight: "5px", }} />
                </span>
              }
            </div>

            <div className="relative flex">
              <form id="form-file-upload" onDragEnter={e => handleDrag(e, 3)} onSubmit={(e) => e.preventDefault()} style={{
                width: "100%",
                textAlign: "center",
                position: "relative"
              }}>
                <div className="flex w-full mb-[10px] items-center px-[5px]">
                  <input ref={inputFinalINRef} id="final-in-file-upload" className="flex flex-1 rounded text-[black] bg-[red]"
                    style={{ display: "none" }}
                    type="file"
                    name="image"
                    onChange={(event) => {
                      handleFiles(event.target.files[0], "finalDocIN");
                    }}
                  />
                  <label id="label-file-upload" htmlFor="final-in-file-upload" className="flex flex-col" style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderWidth: "2px",
                    borderRadius: "1rem",
                    borderStyle: "dashed",
                    borderColor: "#cbd5e1",
                    backgroundColor: (dragActive[3] || uploadFileList.find(item => item.type === "finalDocIN")) ? "#6A5ACD55" : "#f8fafc",
                  }}>
                    <div style={{ cursor: "pointer", marginBottom: 3 }}>
                      <p style={{ color: uploadFileList.find(item => item.type === "finalDocIN") && "#000" }} >파이널(ITS)</p>
                      {uploadFileList.find(item => item.type === "finalDocIN") === undefined && <p>Drag or Click</p>}
                    </div>
                    {
                      uploadFileList.find(item => item.type === "finalDocIN") !== undefined && uploadFileList.find(item => item.type === "finalDocIN").file !== undefined &&
                      <div className="normal-case">
                        File : {uploadFileList.find(item => item.type === "finalDocIN").file.name}
                      </div>
                    }
                  </label>
                  {dragActive[3] && <div id="drag-file-element" onDragEnter={e => handleDrag(e, 3)} onDragLeave={e => handleDrag(e, 3)} onDragOver={e => handleDrag(e, 3)} onDrop={e => handleDrop(e, "finalDocIN")} style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}></div>}
                </div>
              </form>
              {
                uploadFileList.find(item => item.type === "finalDocIN") && <span
                  className={"absolute right-0 cursor-pointer py-1 rounded text-[15px] justify-end mr-[15px]"}
                  onClick={() => {
                    removeFiles("finalDocIN");
                  }}>
                  <FontAwesomeIcon icon={faXmark} style={{ color: "crimson", marginRight: "5px", }} />
                </span>
              }
            </div>

            <div className="relative flex">
              <form id="form-file-upload" onDragEnter={e => handleDrag(e, 2)} onSubmit={(e) => e.preventDefault()} style={{
                width: "100%",
                textAlign: "center",
                position: "relative"
              }}>
                <div className="flex w-full mb-[10px] items-center px-[5px]">
                  <input ref={inputFinalRef} id="final-file-upload" className="flex flex-1 rounded text-[black] bg-[red]"
                    style={{ display: "none" }}
                    type="file"
                    name="image"
                    onChange={(event) => {
                      handleFiles(event.target.files[0], "finalDoc");
                    }}
                  />
                  <label id="label-file-upload" htmlFor="final-file-upload" className="flex flex-col" style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderWidth: "2px",
                    borderRadius: "1rem",
                    borderStyle: "dashed",
                    borderColor: "#f00",
                    backgroundColor: (dragActive[2] || uploadFileList.find(item => item.type === "finalDoc")) ? "#CD6A5A55" : "#f8fafc",
                  }}>
                    <div className="font-bold" style={{ cursor: "pointer", marginBottom: 3 }}>
                      <p style={{ color: "#f00" }}>파이널(KTS)</p>
                      {uploadFileList.find(item => item.type === "finalDoc") === undefined && <p style={{ color: "#f00" }}>Drag or Click</p>}
                    </div>
                    {
                      uploadFileList.find(item => item.type === "finalDoc") !== undefined && uploadFileList.find(item => item.type === "finalDoc").file !== undefined &&
                      <div className="normal-case text-[#f00] font-bold">
                        File : {uploadFileList.find(item => item.type === "finalDoc").file.name}
                      </div>
                    }
                  </label>
                  {dragActive[2] && <div id="drag-file-element" onDragEnter={e => handleDrag(e, 2)} onDragLeave={e => handleDrag(e, 2)} onDragOver={e => handleDrag(e, 2)} onDrop={e => handleDrop(e, "finalDoc")} style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}></div>}
                </div>
              </form>
              {
                uploadFileList.find(item => item.type === "finalDoc") && <span
                  className={"absolute right-0 cursor-pointer py-1 rounded text-[15px] justify-end mr-[15px]"}
                  onClick={() => {
                    removeFiles("finalDoc");
                  }}>
                  <FontAwesomeIcon icon={faXmark} style={{ color: "crimson", marginRight: "5px", }} />
                </span>
              }
            </div>

            <div className="relative flex">
              <form id="form-file-upload" onDragEnter={e => handleDrag(e, 1)} onSubmit={(e) => e.preventDefault()} style={{
                width: "100%",
                textAlign: "center",
                position: "relative"
              }}>
                <div className="flex w-full mb-[10px] items-center px-[5px]">
                  <input ref={inputScheduleRef} id="schedule-file-upload" className="flex flex-1 rounded text-[black] bg-[red]"
                    style={{ display: "none" }}
                    type="file"
                    name="image"
                    onChange={(event) => {
                      handleFiles(event.target.files[0], "scheduleKO");
                    }}
                  />
                  <label id="label-file-upload" htmlFor="schedule-file-upload" className="flex flex-col" style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderWidth: "2px",
                    borderRadius: "1rem",
                    borderStyle: "dashed",
                    borderColor: "#cbd5e1",
                    backgroundColor: (dragActive[1] || uploadFileList.find(item => item.type === "scheduleKO")) ? "#6A5ACD55" : "#f8fafc",
                  }}>
                    <div style={{ cursor: "pointer", marginBottom: 3 }}>
                      <p style={{ color: uploadFileList.find(item => item.type === "scheduleKO") && "#000" }}>행사지시서</p>
                      {uploadFileList.find(item => item.type === "scheduleKO") === undefined && <p>Drag Or Click</p>}
                    </div>
                    {
                      uploadFileList.find(item => item.type === "scheduleKO") !== undefined && uploadFileList.find(item => item.type === "scheduleKO").file !== undefined &&
                      <div className="normal-case">
                        File : {uploadFileList.find(item => item.type === "scheduleKO").file.name}
                      </div>
                    }
                  </label>
                  {dragActive[1] && <div id="drag-file-element" onDragEnter={e => handleDrag(e, 1)} onDragLeave={e => handleDrag(e, 1)} onDragOver={e => handleDrag(e, 1)} onDrop={e => handleDrop(e, "scheduleKO")} style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}></div>}
                </div>
              </form>
              {
                uploadFileList.find(item => item.type === "scheduleKO") && <span
                  className={"absolute right-0 cursor-pointer py-1 rounded text-[15px] justify-end mr-[15px]"}
                  onClick={() => {
                    removeFiles("scheduleKO");
                  }}>
                  <FontAwesomeIcon icon={faXmark} style={{ color: "crimson", marginRight: "5px", }} />
                </span>
              }
            </div>

            <div className="relative flex">
              <form id="form-file-upload" onDragEnter={e => handleDrag(e, 4)} onSubmit={(e) => e.preventDefault()} style={{
                width: "100%",
                textAlign: "center",
                position: "relative"
              }}>
                <div className="flex w-full mb-[10px] items-center px-[5px]">
                  <input ref={inputInvoiceRef} id="invoice-file-upload" className="flex flex-1 rounded text-[black] bg-[red]"
                    style={{ display: "none" }}
                    type="file"
                    name="image"
                    onChange={(event) => {
                      handleFiles(event.target.files[0], "invoice");
                    }}
                  />
                  <label id="label-file-upload" htmlFor="invoice-file-upload" className="flex flex-col" style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderWidth: "2px",
                    borderRadius: "1rem",
                    borderStyle: "dashed",
                    borderColor: "#cbd5e1",
                    backgroundColor: (dragActive[4] || uploadFileList.find(item => item.type === "invoice")) ? "#6A5ACD55" : "#f8fafc",
                  }}>
                    <div style={{ cursor: "pointer", marginBottom: 3 }}>
                      <p style={{ color: uploadFileList.find(item => item.type === "invoice") && "#000" }} >인보이스</p>
                      {uploadFileList.find(item => item.type === "invoice") === undefined && <p>Drag or Click</p>}
                    </div>
                    {
                      uploadFileList.find(item => item.type === "invoice") !== undefined && uploadFileList.find(item => item.type === "invoice").file !== undefined &&
                      <div className="normal-case">
                        File : {uploadFileList.find(item => item.type === "invoice").file.name}
                      </div>
                    }
                  </label>
                  {dragActive[4] && <div id="drag-file-element" onDragEnter={e => handleDrag(e, 4)} onDragLeave={e => handleDrag(e, 4)} onDragOver={e => handleDrag(e, 4)} onDrop={e => handleDrop(e, "invoice")} style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}></div>}
                </div>
              </form>
              {
                uploadFileList.find(item => item.type === "invoice") && <span
                  className={"absolute right-0 cursor-pointer py-1 rounded text-[15px] justify-end mr-[15px]"}
                  onClick={() => {
                    removeFiles("invoice");
                  }}>
                  <FontAwesomeIcon icon={faXmark} style={{ color: "crimson", marginRight: "5px", }} />
                </span>
              }
            </div>
          </div>

          <span className="md:w-full md:h-full">
            <ColorButton
              title="확인"
              color="slateblue"
              handler={async () => {
                if (uploadFileList.length < 1) return;

                var res = await HTTPManager.ModifyFileTourInfo({
                  accessToken: userInfo.accessToken,
                  tourId: tourInfo.id,
                  fileList: uploadFileList,
                });

                if (res?.data?.result) {
                  Props.refresh();
                  Props.setShow(false);
                } else {
                  alert("업로드 실패!");
                  Props.setShow(false);
                }

                setUploadFileList([]);
              }}
            />
          </span>
        </div>
      </div>
    </div>
  )
}