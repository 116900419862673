import React, { useState, useEffect } from "react";
import { useRecoilState, } from "recoil";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ROLES } from "../../features/Constant";
import * as HTTPManager from "../../features/HTTPManager";
import * as Utils from '../../features/Utils';
import { UserInfo } from "../../recoil";
import Calendar from "../Calendar";
import TourInfoItemGuide from "./TourInfoItemGuide";

const PageMain = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const [myTourList, setMyTourList] = useState([]);

  // 이슈 : ToursCardList에 현재 달과 다른 달의 투어 정보가 표시됨.
  // 재현경로 : 다른 달로 이동한 상태 > 다른 메뉴 페이지 확인 > 이 페이지로 복귀
  // 수정 : 전역 변수 > 지역변수
  // Calendar 컴포넌트에서 set함수 받아서 처리
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());

  const [userList, setUserList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [busList, setBusList] = useState([]);

  const [toursBusInfo, setToursBusInfo] = useState([]);
  const [toursHotelInfo, setToursHotelInfo] = useState([]);
  const [toursGuideInfo, setToursGuideInfo] = useState([]);
  const [guideMemoList, setGuideMemoList] = useState([]);

  async function fetchData() {
    if (userInfo.accessToken === undefined) return;

    let res = "";

    if (userInfo.rolesId === ROLES.LEADERGUIDE) {
      res = await HTTPManager.GetUserList({
        accessToken: userInfo.accessToken,
      });

      setUserList(res.data.result);
    }

    res = await HTTPManager.GetMyToursList({
      accessToken: userInfo.accessToken,
      year,
      month,
    });

    if (res.data.accessToken) {
      setUserInfo((prev) => {
        const returnValue = { ...prev };
        returnValue.accessToken = res.data.accessToken;

        return { ...returnValue };
      });
    }

    // [확인필요] 같은 행사 중복 수신
    // 취소 행사 제외    
    setMyTourList(res.data.result.filter(item => item.cancel !== 1));

    if (res.data !== undefined) {
      setToursBusInfo(res.data.toursBus);
      setToursHotelInfo(res.data.toursHotel);
      setToursGuideInfo(res.data.toursGuide);
    }

    res = await HTTPManager.GetHotelList({
      accessToken: userInfo.accessToken
    });

    if (res !== undefined) {
      setHotelList(res.data.hotelList);
    }

    res = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken
    });

    if (res !== undefined) {
      setBusList(res.data.busList);
    }

    res = await HTTPManager.GetGuideMemoForGuide({
      accessToken: userInfo.accessToken,
      guideId: userInfo.id,
    });

    if (res !== undefined) {
      setGuideMemoList(res.data.guideMemoList);
    }
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.GUIDE], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, [userInfo, year, month, setUserInfo]);

  return (
    <div className="w-full h-full">
      <div className="flex flex-1">
        <Calendar
          myTourList={myTourList}
          setYear={setYear}
          setMonth={setMonth}
        />
      </div>
      <div className="bg-[white] flex px-4 md:px-1">
        <div className="bg-[#white] flex flex-row md:flex-col gap-1 w-full justify-between md:items-start p-4 md:px-1 items-center">
          <div className="font-bold items-center">정산서 양식 다운로드</div>
          <div className="flex flex-row gap-2 font-bold items-center">
            <div className="w-[50px] h-fit bg-[black] text-white rounded-full text-center px-2 py-[3px] cursor-pointer select-none hover:bg-gray-500" onClick={() => {
              let answer = window.confirm(
                "KTS_IN.xlsx 파일을 다운로드 하시겠습니까?"
              );

              if (answer) {
                window.open("https://ktravelstore.com:9786/images/kts/tsm/KTS_IN.xlsx");
              }
            }}>ID</div>
            <div className="w-[50px] h-fit bg-[black] text-white rounded-full text-center px-2 py-[3px] cursor-pointer select-none hover:bg-gray-500" onClick={() => {
              let answer = window.confirm(
                "KTS_PH.xlsx 파일을 다운로드 하시겠습니까?"
              );

              if (answer) {
                window.open("https://ktravelstore.com:9786/images/kts/tsm/KTS_PH.xlsx");
              }
            }}>PH</div>
            <div className="w-[100px] h-fit bg-[black] text-white rounded-full text-center px-2 py-[3px] cursor-pointer select-none hover:bg-gray-500" onClick={() => {
              window.open("https://ktravelstore.com:9786/images/kts/tsm/KTS_SAMPLE.png");
            }}>작성방법</div>
          </div>
        </div>
      </div>

      <div className="px-8 md:px-1 text-[#485763] font-bold normal-case mb-10 flex flex-col gap-1">
        <div className="flex flex-row items-center gap-3">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px]">"정산서 업로드" 버튼은 정산서 파일을 업로드하는 기능입니다. (사이트에 정산 내용을 직접 입력하는 기능이 아닙니다.)</div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px]">"정산서 확인" 버튼을 통해 입력된 파일 확인 가능합니다. (빈화면 나타나는 경우 계속해서 새로고침 시도)</div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px]">수정이 필요한 경우 "정산서 업로드" 버튼을 통해 다시 업로드 하시면 됩니다. (이전 정산서 덮어쓰기 처리)</div>
        </div>
      </div>

      <div className="bg-white flex flex-col px-4 md:px-1 pb-6">
        <div className="">
          <span className="flex flex-col gap-7 w-full">
            {myTourList?.length > 0 &&
              myTourList?.map((item, index) => {
                if (userInfo.rolesId === ROLES.LEADERGUIDE || userInfo.rolesId === ROLES.MANAGER)
                  return <TourInfoItemGuide key={index}
                    tour={item}
                    guide={toursGuideInfo}
                    guideName={userList.find(user => user.id === item.guideId)?.name}
                    hotel={toursHotelInfo.filter(hotelInfo => hotelInfo.tourId === item.tourId)}
                    hotelList={hotelList}
                    bus={toursBusInfo.filter(busInfo => busInfo.tourId === item.tourId)}
                    busList={busList}
                    index={index}
                    refresh={fetchData}
                    guideMemoList={guideMemoList?.filter(memoInfo => memoInfo.tourId === item.tourId)}
                  />;
                else
                  return <TourInfoItemGuide key={index}
                    tour={item}
                    guide={toursGuideInfo}
                    guideName={userInfo?.name}
                    hotel={toursHotelInfo.filter(hotelInfo => hotelInfo.tourId === item.tourId)}
                    hotelList={hotelList}
                    bus={toursBusInfo.filter(busInfo => busInfo.tourId === item.tourId)}
                    busList={busList}
                    index={index}
                    refresh={fetchData}
                    guideMemoList={guideMemoList?.filter(memoInfo => memoInfo.tourId === item.tourId)}
                  />;
              })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageMain;
