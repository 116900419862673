import React, { useState, useEffect } from "react";
import { useRecoilState, } from "recoil";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ROLES } from "../../features/Constant";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPSettlementManager from "../../features/HTTPSettlementManager";
import * as Utils from "../../features/Utils";
import { UserInfo } from "../../recoil";

const GuideTemplatePage = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const [userList, setUserList] = useState([]);

  async function fetchData() {
    if (userInfo.accessToken === undefined) return;

    let res = "";

    if (userInfo.rolesId === ROLES.LEADERGUIDE) {
      res = await HTTPManager.GetUserList({
        accessToken: userInfo.accessToken,
      });

      setUserList(res.data.result);
    }
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.GUIDE], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, [userInfo, setUserInfo]);

  async function handleDownloadFile(fileName, fileURL) {
    fetch(fileURL, {
      method: 'GET',
      headers: {
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );

        document.body.appendChild(link);

        var answer = window.confirm(`Save ${fileName}?`);
        if (answer) {
          link.click();
          link.parentNode.removeChild(link);
        }
      });
  }

  return (
    <div className="flex flex-col w-full h-full pt-[50px] pb-[37px] px-[30px]">
      <div className="flex flex-col">
        <div className="text-[32px] font-extrabold mb-[25px] md:mt-[10px]">양식</div>
        <div className="text-[18px] p-[0.7px] font-bold">정보</div>
      </div>

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-3">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px] cursor-pointer" onClick={() => {
            handleDownloadFile("신세계 면세점 가이드 신청서, 약정서.docx", "https://ktravelstore.com:9786/images/kts/tsm/template/kwFHGk2BGgOjA5Crsz3.docx");
          }}>신세계 면세점 가이드 신청서, 약정서</div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px] cursor-pointer" onClick={() => {
            handleDownloadFile("신라아이파크면세점 신청서.docx", "https://ktravelstore.com:9786/images/kts/tsm/template/bVDfDzOEIfJ6lhLj.docx");
          }}>신라아이파크면세점 신청서</div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px] cursor-pointer" onClick={() => {
            handleDownloadFile("신라아이파크면세점 약정서.docx", "https://ktravelstore.com:9786/images/kts/tsm/template/XFgXerTx8A.docx");
          }}>신라아이파크면세점 약정서</div>
        </div>
      </div>
    </div>
  );
};

export default GuideTemplatePage;
