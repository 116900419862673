import React, { useState, useEffect } from "react";
import * as Utils from '../features/Utils';
import * as HTTPManager from '../features/HTTPManager';
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ColorButton from "./ColorButton"
import { ROLES } from "../features/Constant";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function RegistHotel(Props) {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const [addressDo, SetAddressDo] = useState("");
  const [addressSiGu, SetAddressSiGu] = useState("");
  const [name, setName] = useState("");
  const [breakfast, setBreakfast] = useState("");
  const [memo, setMemo] = useState("");
  const [priceCount, setPriceCount] = useState(0);

  const [priceState, setPriceState] = useState([]);

  function updatePriceState() {
    // state 배열 값 추가하는 방법        
    setPriceState([...priceState, {
      type: "",
      price: "",
    }]);
  }

  const inputChangeHandler = (event, idx, type) => {
    const updatedState = priceState;
    const updatedFormElement = {
      ...updatedState[idx]
    }

    switch (type) {
      case 'type':
        updatedFormElement.type = event.target.value;
        break;
      case 'price':
        updatedFormElement.price = event.target.value;
        break;
      default:
        break;
    }

    updatedState[idx] = updatedFormElement;

    setPriceState(updatedState);
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.MANAGER, ROLES.GOD], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  return (
    <div className="fixed h-full w-full top-0 left-60 md:left-0 md:top-10 pl-4 text-sm bg-[#0007] overflow-auto z-[100]">
      <div className="fixed border-t border-l border-r-2 border-b-2 h-5/6 w-5/6 rounded-md md:w-11/12 bg-white top-24 md:top-20 overflow-auto p-6 md:p-2">
        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
          <div
            style={{
              width: 25,
              height: 25,
              fontSize: 15,
              color: "grey",
              cursor: "pointer",
              textAlign: "center"
            }}
            onClick={() => { Props.setShow(false) }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>기본 정보</div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>시/도</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) 서울특별시, 경기도 등"
            type="text"
            name="addressDo"
            style={{ flex: 1 }}
            onChange={(e) => { SetAddressDo(e.target.value) }}
            value={addressDo}
          />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>구</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) 마포구, 종로구, 중구"
            type="text"
            name="addressSiGu"
            style={{ flex: 1 }}
            onChange={(e) => { SetAddressSiGu(e.target.value) }}
            value={addressSiGu}
          />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>이름</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) 스카이파크, 설악포유"
            type="text"
            name="name"
            style={{ flex: 1 }}
            onChange={(e) => { setName(e.target.value) }}
            value={name}
          />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>조식</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) 조식 여부, 가능 시 가격 입력"
            type="text"
            name="breakfast"
            style={{ flex: 1 }}
            onChange={(e) => { setBreakfast(e.target.value) }}
            value={breakfast}
          />
        </div>

        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>메모</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) 메모"
            type="text"
            name="memo"
            style={{ flex: 1 }}
            onChange={(e) => { setMemo(e.target.value) }}
            value={memo}
          />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <span style={{ marginRight: 10 }}>가격 정보</span>
          <FontAwesomeIcon icon={faSquarePlus} size="xl" color="grey" onClick={() => {
            setPriceCount(priceCount + 1);
            updatePriceState();
          }} />
        </div>
        <div style={{ width: '100%', marginBottom: 10 }}>
          {
            priceState.map((item, index) => {
              return (
                <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
                    <div style={{ width: 80, color: '#9ca3af' }}>기간</div>
                    <input
                      className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                      placeholder="ex) 22년 주중, 22년 주말, 1-6월 주중"
                      type="text"
                      name="type"
                      style={{ flex: 1 }}
                      onChange={(e) => inputChangeHandler(e, index, "type")}
                    />
                  </div>
                  <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
                    <div style={{ width: 80, color: '#9ca3af' }}>가격</div>
                    <input
                      className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                      placeholder="ex) 66,000"
                      type="text"
                      name="price"
                      style={{ flex: 1 }}
                      onChange={(e) => inputChangeHandler(e, index, "price")}
                    />
                  </div>
                </div>
              )
            })
          }
        </div>
        <span style={{ width: "100%" }}>
          <ColorButton title="등록" color="slateblue" handler={async () => {
            var res = await HTTPManager.RegistHotelInfo({
              accessToken: userInfo.accessToken,
              info: {
                address1: addressDo,
                address2: addressSiGu,
                name: name,
                breakfast: breakfast,
                memo: memo,
              },
              price: priceState
            })

            if (res.data.result) {
              alert("등록 완료");
              navigate(-1);
            } else {
              alert("등록 실패");
            }
          }} />
        </span>
      </div>
    </div >
  );
}