import React, { useState, useEffect } from "react";
import * as Utils from '../../features/Utils';
import * as HTTPShopManager from '../../features/HTTPShopManager';
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../features/Constant";
import RegistDialog from "./RegistDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShop } from "@fortawesome/free-solid-svg-icons";

export default function () {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);

  const [showAddDialog, setShowAddDialog] = useState(false);

  const [shopList, setShopList] = useState([]);
  const [shopFilterList, setShopFilterList] = useState([]);

  async function fetchData() {
    if (userInfo.accessToken === undefined) return;

    let res = "";

    res = await HTTPShopManager.getShopList({
      accessToken: userInfo.accessToken,
    });

    setShopList(res.data.result);
    setShopFilterList(res.data.result)
  }

  useEffect(() => {
    Utils.allowByPositionEx([ROLES.GOD, ROLES.SETTLEMENT], userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  let seoulCount = shopFilterList?.filter(shop => shop.address.includes("서울"))?.length;
  let incheonCount = shopFilterList?.filter(shop => shop.address.includes("인천"))?.length;
  let gyunggiCount = shopFilterList?.filter(shop => shop.address.includes("경기"))?.length;
  let etcCount = shopFilterList?.length - seoulCount - incheonCount - gyunggiCount;

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-14 px-[50px] md:px-[30px] pb-5">
      <div className="text-start mt-[50px] text-[#9299A1]">쇼핑</div>
      <div className="flex flex-row items-center mt-[50px]">
        <div className="text-start text-[#333A40] font-bold text-[30px]">매장정보</div>
      </div>

      <div className="w-full flex flex-row gap-2 mt-[25px] scrollRemoveBox px-1">
        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faShop} style={{ color: "#1D3C77" }} />
            </span>
            <div>총</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{shopFilterList?.length}곳</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faShop} style={{ color: "#1D3C77" }} />
            </span>
            <div>서울</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{seoulCount}곳</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faShop} style={{ color: "#1D3C77" }} />
            </span>
            <div>인천</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{incheonCount}곳</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faShop} style={{ color: "#1D3C77" }} />
            </span>
            <div>경기</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{gyunggiCount}곳</div>
        </div>

        <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
          <div className="flex flex-row gap-3 font-bold">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faShop} style={{ color: "#1D3C77" }} />
            </span>
            <div>그 외</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{etcCount}곳</div>
        </div>
      </div>

      <div className="w-full flex flex-col">
        <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

        <div className="grid grid-cols-[10%_10%_50%_10%_10%_10%] sticky top-0 w-full text-[white] text-center text-[16px] font-bold bg-[black] py-3">
          <div>번호</div>
          <div>이름</div>
          <div>주소</div>
          <div>메인상품</div>
          <div>담당자</div>
          <div>연락처</div>
        </div>
        <table>
          <tbody>
            {
              shopFilterList?.map(shop => {
                return (
                  <tr key={shop.id} className="grid grid-cols-[10%_10%_50%_10%_10%_10%] text-center border-[#E2E2E2] border-x-[1px] border-b-[1px] py-5 font-semibold items-center normal-case">
                    <td>{shop.id}</td>
                    <td>{shop.name}</td>
                    <td>{shop.address}</td>
                    <td>{shop.product}</td>
                    <td>{shop.manager}</td>
                    <td>{shop.contactNumber}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <div className="fixed flex flex-col w-full md:top-14">
        {
          showAddDialog &&
          <>
            <div className="flex w-full h-full text-center">
              <RegistDialog setShow={setShowAddDialog} refresh={fetchData} />
            </div>
          </>
        }
      </div>
    </div >
  );
}