import PageMain from "./infoMenu/PageMain";
import ScheduleList from "./ScheduleList";
import TourInfoListMainPage from "./tourInfoList/MainPage";
import BusList from "./BusList";
import HotelList from "./HotelList";
import RegistGuide from "./RegistGuide";
import RoomingWindow from "./rooming/RoomingWindow";
import RequestWindow from "./requestBoard/RequestWindow";
import LogWindow from "./logging/LogWindow";
import FinishPage from "./hanmaru/FinishPage";
import FundWindow from "./fund/FundWindow";
import AccountPage from "./hanmaru/AccountPage";
import AgencyAccountWindow from "./management/AgencyAccountWindow";
import BusCalendar from "./bus/BusCalendar";
import RentalCarPage from "./bus/RentalCarPage";
import SettlementPage from "./management/SettlementPage";
import GuideSettlementPage from "./infoMenu/GuideSettlementPage";
import GuideAttractionPage from "./infoMenu/GuideAttractionPage";
import SettlementListPage from "../components/management/SettlementListPage";
import ShopAccountPage from "./shop/ShopAccountPage";
import ShopListPage from "./shop/ShopListPage";
import { ROLES } from "../features/Constant";
import ShopPerformancePage from "./shop/ShopPerformancePage";
import GuideTemplatePage from "./infoMenu/GuideTemplatePage";

const menuItems = [
  {
    name: "정보",
    key: "menuInfo",
    path: "",
    element: "",
    type: [ROLES.GUIDE],
    subMenu: [
      {
        name: "일정",
        key: "menuInfoSchedule",
        path: "/guideSchedule",
        element: <PageMain />,
        type: [ROLES.GUIDE],
      },
      {
        name: "정산",
        key: "menuInfoGuideSettlement",
        path: "/guideSettlement",
        element: <GuideSettlementPage />,
        type: [ROLES.GUIDE],
      },
      {
        name: "관광지",
        key: "menuInfoGuideAttraction",
        path: "/guideAttraction",
        element: <GuideAttractionPage />,
        type: [ROLES.GUIDE],
      },
      {
        name: "양식",
        key: "menuInfoGuideTemplate",
        path: "/guideTemplate",
        element: <GuideTemplatePage />,
        type: [ROLES.GUIDE],
      },
    ],
  },
  {
    name: "투어",
    key: "menuTour",
    path: "",
    element: "",
    type: [ROLES.MANAGER, ROLES.ITS, ROLES.GOD, ROLES.SETTLEMENT],
    subMenu: [
      {
        name: "목록",
        key: "menuTourList",
        path: "/scheduleList",
        element: <ScheduleList />,
        type: [ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT],
      },
      {
        name: "목록(표)",
        key: "menuTourListEx",
        path: "/tourInfoMain",
        element: <TourInfoListMainPage />,
        type: [ROLES.GOD],
      },
      {
        name: "루밍",
        key: "menuTourRooming",
        path: "/rooming",
        element: <RoomingWindow />,
        type: [ROLES.MANAGER, ROLES.ITS, ROLES.GOD],
      },
      {
        name: "요청",
        key: "menuTourRequest",
        path: "/itsRequest",
        element: <RequestWindow />,
        type: [ROLES.MANAGER, ROLES.ITS, ROLES.GOD],
      },
      {
        name: "지원금",
        key: "menuTourFund",
        path: "/fund",
        element: <FundWindow />,
        type: [ROLES.GOD],
      },
    ],
  },
  {
    name: "사용자",
    key: "menuUser",
    path: "",
    element: "",
    type: [ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT],
    subMenu: [
      {
        name: "가이드",
        key: "menuGuide",
        path: "/registGuide",
        element: <RegistGuide />,
        type: [ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT],
      },
    ],
  },
  {
    name: "버스",
    key: "menuBus",
    path: "",
    element: "",
    type: [ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT],
    subMenu: [
      {
        name: "등록 및 목록",
        key: "menuBusRegistNList",
        path: "/bus",
        element: <BusList />,
        type: [ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT],
      },
      {
        name: "달력",
        key: "menuBusCalendar",
        path: "/busCalendar",
        element: <BusCalendar />,
        type: [ROLES.GOD],
      },
      {
        name: "렌트카",
        key: "menuBusRentalCar",
        path: "/rentalCar",
        element: <RentalCarPage />,
        type: [ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT],
      },
    ],
  },
  {
    name: "호텔",
    key: "menuHotel",
    path: "",
    element: "",
    type: [ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT],
    subMenu: [
      {
        name: "호텔 등록 및 목록",
        key: "menuHotelRegistNList",
        path: "/hotel",
        element: <HotelList />,
        type: [ROLES.MANAGER, ROLES.GOD, ROLES.SETTLEMENT],
      },
    ],
  },
  {
    name: "정산",
    key: "menuManagement",
    path: "",
    element: "",
    type: [ROLES.GOD, ROLES.SETTLEMENT],
    subMenu: [
      {
        name: "목록",
        key: "menuManagementTotalSettlement",
        path: "/settlementList",
        element: <SettlementListPage />,
        type: [ROLES.GOD, ROLES.SETTLEMENT],
      },
      {
        name: "가이드전 전도금 정산",
        key: "menuManagementSettlement",
        path: "/settlement",
        element: <SettlementPage />,
        type: [ROLES.GOD, ROLES.SETTLEMENT],
      },
      {
        name: "에이전시별 손익",
        key: "menuManagementAgencyAccount",
        path: "/agencyAccount",
        element: <AgencyAccountWindow />,
        type: [ROLES.GOD, ROLES.SETTLEMENT],
      },
    ]
  },
  {
    name: "쇼핑",
    key: "menuManagementShop",
    path: "",
    element: "",
    type: [ROLES.GOD, ROLES.SETTLEMENT],
    subMenu: [
      {
        name: "매장",
        key: "menuManagementShopList",
        path: "/shopList",
        element: <ShopListPage />,
        type: [ROLES.GOD, ROLES.SETTLEMENT],
      },
      {
        name: "매출",
        key: "menuManagementShopAccount",
        path: "/shopAccount",
        element: <ShopAccountPage />,
        type: [ROLES.GOD, ROLES.SETTLEMENT],
      },
      {
        name: "실적",
        key: "menuManagementShopPerformance",
        path: "/shopPerformance",
        element: <ShopPerformancePage />,
        type: [ROLES.GOD, ROLES.SETTLEMENT],
      },
    ]
  },
  {
    name: "한마루",
    key: "menuHanmaru",
    path: "",
    element: "",
    type: [ROLES.GOD],
    subMenu: [
      {
        name: "매입 매출",
        key: "menuHanmaruAccount",
        path: "/hanmaru/account",
        element: <AccountPage />,
        type: [ROLES.GOD],
      },
      {
        name: "마감",
        key: "menuHanmaruFinish",
        path: "/hanmaru/finish",
        element: <FinishPage />,
        type: [ROLES.GOD],
      },]
  },
  {
    name: "로그",
    key: "menuLog",
    path: "/log",
    element: <LogWindow />,
    type: [ROLES.MANAGER, ROLES.GOD],
    subMenu: [

    ],
  },
];

export default menuItems;
